import { dbFights } from "../firebase";
import { collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const fightsCollectionRef = collection(dbFights, "Fights");
const dataName = "Fights";
const dataNameSports = "Events";

class FightsDataService {
    addFights = (newFight) => {
        return addDoc(fightsCollectionRef, newFight)
    };

    updateFight = (id, updatedFight) => {
        const fightDoc = doc(dbFights, dataName, id);
        return updateDoc(fightDoc, updatedFight);
    };

    updateEvent = (id, data) => {
        const fightDoc = doc(dbFights, dataNameSports, id);
        return updateDoc(fightDoc, data);
    };

    deleteFight = (id) => {
        const fightDoc = doc(dbFights, dataName, id);
        return deleteDoc(fightDoc);
    };

    getAllFights = () => {
        return getDocs(fightsCollectionRef);
    };

    getFight = (id) => {
        const fightDoc = doc(dbFights, dataName, id);
        return getDoc(fightDoc);
    };


}

export default new FightsDataService();