import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { firestore } from "../firebase";
import { collection, getDoc, doc, query, where, onSnapshot } from "firebase/firestore";
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const FightLog = () => {
    const paramsIdFight = useParams();

    //Bets
    const [bets, setBets] = useState([]);

    const getHistory = async () => {
        const betsCollectionRef = collection(firestore, "History");
        const q = query(betsCollectionRef, where("uidFight", "==", paramsIdFight.idFight));
        const bet = [];
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            querySnapshot.forEach(async (dataBets) => {
                bet.push({ ...dataBets.data(), id: dataBets.id });

            });

            for (let i = 0; i < bet.length; i++) {
                const userDoc = doc(firestore, "Users", bet[i].uidUser);
                const docSnap = await getDoc(userDoc);
                const userName = docSnap.data().userName
                bet[i].userName = userName
            }

            setBets(bet.sort((a, b) => a.date > b.date));
        });

        return () => unsubscribe();
    };

    useEffect(() => {
        getHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Container component="main" style={{ marginBottom: "25em" }}>

                <TableContainer style={{ maxWidth: "100%" }} component={Paper} sx={{ mt: 6 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell align="center">Fecha</TableCell> */}
                                <TableCell align="center">Id Usuario</TableCell>
                                <TableCell align="center">Fecha</TableCell>
                                <TableCell align="center">Usuario</TableCell>
                                <TableCell align="center">Descripción</TableCell>
                                <TableCell align="center">Monto Inicial</TableCell>
                                <TableCell align="center">Nuevo Balance</TableCell>
                                <TableCell align="center">Tipo de movimiento</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bets.map((doc, index) => (
                                <TableRow

                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {/* <TableCell align="center">{doc.date}</TableCell> */}
                                    <TableCell align="center">{doc.uidUser}</TableCell>
                                    <TableCell align="center">{doc.date.toDate().toLocaleDateString("es-MX")}</TableCell>
                                    <TableCell align="center">{doc.userName}</TableCell>
                                    <TableCell align="center">{doc.description}</TableCell>
                                    <TableCell align="center">{doc.newBalance}</TableCell>
                                    <TableCell align="center">{doc.newBalance}</TableCell>
                                    <TableCell align="center">{doc.movementType}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default FightLog
