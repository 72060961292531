import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IconButton } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { uploadFile } from "../firebase";
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { collection, getDocs, query, where, updateDoc, doc, orderBy } from "firebase/firestore";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { Modal, Spin } from 'antd';
import Button from '@mui/material/Button';

const Deposits = () => {
  const [deposits, setDeposits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useUserAuth();

  const getDeposits = async () => {
    const depositsCollectionRef = collection(firestore, "Deposits");
    const q = query(depositsCollectionRef, where("uid", "==", user.uid), orderBy("depositDate", "desc"));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    setDeposits(items);
  };

  useEffect(() => {
    getDeposits();
    // eslint-disable-next-line
  }, []);

  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
  });

  const DocuPDF = ({ amount }) => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "38px" }}>Ficha digital</Text>
          </View>
          <View style={{ padding: 30, backgroundColor: "#E3E3E3", marginTop: "20px" }}>
            <Text style={{ fontSize: "18px" }}>
              Instrucciones
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "10px" }}>
              1. Realiza tu pago con cualquiera de los establecimientos permitidos.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              2. Toma una foto de tu comprobante de pago.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              3. Ingresa a tu cuenta y dirigete a "Agregar saldo".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              4. Navega en la parte inferior, verás el estado de tu saldo a añadir. Toca o da click en "Subir comprobante".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              5. En los proximos minutos un miembro de nuestro equipo revisará tu comprobante y se añadirá el saldo a tu cuenta.
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            <Text style={{ color: "gray", fontWeight: "extrabold", fontSize: "20px" }}>MONTO A PAGAR</Text>
            <Text style={{ marginTop: "20px", fontWeight: "bold", fontSize: "32px" }}>
              $ {amount} MXN
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Transferencia electrónica {"(SPEI)"}:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              CLABE: 058320000000893020
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Banco: Banregio
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Deposito en efectivo:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              Número de tarjeta: 4741 7406 0220 7885
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Beneficiario: GalloWin
            </Text>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "20px" }}>
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Oxxo_Logo.svg/2560px-Oxxo_Logo.svg.png"
                alt="OXXO"
                style={{ maxWidth: "80px", maxHeight: "40px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/7-eleven_logo.svg/2110px-7-eleven_logo.svg.png"
                alt="7Eleven"
                style={{ maxWidth: "40px", maxHeight: "40px", marginLeft: "30px" }}
              />
              <Image
                src="https://pbs.twimg.com/profile_images/1082319678893023234/QM2qBfnH_400x400.jpg"
                alt="Soriana"
                style={{ maxWidth: "100px", maxHeight: "50px", marginLeft: "25px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/en/b/be/Tiendas_Extra_logo.png"
                alt="Extra"
                style={{ maxWidth: "100px", maxHeight: "100px", paddingBottom: "50px", marginLeft: "25px" }}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const handleUploadedFile = async (e, uid) => {
    e.preventDefault();
    setLoading(true);
    try {
      const depositDoc = doc(firestore, "Deposits", uid);
      const urlFile = await uploadFile(e.target.files[0]);
      await updateDoc(depositDoc, {
        voucher: urlFile,
      });
      const newDeposits = deposits.map((deposit) => {
        if (deposit.id === uid) {
          return {
            ...deposit,
            voucher: urlFile,
          };
        }
        return deposit;
      });
      setDeposits(newDeposits);
      setLoading(false);
      setOpenModal(true);
    } catch (error) {
      console.error("Error adding document: ", error);
      setLoading(false);
    }
  }

  return (
    <Spin
      size="large"
      spinning={loading}
    >
      <div style={{ backgroundColor: "#2b2b2b" }}>
        <Container component="main" maxWidth="xs" style={{ backgroundColor: "#2b2b2b" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingBottom: "15px"
            }}
          >
            <Typography component="h1" variant="h5" color={'#ffffff'} fontSize="17px" align="center" style={{padding: "20px"}}>
              Depositos
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 250 }} style={{ backgroundColor: "#4a4949" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ color: "#ffffff" }}>Fecha</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Estatus</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Cantidad</TableCell>
                    <TableCell style={{ color: "#ffffff" }} align="center">Comprobante</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deposits.map((doc, index) => (
                    <TableRow
                      key={doc.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {new Date(doc.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                      </TableCell>
                      {doc.status === "waiting" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#603B9A' }}>
                          <Grid container direction="row" alignItems="center" justifyContent="center">
                            <Grid item xs={10}>
                              <Typography component="h1" variant="h5" color="primary" align="center" style={{ fontSize: "12px", color: "#603B9A" }}>
                                En espera
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <PDFDownloadLink document={<DocuPDF amount={doc.amount} />} fileName="ficha.pdf">
                                <IconButton
                                  color="primary"
                                  aria-label="descargar ficha"
                                  component="label"
                                  size="large"
                                >
                                  <SimCardDownloadIcon />
                                </IconButton>
                              </PDFDownloadLink>
                            </Grid>
                          </Grid>
                        </TableCell>}
                      {doc.status === "approved" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>Aprobado</TableCell>}
                      <TableCell align="right">+ ${doc.amount}</TableCell>
                      {doc.voucher === "" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>
                          <IconButton color="primary" aria-label="Subir comprobante" component="label" size="large">
                            <input
                              hidden
                              accept="image/*"
                              type="file"
                              name=""
                              id=""
                              onChange={e => handleUploadedFile(e, doc.id)}
                            />
                            <Grid container direction="column" alignItems="center" justifyContent="center">
                              <Grid item xs={12}>
                                <AttachFileIcon />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography component="h1" variant="h5" color="primary" align="center" style={{ fontSize: "10px" }}>
                                  Subir archivo
                                </Typography>
                              </Grid>
                            </Grid>
                          </IconButton>
                        </TableCell>}
                      {doc.voucher !== "" &&
                        <TableCell align="center" style={{ fontSize: "14px", color: '#50AF48' }}>
                          {doc.aui === "" &&
                            <IconButton color="primary" aria-label="Subir comprobante" component="label" size="large">
                              <input
                                hidden
                                accept="image/*"
                                type="file"
                                name=""
                                id=""
                                onChange={e => handleUploadedFile(e, doc.id)}
                              />
                              <CheckCircleOutlineOutlinedIcon style={{ fontSize: "24px", color: 'green' }} />
                            </IconButton>}
                          {doc.aui !== "" &&
                            <CheckCircleOutlineOutlinedIcon style={{ fontSize: "24px", color: 'green' }} />}
                        </TableCell>}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>

        {/* Modal Success Upload */}
        <Modal
          open={openModal}
          closable={true}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          footer={null}
          centered
        >
          <div>
            <Typography component="h1" variant="h5" color={'#50AF48'} align="center" >
              Tu comprobante fue enviado con éxito!
            </Typography>
            <br></br>
            <Typography variant="body2" align="center">
              Revisaremos tu comprobante! Tu nuevo saldo se verá reflejado en tu cuenta pronto.
            </Typography>
            <Button
              style={{
                borderRadius: 35,
                backgroundColor: "#603B9A",
                padding: "10px 36px",
                fontSize: "14px"
              }}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 4 }}
              onClick={() => setOpenModal(false)}
            >
              Aceptar
            </Button>
          </div>
        </Modal>
      </div>
    </Spin>

  );
};

export default Deposits;
