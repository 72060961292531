import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { dbFights } from "../firebase";
import { Stack } from "@mui/material";
import { Input } from '@material-ui/core'
import { firestore} from '../firebase'
import { useUserAuth } from "../context/UserAuthContext";
import { doc, onSnapshot, collection, addDoc, query, where, orderBy, limit, deleteDoc } from "firebase/firestore";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useParams } from 'react-router-dom';

function ChatAdminSports() {
    const scroll = useRef();
    const [messages, setMessages] = useState([]);
    const [msg, setMsg] = useState('');
    const { user, getUserData, userInfo } = useUserAuth();
    const [nombre, setNombre] = useState("");

    const [live, setLive] = useState(false);
    const [winner, setWinner] = useState("");

    const [chatAdmin, setChatAdmin] = useState(true);

    const paramsIdEvent = useParams(); //=========>

    const getMessages = async () =>{
        const msgCollectionRef = collection(firestore, "MessageSports");
        const q = query(msgCollectionRef, where("uidEvent", "==", paramsIdEvent.idEvent), orderBy("sendDate"), limit(50));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messagesItems = [];
        querySnapshot.forEach((doc) => {
            messagesItems.push({ ...doc.data(), id: doc.id });
        });
        setMessages(messagesItems);
        });
        return () => unsubscribe();
    }

    const sendMessage = async (e) =>{
        e.preventDefault()
        if(msg === ""){
            return;
        }
        const msgRef = collection(firestore, "MessageSports");
        const newDate = new Date();
        const docData = {
            text: msg,
            uidUser: user.uid,
            uidEvent: paramsIdEvent.idEvent,
            sendDate: newDate,
            name: nombre,
            type: "admin"
        };
        await addDoc(msgRef, docData);

        setMsg('')
        // scroll.current.scrollIntoView({ behavior: 'smooth' })
    }

    const getMsgName = async () =>{
        await getUserData(user).then((snapshot) =>{
            setNombre(snapshot.data().userName);
        });
    }

    const getRealData = async () =>{
        const unsubscribe = onSnapshot(doc(dbFights, "Events", paramsIdEvent.idEvent), (doc) => {
          setLive(doc.data().live);
          setWinner(doc.data().winner);
          
          if(doc.data().chat !== null){
            setChatAdmin(doc.data().chat);
          } else setChatAdmin(true);
        });
        return () => unsubscribe();
    }

    const deleteMSGAdmin = async (id) =>{
        const msgDoc = doc(firestore, "MessageSports", id);
        return deleteDoc(msgDoc);
    }

    useEffect(() => {
        getMessages();
        getMsgName();
        getRealData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box
            sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            }}
        >
        <Grid container spacing={2} direction="column">
            <Grid item xs={12} sx={{mt: 1}}>
                <Typography component="h1" variant="h5" align="left" sx={{ pb: 1 }} style={{fontSize:"24px", color:'#1D1D1B', fontWeight: "500"}}>
                    Chat en vivo
                </Typography>
            </Grid>
            <Grid item xs={12} style={{backgroundColor: "#F6F4F6", minHeight:"300px"}}>
                <div className="msgs">
                    {messages.map((item) => {
                        const { id, text, uidUser, name, sendDate } = item;
                        let color = 'received';
                        if(item?.type === "admin" && userInfo.type === "admin" && uidUser === user.uid) {
                            color = 'sent-admin';
                        } else if (item?.type === "admin") {
                            color = 'received-admin';
                        } else if(uidUser === user.uid) {
                            color = 'sent';
                        }                     
                        return (
                            <div>
                                <div key={id} className={`msg ${color}`}>
                                    {userInfo.type === "admin" && 
                                    <DeleteForeverIcon sx={{fontSize: "18px", color: "#742425", pt: 1}}
                                        style={{backgroundColor: "#fff", borderRadius: 12, padding: "4px 4px",}}
                                        onClick={(e) => deleteMSGAdmin(id)}/>}
                                    <p>{name}: {text}<br></br>
                                    {new Date(sendDate.seconds * 1000).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit",})}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Grid>
            {live?
            <Grid item xs={12} style={{backgroundColor:"white"}}>
                {chatAdmin? 
                <form onSubmit={sendMessage}>
                <Stack
                sx={{ pt: 1, pb: 3 }}
                direction="row"
                spacing={2}
                justifyContent="center"
                >
                    <Input 
                        style={{ width: '78%', fontSize: '15px', fontWeight: '550', marginLeft: '5px', marginBottom: '-3px' }} 
                        placeholder='Mensaje...' type="text" value={msg} 
                        onChange={e => setMsg(e.target.value)} />
                    <Button style={{ width: '18%', fontSize: '15px', fontWeight: '550', margin: '4px 5% -13px 5%', maxWidth: '200px', color: "#E81A1A"}} 
                        type="submit">
                            Enviar
                    </Button>
                </Stack>
                </form>:
                <Typography component="h1" variant="h5" align="left" sx={{ pb: 2, pl: 1 }} style={{fontSize:"14px", color:'#1D1D1B', fontWeight: "500"}}>
                    Chat desactivado por Administracion.
                </Typography>
                }
            </Grid>
            :
            <Grid item xs={12} style={{backgroundColor:"white"}}>
                {winner !== "" &&
                <Typography component="h1" variant="h5" align="left" sx={{ pb: 2, pl: 1 }} style={{fontSize:"14px", color:'#1D1D1B', fontWeight: "500"}}>
                    El chat ya no esta disponible para esta pelea.
                </Typography>}
                {winner === "" &&
                <Typography component="h1" variant="h5" align="left" sx={{ pb: 2, pl: 1 }} style={{fontSize:"14px", color:'#1D1D1B', fontWeight: "500"}}>
                    El chat aún no ha sido activado
                </Typography>}
            </Grid>}
        </Grid>
        <div ref={scroll}></div>
        </Box>
    )
}

export default ChatAdminSports