import React, { useState } from "react";
import RouletteDataService from "../Services/rouletta.services";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getDocs, collection, where, query } from "firebase/firestore";
import { firestore } from "../firebase";

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#EA9F26',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#EA9F26',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#EA9F26',
    },
  },
});

const CreateBetRoulette = () => {
  const [description, setDescription] = useState("");
  const [rouletteName, setRouletteName] = useState("");
  const [price, setPrice] = useState(0);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (rouletteName === "" || price === null || description === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    if(price <= 0){
      setMessage({ error: true, msg: "Cantidad inválida" });
      return;
    }
    let listOfNumbers = [];
    for (let i = 1; i <= 14; i++) {
      const number = {
        available: true,
        color: i % 2 === 0 ? 'red' : 'green',
        number: i
      };
      listOfNumbers.push(number);
    }

    const date = new Date();
    const newRoulette = {
      createDate: date,
      date,
      description,
      listOfNumbers,
      live: false,
      rouletteName,
      price,
      betsActive: false,
      status: 1
    };

    try {
      const betsCollectionRef = collection(firestore, "Roulette");
      const q = query(betsCollectionRef, where("status", "==", 1));
      const response = await getDocs(q);
      if (!response.empty) {
        return setMessage({ error: true, msg: "¡Ya hay una ruletta activa!" });
      }
      await RouletteDataService.addRoulette(newRoulette);
      setMessage({ error: false, msg: "¡Nueva ruleta añadida!" });
      clearFields();
      navigate("/ruleta-admin");
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const clearFields = () => {
    setRouletteName("");
    setDescription("");
    setPrice(0);
  }

  const validatePaste = (event) => {
    event.preventDefault();
  }

  const handleChange = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }
    setPrice(parseInt(event.target.value))
  };

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2} direction="row" alignItems="inherit">
            <Grid item xs={0.5} sx={{ mt: 2.5, mb: 0.5, mr: 2 }}>
              <ArrowBackIosNewIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#1D1D1B' }}
                onClick={(e) => navigate("/ruleta-admin")} />
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, mb: 0.5 }}>
              <Typography component="h1" variant="h5" align="left" style={{ fontSize: "26px", color: '#1D1D1B' }}>
                Crear rifa
              </Typography>
            </Grid>
          </Grid>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formRouletteName"
              label="Nombre de la rifa"
              name="rouletteName"
              value={rouletteName}
              onChange={(e) => setRouletteName(e.target.value)}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formDescription"
              label="Descripción de la rifa"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formPrice"
              label="Precio de cada número"
              type="number"
              name="price"
              value={price}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={handleChange}
              onPaste={validatePaste}
            />
            {message?.msg && (
              <Alert
                severity={message?.error ? "error" : "success"}
                dismissible
                onClose={() => setMessage("")}
              >
                {message?.msg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 5, mb: 4 }}
              style={{
                color: "#FFFFFF",
                borderRadius: 28,
                backgroundColor: "#E81A1A",
                padding: "10px 36px",
                fontSize: "14px",
                marginBottom: "7em",
                marginTop: "7em"
              }}
            >
              Crear Rifa
            </Button>
          </Box>
        </Box>
      </Container>
      <div style={{ height: "50px" }} ></div>
    </>
  );
};

export default CreateBetRoulette;