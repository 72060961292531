import React, { useState, useEffect } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import {
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { channels } from "../utils/constants";
import { v4 as uuidv4 } from "uuid";

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#EA9F26',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#EA9F26',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#EA9F26',
    },
  },
});

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6750A4",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#6750A4"["400"],
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

const CreateSportGame = () => {
  //Database "Fights" data
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState(true);
  const [category, setCategory] = useState("");
  const [password, setPassword] = useState("");
  const [privateEvent, setPrivateEvent] = useState(false);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [createDate, setCreateDate] = useState(new Date());
  //Nuevos datos
  const [betStatus, setBetStatus] = useState(false);
  const [live, setLive] = useState(false);
  const [winner, setWinner] = useState("");
  const [message, setMessage] = useState({ error: false, msg: "" });

  const [checked, setChecked] = useState(false);
  const [betCheck, setBetChecked] = useState(false);
  const [statusCheck, setStatusCheck] = useState(true);
  const [channel, setChannel] = useState(null); 
  const [urlVisible, setUrlVisible] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");

  const id = uuidv4();


  const handleChangeChannel = (event) => {
    setChannel(event.target.value);
    event.target.value === 3 ? setUrlVisible(true) : setUrlVisible(false);
  };

  const handleChangeUrl = (event) => {
    setUrlVideo(event.target.value);
  };

  const navigate = useNavigate();

  const classes = useStyles();

  const handleSubmit = async (e) => {
    let previousImage;
    e.preventDefault();
    setMessage("");
    if (category === "Caballos") {
      previousImage = "https://firebasestorage.googleapis.com/v0/b/gallo-win-dev.appspot.com/o/images%2Fhorse-list.jpg?alt=media&token=f41c912c-f18f-4ded-b9f7-ce40ee768533"
    } else {
      previousImage = "https://firebasestorage.googleapis.com/v0/b/gallo-win-dev.appspot.com/o/images%2Fsport-list.png?alt=media&token=a938d58a-9c88-495f-9e30-8441654a6064"
    }
    if (
      eventName === "" ||
      description === "" ||
      team1 === "" ||
      team2 === "" ||
      channel === null
    ) {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    if (checked && password === "") {
      setMessage({ error: true, msg: "Ingresa una contraseña para un juego privado" });
      return;
    }
    if (channel === 3 && urlVideo === "") {
      setMessage({ error: true, msg: "Ingresa la url del streaming de FaceBook" });
      return;
    }
    let newUrlVideo = channels[channel].url;
    if(channel === 3) {
      newUrlVideo = urlVideo;
    }
    setCreateDate(new Date());
    const newEvent = {
      id: id,
      eventName,
      description,
      teams: [{
        id: "1",
        name: team1
      },
      {
        id: "2",
        name: team2
      }],
      status,
      location,
      type,
      category,
      urlVideo: newUrlVideo,
      idVideo: channels[channel].id,
      password,
      privateEvent,
      date,
      time,
      createDate,
      betStatus,
      live,
      winner,
      ended: false,
      chat: true,
      previousImage: previousImage
    };

    try {
      await setDoc(doc(db, "Events", id), newEvent);
      setMessage({ error: false, msg: "¡Nuevo juego añadido!" });
      clearFields();
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const clearFields = () => {
    setEventName("");
    setDescription("");
    setTeam1("");
    setTeam2("");
    setStatus(true);
    setLocation("")
    setType("")
    setChannel(null);
    setPassword("");
    setCategory("");
    setPrivateEvent(false);
    setDate(new Date());
    setTime(new Date());
    setCreateDate(new Date());
    setBetStatus(false);
    setWinner("");
    setLive(false);
    //Switches
    setChecked(false);
    setStatusCheck(true);
    setBetChecked(false);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setPrivateEvent(event.target.checked);
  };

  const handleChangeBetStatus = (event) => {
    setBetChecked(event.target.checked);
    setBetStatus(event.target.checked);
  };

  const handleChangeStatusFight = (event) => {
    setStatusCheck(event.target.checked);
    setStatus(event.target.checked);
  };

  useEffect(() => {
    setPrivateEvent(false);
  }, []);

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2} direction="row" alignItems="inherit">
            <Grid item xs={0.5} sx={{ mt: 2.5, mb: 0.5, mr: 2 }}>
              <ArrowBackIosNewIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#1D1D1B' }}
                onClick={(e) => navigate("/lista-sports")} />
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, mb: 0.5 }}>
              <Typography component="h1" variant="h5" align="left" style={{ fontSize: "26px", color: '#1D1D1B' }}>
                Crear juego
              </Typography>
            </Grid>
          </Grid>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formgameName"
              label="Nombre del juego"
              name="eventName"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formDescription"
              label="Descripción del juego"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formTeam1"
              label="Nombre equipo 1"
              name="team1"
              value={team1}
              onChange={(e) => setTeam1(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formTeam2"
              label="Nombre equipo 2"
              name="team2"
              value={team2}
              onChange={(e) => setTeam2(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formLocation"
              label="Lugar del evento"
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formType"
              label="Tipo"
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <Grid container direction="flex" alignItems="flex-start">
              <FormControl className={classes.formControl}>
                <InputLabel>Categoría</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  style={{ marginRight: "1em" }}
                >
                  <MenuItem value={"Fútbol"}>Fútbol</MenuItem>
                  <MenuItem value={"Frontón"}>Frontón</MenuItem>
                  <MenuItem value={"Básquetbol"}>Básquetbol</MenuItem>
                  <MenuItem value={"Caballos"}>Caballos</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel>Canal</InputLabel>
                <Select
                  value={channel}
                  onChange={handleChangeChannel}
                >
                  <MenuItem value={1}>Canal 1</MenuItem>
                  <MenuItem value={2}>Canal 2</MenuItem>
                  <MenuItem value={3}>Canal FaceBook</MenuItem>
                </Select>
              </FormControl>
              {urlVisible && (
                <CssTextFiel
                  margin="normal"
                  required
                  fullWidth
                  id="formChannel"
                  label="URL del streaming de FaceBook"
                  name="urlVideo"
                  value={urlVideo}
                  onChange={handleChangeUrl}
                  inputProps={{
                    style: {
                      height: "40px",
                      paddingLeft: "15px"
                    },
                  }}
                />
              )}
            </Grid>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item xs={12} sx={{ mb: 4 }}>
                <FormControlLabel
                  sx={{ mt: 2, }}
                  control={<Switch sx={{ m: 1 }} />}
                  checked={statusCheck}
                  onChange={handleChangeStatusFight}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Estatus del juego" />
                <FormControlLabel
                  sx={{ mt: 2, }}
                  control={<Switch />}
                  checked={betCheck}
                  onChange={handleChangeBetStatus}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Apuestas abiertas" />
                <FormControlLabel
                  sx={{ mt: 2, }}
                  control={<Switch />}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Juego privado" />
                {privateEvent ?
                  <CssTextFiel
                    margin="normal"
                    required
                    fullWidth
                    id="formPassword"
                    label="Contraseña del juego"
                    name="passwordFight"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  /> : <></>
                }
              </Grid>
            </Grid>
            <Grid direction="row" justifyContent="flex-start" container rowSpacing={3} columnSpacing={{ sx: 0, sm: 0, md: 2, lg: 2 }}>
              <Grid item xs={12} sx={{ mb: 4 }}></Grid>
              <ThemeProvider theme={materialTheme}>
                <Grid item md={3} xs={6}  >
                  <DatePicker label="Fecha" style={{ marginLeft: "10px" }} value={date} onChange={setDate} />
                </Grid>
                <Grid item md={3} xs={6} sx={{ mb: 2 }}>
                  <TimePicker label="Hora" style={{ marginLeft: "10px" }} value={time} onChange={setTime} />
                </Grid>
              </ThemeProvider>
            </Grid>
            {message?.msg && (
              <Alert
                severity={message?.error ? "error" : "success"}
                dismissible
                onClose={() => setMessage("")}
              >
                {message?.msg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 5, mb: 4 }}
              style={{
                color: "#FFFFFF",
                borderRadius: 28,
                backgroundColor: "#E81A1A",
                padding: "10px 36px",
                fontSize: "14px"
              }}
            >
              Crear juego
            </Button>
          </Box>
        </Box>
      </Container>
      <div style={{ height: "50px" }} ></div>
    </>
  );
};

export default CreateSportGame;