import { firestore } from "../firebase";
import { collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where } from "firebase/firestore";

const depositsCollectionRef = collection(firestore, "Deposits");
const dataName = "Deposits";

class DepositsDataService {
    addDeposit = (newDeposit) => {
        return addDoc(depositsCollectionRef, newDeposit)
    };

    updateDeposit = (id, updatedDeposit) => {
        const depositDoc = doc(firestore, dataName, id);
        return updateDoc(depositDoc, updatedDeposit);
    };

    deleteDeposit = (id) => {
        const depositDoc = doc(firestore, dataName, id);
        return deleteDoc(depositDoc);
    };

    getAllDeposit = () => {
        return getDocs(depositsCollectionRef);
    };

    getDepositsByUserUID = (userUid) => {
        const q = query(depositsCollectionRef, where("uid", "==", userUid));
        const querySnapshot = getDocs(q);

        return querySnapshot;
    };

    getDeposit = (id) => {
        const fightDoc = doc(firestore, dataName, id);
        return getDoc(fightDoc);
    };


}

export default new DepositsDataService();