import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import Typography from '@mui/material/Typography';
import { useLocalStorage } from "../useLocalStorage";
import { firestore } from "../firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { Table, Button, Input, Space } from "antd";
import { SearchOutlined, FormOutlined, FileExcelOutlined } from "@ant-design/icons";
import './AllUsersPanel.css';

const AllUsersPanel = () => {
  const usersCollectionRef = collection(firestore, "Users");
  const [allUsers, setAllUsers] = useState([]);
  const [, setUidUserDetail] = useLocalStorage("uidUserDetail", "");
  const navigate = useNavigate();
  const inputSearch = useRef(null)

  const handleOnExport = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(allUsers);

    utils.book_append_sheet(wb, ws, "usuarios");

    writeFile(wb, "usuarios.xlsx");
  };

  const handleMoreDetail = (userUid) => {
    setUidUserDetail(userUid);
    navigate("/userDetail");
  }

  const getAllUsers = async () => {
    const q = query(usersCollectionRef, orderBy("userName", "asc"),);

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      const item = doc.data();
      items.push({
        ...item,
        id: doc.id,
        deposits: item?.deposits ? item.deposits : 0,
        depositsTotal: item?.depositsTotal ? item.depositsTotal : 0,
        ganancias: item.ganancias.toFixed(2),
        perdidas: item.perdidas.toFixed(2),
        balance: item.balance.toFixed(2)
      });
    });
    setAllUsers(items);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'userName',
      key: 'userName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              placeholder=""
              value={selectedKeys[0]}
              ref={inputSearch}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                key="btnFilter"
                type="primary"
                onClick={() => {
                  confirm();
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                key="btnCleaner"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: true });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.userName != null) {
          return record.userName.toLowerCase().includes(value.toLowerCase());
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => inputSearch.current?.focus(), 100);
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              style={{ marginBottom: 8, display: 'block' }}
              placeholder=""
              value={selectedKeys[0]}
              ref={inputSearch}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
            ></Input>
            <Space>
              <Button
                key="btnFilter"
                type="primary"
                onClick={() => {
                  confirm();
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
              </Button>
              <Button
                key="btnCleaner"
                onClick={() => {
                  clearFilters();
                  confirm({ closeDropdown: true });
                }}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        if (record.email != null) {
          return record.email.toLowerCase().includes(value.toLowerCase());
        }
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => inputSearch.current?.focus(), 100);
        }
      },
    },
    {
      title: 'Apuestas',
      dataIndex: 'totalBets',
      key: 'totalBets',
      sorter: {
        compare: (a, b) => a.totalBets - b.totalBets,
        multiple: 6,
      },
    },
    {
      title: 'Total ingresado',
      dataIndex: 'depositsTotal',
      key: 'depositsTotal',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0 }}>${record.depositsTotal}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.depositsTotal - b.depositsTotal,
        multiple: 4,
      },
    },
    {
      title: 'Ganancias',
      dataIndex: 'ganancias',
      key: 'ganancias',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#50AF48' }}>${record.ganancias}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.ganancias - b.ganancias,
        multiple: 3,
      },
    },
    {
      title: 'Perdidas',
      dataIndex: 'perdidas',
      key: 'perdidas',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#DF2451' }}>${record.perdidas}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.perdidas - b.perdidas,
        multiple: 2,
      },
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      key: 'balance',
      render: (value, record) => {
        return (
          <>
            <p style={{ margin: 0, color: '#603B9A' }}>${record.balance}</p>
          </>
        );
      },
      sorter: {
        compare: (a, b) => a.balance - b.balance,
        multiple: 1,
      },
    },
    {
      title: "Ver más",
      key: "action",
      render: (text, record) => (
        <>
          <Button
            onClick={() => handleMoreDetail(record.id)}
            type="link"
            icon={<FormOutlined />}
          >
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    getAllUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-body-users-panel">
      <div className="container-users-actions">
        <div style={{ flex: 2 }}>
          <Typography component="h1" variant="h4" color={'#1D1D1B'}>
            Usuarios
          </Typography>
        </div>
        <button
          className="btn-export-xlsx"
          onClick={handleOnExport}>
          Exportar XLSX <FileExcelOutlined />
        </button>
      </div>
      <Table
        dataSource={allUsers}
        columns={columns}
        rowKey="id"
        scroll={{ x: '70vw' }}
      />
    </div>
  )

};

export default AllUsersPanel;
