import React, { useState, useEffect } from "react";
import RouletteDataService from "../Services/rouletta.services";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getDocs, collection, where, query} from "firebase/firestore";
import { firestore } from "../firebase";
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#EA9F26',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#EA9F26',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#EA9F26',
    },
  },
});

const CreateBetRoulette = () => {
  const [description, setDescription] = useState("");
  const [rouletteName, setRouletteName] = useState("");
  const [roulette, setRoulette] = useState("");
  const [price, setPrice] = useState(0);
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const [loading, setLoading] = useState(true);
  const [boughtTickets, setboughtTickets] = useState("");
  const paramsIdRoulette = useParams();
  const rouleteBetCollectionRef = collection(firestore, "RouletteBets");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (rouletteName === "" || description === "" || price === "") {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }

    const newRoulette = {
      rouletteName,
      description,
      status
    };

    const newPrice = {
      price
    };

    try {
      if (paramsIdRoulette.idRuleta !== undefined && paramsIdRoulette.idRuleta !== "") {
        await RouletteDataService.updateRoulette(paramsIdRoulette.idRuleta, newRoulette);
        setMessage({ error: false, msg: "¡Actualizado correctamente!" });
        if (boughtTickets.length > 0 && roulette.price !== price) {
          setMessage({ error: true, msg: "No puedes actualizar el precio, ya que un usuario ya ha comprado un ticket" });
          return
        } else {
          await RouletteDataService.updateRoulette(paramsIdRoulette.idRuleta, newPrice);
        }
      } else {
        //await FightsDataService.addFights(newFight);
        setMessage({ error: false, msg: "¡Error inesperado!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const getRouletteBet = async () => {
    const q = query(rouleteBetCollectionRef, where("idRoulette", "==", paramsIdRoulette.idRuleta));

    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });

    setboughtTickets(items)
  }

  const getBetDataRoulette = async () => {
    setMessage("");
    try {
      const docSnap = await RouletteDataService.getRoulette(paramsIdRoulette.idRuleta);
      setRouletteName(docSnap.data().rouletteName);
      setDescription(docSnap.data().description);
      setPrice(docSnap.data().price);
      setStatus(docSnap.data().status);
      setRoulette(docSnap.data())
      setLoading(false)
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  }

  const validatePaste = (event) => {
    event.preventDefault();
  }

  const handleChange = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }
    setPrice(parseInt(event.target.value))
  };

  const currencies = [
    {
      value: 0,
      label: 'INACTIVA',
    },
    {
      value: 1,
      label: 'ACTIVA',
    },
    {
      value: 2,
      label: 'FINALIZADA',
    },
    {
      value: 3,
      label: 'OCULTA',
    },
    {
      value: 4,
      label: 'CANCELADA',
    },
  ];

  useEffect(() => {
    // if (paramsIdFight.idFight !== undefined && paramsIdFight.idFight !== "") {
    getBetDataRoulette()
    getRouletteBet()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {loading &&
            <Grid container sx={{ display: 'flex', mb: 3 }} direction="column" alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <CircularProgress color="inherit" style={{ color: "#6750A4" }} />
              </Grid>
            </Grid>
          }
          <Grid container spacing={2} direction="row" alignItems="inherit">
            <Grid item xs={0.5} sx={{ mt: 2.5, mb: 0.5, mr: 2 }}>
              <ArrowBackIosNewIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#1D1D1B' }}
                onClick={(e) => navigate("/ruleta-admin")} />
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, mb: 0.5 }}>
              <Typography component="h1" variant="h5" align="left" style={{ fontSize: "26px", color: '#1D1D1B' }}>
                Actualizar rifa
              </Typography>
            </Grid>
          </Grid>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formRouletteName"
              label="Nombre de la rifa"
              name="rouletteName"
              value={rouletteName}
              onChange={(e) => setRouletteName(e.target.value)}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formDescription"
              label="Descripción de la rifa"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formPrice"
              label="Precio de cada número"
              type="number"
              name="price"
              value={price}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={handleChange}
              onPaste={validatePaste}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              select
              id="formStatus"
              label="Estatus"
              name="status"
              defaultValue={paramsIdRoulette.status}
              onChange={(e) => setStatus(e.target.value)}
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextFiel>
            {message?.msg && (
              <Alert
                severity={message?.error ? "error" : "success"}
                dismissible
                onClose={() => setMessage("")}
              >
                {message?.msg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 5, mb: 4 }}
              style={{
                color: "#FFFFFF",
                borderRadius: 28,
                backgroundColor: "#E81A1A",
                padding: "10px 36px",
                fontSize: "14px",
                marginBottom: "7em",
                marginTop: "7em"
              }}
            >
              Actualizar rifa
            </Button>
          </Box>
        </Box>
      </Container>
      <div style={{ height: "50px" }} ></div>
    </>
  );
};

export default CreateBetRoulette;