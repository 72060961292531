import { dbRoulette } from "../firebase";
import { collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const rouletteCollectionRef = collection(dbRoulette, "Roulette");
const dataName = "Roulette";

class RouletteDataService {
    addRoulette = (newFight) => {
        return addDoc(rouletteCollectionRef, newFight)
    };

    createBetRoulette = (newBet) => {
        return addDoc(rouletteCollectionRef, newBet)
    };

    updateRoulette = (id, updatedRoulette) => {
        const rouletteDoc = doc(dbRoulette, dataName, id);
        return updateDoc(rouletteDoc, updatedRoulette);
    };

    deleteRoulette = (id) => {
        const rouletteDoc = doc(dbRoulette, dataName, id);
        return deleteDoc(rouletteDoc);
    };

    getAllRoulette = () => {
        return getDocs(rouletteCollectionRef);
    };

    getRoulette = (id) => {
        const rouletteDoc = doc(dbRoulette, dataName, id);
        return getDoc(rouletteDoc);
    };


}

export default new RouletteDataService();