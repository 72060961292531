
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import { Box } from "@mui/material";


export default function Footer() {

  return (
    <Box
      sx={{
        backgroundColor: "#781113",
        p: 9,
      }}
      component="footer"
    >
      <div >
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mb: 4 }} >
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="white" gutterBottom fontSize={"18px"}>
              Redes sociales y ayuda.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link href="https://api.whatsapp.com/send?phone=+525564305887&text=¡Hola, necesito ayuda!"
            color="inherit"
            target="_blank">
              <WhatsApp style={{ color: "#FFFFFF" }} />
            </Link>
            <Link href="https://www.facebook.com/profile.php?id=100094111945738&mibextid=LQQJ4d"
              color="inherit"
              target="_blank"
              sx={{ pl: 1 }}
              >
              <Facebook style={{ color: "#FFFFFF" }} />
            </Link>
            <Link
              href="https://instagram.com/misgallosmx?igshid=MzRlODBiNWFlZA=="
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
              target="_blank"
            >
              <Instagram style={{ color: "#FFFFFF" }} />
            </Link>
          </Grid>
        </Grid>
        <Typography variant="body2" color="white" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://misgallos.mx/">
            GALLOMX
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
        <Typography variant="body2" color="white" align="center">
          <Link color="inherit" href="/terminos-condiciones" target="_blank">
            Términos y Condiciones
          </Link>{" "}
        </Typography>
      </div>
    </Box>
  )
}
