import React, { useState, useEffect, useRef } from "react";
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import PeleasActuales from "./PeleasActuales";
import PeleasPasadas from "./PeleasPasadas";
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import { getAuth, linkWithCredential } from "firebase/auth";
import { Modal as ModalAnt, Form, Input } from "antd";
import Button from '@mui/material/Button';
import { query, collection, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { EmailAuthProvider } from "firebase/auth";
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
// import { MuiTelInput } from 'mui-tel-input'
import { Link } from "react-router-dom";
import { Carousel } from 'antd';
import Modal from '@mui/material/Modal';
import { CloseOutlined } from "@ant-design/icons";
import Categories from "./Categories";
import './HomeUser.css';
import { Checkbox } from "antd";
import CryptoJS from 'crypto-js'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#ffffff',
  },
});

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#000000',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#000000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000000',
  },

  '& .MuiInputLabel-root': {
    color: '#000000',
    borderColor: '#000000',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#000000',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#000000',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    }
  }
});

// const CssTextFieldPhone = styled(MuiTelInput)({
//   '& label.Mui-focused': {
//     color: '#ffffff',
//   },
//   '& .MuiInput-underline:after': {
//     borderBottomColor: '#ffffff',
//   },
//   '& .MuiOutlinedInput-root': {
//     '&.Mui-focused fieldset': {
//       borderColor: '#ffffff',
//     },
//   },
//   '& .MuiInputLabel-root': {
//     color: '#ffffff',
//   },
// });

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
};

const HomeUser = () => {
  const { userInfo, user, logOut, getUserData } = useUserAuth();
  // const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [modalVisisble, setModalVisible] = useState(false);
  const [modalBirthDate, setModalBirthDate] = useState(false);
  // const [modalPhoneVisisble, setModalPhoneVisible] = useState(false);
  const [error, setError] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [check, setCheck] = useState(false);
  // const [number, setNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  // const [flag, setFlag] = useState(false);
  // const [otp, setOtp] = useState("");
  // const { setUpRecaptcha } = useUserAuth();
  // const [countDown, setCountDown] = useState(60);
  // const [blockResendBttn, setBlockResendBttn] = useState(false);
  const [ads, setAds] = useState([]);
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const handleOpen = () => setOpen(true);
  // const [open, setOpen] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [urlMedia, setUrlMedia] = useState("");
  // const timerId = useRef();
  const [message, setMessage] = useState({ error: false, msg: "" });


  const getHexEncodedHash = (message, key) => {
    const hash = CryptoJS.HmacSHA256(message, key)

    return CryptoJS.enc.Hex.stringify(hash)
  }

  const apikey = "cc35af96f380dd37b0b248dcd681ef67ae1d5ae9"
  const hash = getHexEncodedHash(userInfo.email, apikey)

  const tawkMessengerRef = useRef()

  const onLoad = () => {
      tawkMessengerRef.current.setAttributes({
        name: userInfo.userName,
        email: userInfo.email,
        hash: hash
      }, function (error) {
        console.log(error)
      })
   
  }

  const onChange = (e) => {
    setCheck(e.target.checked)
  };

  const handleOpenModalAdd = (url) => {
    console.log("open");
    setUrlMedia(url)
    setOpenModalAdd(true);
  }

  const handleCloseModalAdd = () => {
    console.log("close");
    setUrlMedia("");
    setOpenModalAdd(false);

  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // function refreshPage() {
  //   window.location.reload(false);
  // }

  const validateUserAuthMethod = () => {
    const auth = getAuth();
    const hasPasswordProvider = auth.currentUser.providerData.some(item => item.providerId === "password")
    // const hasPhoneProvider = auth.currentUser.providerData.some(item => item.providerId === "phone")
    if (!hasPasswordProvider & process.env.REACT_APP_ENV !== "dev") {
      setModalVisible(true);
    }
    // if (!hasPhoneProvider && process.env.REACT_APP_ENV !== "dev") {
    //   setModalPhoneVisible(true);
    // }
  };

  const handleLogout = async () => {
    await logOut();
  }

  const onFinish = async (values) => {
    try {
      const queryEmail = query(
        collection(firestore, "Users"),
        where("email", "==", values.email)
      );
      const querySnapshot = await getDocs(queryEmail);
      if (!querySnapshot.empty && querySnapshot.docs[0].id !== user.uid) {
        form.setFields([
          {
            name: 'email',
            errors: ['El correo ya esta en uso']
          }
        ])
        return;
      }
      const credential = EmailAuthProvider.credential(values.email, values.password);
      const auth = getAuth();
      await linkWithCredential(auth.currentUser, credential);
      const userDoc = doc(firestore, "Users", user.uid);
      await updateDoc(userDoc, {
        email: values.email,
      });
      setModalVisible(false);
      window.location.reload();
    } catch (err) {
      console.log(err?.code);
      switch (err.code) {
        case "auth/requires-recent-login":
          setError("¡Debe volver a iniciar sesión para continuar con este proceso!");
          setShowLogout(true);
          break;
        case "auth/invalid-email":
          setError("¡Comprueba tu correo electrónico!");
          break;
        case "auth/user-not-found":
          setError("¡Usuario no encontrado, revisa tu correo!");
          break;
        case "auth/wrong-password":
          setError("¡Revisa que tu contraseña este bien escrita!");
          break;
        case "auth/weak-password":
          setError("¡Tu contraseña debe tener por lo menos 6 dígitos!");
          break;
        case "auth/email-already-in-use":
          setError("¡Este correo ya esta registrado!");
          break;
        default:
          setError("Error inesperado");
      }
    }
  };

  async function updateBirthDate() {
    setError("")
    try {
      let hoy = new Date()
      let fechaNacimiento = new Date(birthDate + "T00:00:00")
      let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
      let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
      if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
      ) {
        edad--
      }

      const userDoc = doc(firestore, "Users", user.uid);
      if (birthDate === "") {
        setError("Debes ingresar tu fecha de nacimiento");
        return
      }
      if (check === false) {
        setError("Debes aceptar los términos y condiciones");
        return
      }

      if (edad >= 18) {
        await updateDoc(userDoc, {
          birthDate: new Date(birthDate + "T00:00:00")
        });
        setModalBirthDate(false)
        setMessage({ error: false, msg: "Se guardaron los datos correctamente" });
      } else {
        setError("Debes ser mayor de edad.");
      }
    } catch (err) {
      console.log(err);
    }

  }

  async function updateDate(date, activeLive) {
    const userDoc = doc(firestore, "Users", user.uid);
    try {
      await updateDoc(userDoc, {
        dateBalance: date,
        activeLive: activeLive
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getUser = async () => {
    await getUserData(user).then((snapshot) => {
      if (!snapshot.data().birthDate) {
        setModalBirthDate(true)
      }
      if (userInfo.type === "active") {
        if (!snapshot.data().birthDate) {
          setModalBirthDate(true)
        }
        if (snapshot.data().balance < 100) {
          if (!snapshot.data().dateBalance) {
            let currentDate = new Date()
            updateDate(currentDate, true)
          } else {
            const currentDate = new Date()
            const date = snapshot.data().dateBalance.toDate()
            const dif = currentDate.getTime() - date.getTime()
            const days = dif / (1000 * 60 * 60 * 24)
            if (days >= 1) {
              updateDate(snapshot.data().dateBalance, false)
            }
          }
          setOpenModal(true)
        } else {
          updateDate(null, true)
        }
      }
    });
  };

  const getADS = async () => {
    const queryAdss = query(
      collection(firestore, "Ads"), where("isActive", "==", true)
    );
    const querySnapshot = await getDocs(queryAdss);
    setAds(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    if (user?.uid) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    validateUserAuthMethod()
  }, []);

  // useEffect(() => {
  //   if (open) {
  //     timerId.current = setInterval(() => {
  //       setCountDown(prev => prev - 1);
  //     }, 1000)
  //     return () => clearInterval(timerId.current);
  //   }
  // }, [open]);

  useEffect(() => {
    getADS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getOtp = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   if (number === "" || number === undefined) {
  //     return setError("Completa los campos del formulario!");
  //   }

  //   try {
  //     const queryPhone = query(
  //       collection(firestore, "Users"),
  //       where("phoneNumber", "==", number)
  //     );
  //     const querySnapshot = await getDocs(queryPhone);
  //     if (!querySnapshot.empty && querySnapshot.docs[0].id !== user.uid) {
  //       return setError("¡El número de teléfono ya está en uso!");
  //     }
  //     await setUpRecaptcha(number);
  //     setPhoneNumber(number);
  //     setFlag(true);
  //   } catch (err) {
  //     console.log(err)
  //     switch (err.code) {
  //       case "auth/invalid-phone-number":
  //         setError("¡Número de teléfono no valido, compruebalo de nuevo!");
  //         break;
  //       default:
  //         setError("Error inesperado");
  //     }
  //   }
  //   handleOpen();
  // };

  // const verifyOtp = async (e) => {
  //   e.preventDefault();
  //   setError("");

  //   if (otp === "" || otp === null) return;
  //   try {
  //     const auth = getAuth()
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       "recaptcha-container-link",
  //       { 'size': 'invisible' },
  //       auth
  //     );
  //     const applicationVerifier = window.recaptchaVerifier
  //     const provider = new PhoneAuthProvider(auth);
  //     const verificationId = await provider.verifyPhoneNumber(phoneNumber, applicationVerifier);
  //     const authCredential = PhoneAuthProvider.credential(verificationId, otp);
  //     await linkWithCredential(auth.currentUser, authCredential);
  //     const userDoc = doc(firestore, "Users", user.uid);
  //     await updateDoc(userDoc, {
  //       phoneNumber: phoneNumber,
  //     });
  //     navigate("/homeuser")
  //     refreshPage();
  //   } catch (err) {
  //     console.log(err)
  //     switch (err.code) {
  //       case "auth/invalid-email":
  //         setError("¡Comprueba tu correo electrónico!");
  //         break;
  //       case "auth/user-not-found":
  //         setError("¡Usuario no encontrado, revisa tu correo!");
  //         break;
  //       case "auth/wrong-password":
  //         setError("¡Revisa que tu contraseña este bien escrita!");
  //         break;
  //       case "auth/weak-password":
  //         setError("¡Tu contraseña debe tener por lo menos 6 dígitos!");
  //         break;
  //       case "auth/email-already-in-use":
  //         setError("¡Este correo ya esta registrado!");
  //         break;
  //       case "auth/invalid-verification-code":
  //         setError("¡Verifica tu código!");
  //         break;
  //       default:
  //         //En caso de error inesperado se recarga la ventana
  //         setError("Error inesperado");
  //     }
  //   }
  // };

  // const resendOtp = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   setBlockResendBttn(true);
  //   try {
  //     await setUpRecaptcha(number);
  //   } catch (err) {
  //     switch (err.code) {
  //       case "auth/invalid-phone-number":
  //         setError("¡Número de teléfono no valido, compruebalo de nuevo!");
  //         break;
  //       default:
  //         setError("Error inesperado");
  //     }
  //   }
  // };

  return (
    <>
      <div>
        <TawkMessengerReact propertyId="63d190bfc2f1ac1e202f9d49" // Example Property ID.
          widgetId="1gnl9qrcd" // Example Widget ID.
          onLoad={
            onLoad
          }
          ref={
            tawkMessengerRef
          }></TawkMessengerReact>
      </div>
      {/* Modal birthDay */}
      <ModalAnt
        open={modalBirthDate}
        onClose={handleClose}
        closable={false}
        footer={null}
      >
        <div>
          <h3 style={{ marginBottom: "1em" }}>Para continuar, por favor ingresa tu fecha de nacimiento y acepta los términos y condiciones.</h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CssTextField
              id="date"
              label="Fecha de nacimiento"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setBirthDate(e.target.value)}
              style={{ marginTop: "15px", width: "100%" }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "15px", padding: "1em" }}>
            <Checkbox style={{ marginRight: "1em" }} onChange={onChange} />
            <Typography style={{ color: "#000" }} variant="body2" gutterBottom>
              Confirmo que tengo más de 18 años y acepto los <Link to="/terminos-condiciones" target="_blank">términos y condiciones</Link>
            </Typography>
          </div>
          {message?.msg && (
            <Alert
              severity={message?.error ? "error" : "success"}
              dismissible
              onClose={() => setMessage("")}
            >
              {message?.msg}
            </Alert>
          )}
          {error && <Alert severity="error">{error}</Alert>}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" size="medium" color={'primary'}
              onClick={(e) => updateBirthDate()}
              style={{
                color: "#FFFFFF",
                borderRadius: 18,
                backgroundColor: "#781113",
                padding: "8px 48px",
                fontSize: "14px",
                marginTop: "15px",
                width: "250px"
              }}>
              Guardar
            </Button>
          </div>
        </div>
      </ModalAnt>
      {/* Modal Ads */}
      <Modal
        open={openModalAdd}
        onClose={handleCloseModalAdd}
      >
        <Box sx={style}>
          <div>
            <CloseOutlined onClick={handleCloseModalAdd} style={{ position: "absolute", right: "30px", top: "20px", fontSize: "20px", cursor: "pointer" }} />
          </div>
          <img src={urlMedia} alt="ad" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
        </Box>
      </Modal>
      <div style={{ backgroundColor: "#2b2b2b" }}>
        {/* ADS */}
        <div className="carousel-container">
          <Carousel autoplay>
            {ads.map((ad) => (
              <div key={ad.id} onClick={() => handleOpenModalAdd(ad.urlMediaComplete)}>
                <img className="carousel-img" src={ad.urlMedia} alt={ad.name} style={{ objectFit: "contain" }} />
              </div>
            ))}
          </Carousel>
        </div>
        <Categories />
        <Container style={{ backgroundColor: "#2b2b2b" }} >
          {userInfo?.type !== "banned" &&
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6} lg={6} align="center" sx={{ mt: 4 }}>
                  <StyledTabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor=""
                    textColor="inherit"
                    sx={{ width: "100%" }}
                  >
                    <Tab label="Peleas actuales" sx={{ width: "50%" }} style={{ back: "green", color: "#ffffff" }} />
                    <Tab label="Peleas pasadas" sx={{ width: "50%" }} style={{ color: "#ffffff" }} />
                  </StyledTabs>
                </Grid>
              </Grid>
              {tabValue === 0 && <PeleasActuales />}
              {tabValue === 1 && <PeleasPasadas />}
            </Box>}
          {userInfo?.type === "banned" &&
            <Box
              sx={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 10
              }}
            >
              <Typography component="h1" variant="h5" color={'error'} style={{ fontSize: "22px", fontWeight: "bold", textAlign: "center" }}>
                Tu cuenta ha sido desactivada. Para más información ponte en contacto con algun miembro de la administración de GalloWin
              </Typography>
            </Box>
          }
          {/* modal autenticacón phone number */}
          {/* <ModalAnt
            open={modalPhoneVisisble}
            closable={false}
            footer={null}
          >
            <Box component="form" onSubmit={getOtp} noValidate sx={{ mt: 3 }}
              style={{ display: !flag ? "block" : "none" }}>
              {error && <Alert severity="error" sx={{ mt: 0, mb: 4 }}>{error}</Alert>}
              <Typography sx={{ mb: 3 }} style={{ textAlign: "center", color: "#000000" }}>
                Ingresa tu número telefónico para continuar.
              </Typography>
              <CssTextFieldPhone sx={{ mt: 1 }} continents={["NA"]} forceCallingCode defaultCountry="MX" flagSize="small" value={number} onChange={setNumber} fullWidth autoFocus />
              &nbsp;
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mb: 6 }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: 28,
                  backgroundColor: "#781113",
                  padding: "10px 36px",
                  fontSize: "14px"
                }}

              >
                Continuar
              </Button>
              <div id="recaptcha-container"></div>
              <div id="recaptcha-container-link"></div>
            </Box>

            <Box component="form" onSubmit={verifyOtp} noValidate sx={{ mt: 28 }}
              style={{ display: flag ? "block" : "none" }}>
              <Typography component="h3" variant="h6" color={'#1D1D1B'} sx={{ mb: 2 }} style={{ textAlign: "center", }}>
                Verifica tu cuenta
              </Typography>
              <Typography sx={{ mb: 2 }} component="h1" variant="body1" style={{ textAlign: "center", }}>
                Ingresa el código de verificación que enviamos a tu teléfono {number}
              </Typography>
              {error && <Alert severity="error">{error}</Alert>}
              <CssTextField
                margin="normal"
                required
                fullWidth
                name="otp"
                label="Código de verificación"
                type="otp"
                id="otp"
                onChange={(e) => setOtp(e.target.value)}
              />
              {open ?
                <Typography sx={{ mt: 2 }} textAlign="center" component="h1" variant="h5" align="center" style={{ fontSize: "14px", color: 'GrayText' }}>
                  Reenviar código : {countDown}
                </Typography> :
                <>
                  {!blockResendBttn ?
                    <Button
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{ mt: 3, mb: 3 }}
                      style={{
                        borderRadius: 22,
                        color: "#603B9A",
                        padding: "8px 24px",
                        fontSize: "12px"
                      }}
                      onClick={(e) => resendOtp(e)}
                    >
                      Reenviar código
                    </Button> :
                    <Button
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled
                      sx={{ mt: 3, mb: 3 }}
                      style={{
                        borderRadius: 22,
                        padding: "8px 24px",
                        fontSize: "12px"
                      }}
                    >
                      Código reenviado
                    </Button>
                  }
                </>
              }
              &nbsp;
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 4 }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: 28,
                  backgroundColor: "#E81A1A",
                  padding: "10px 36px",
                  fontSize: "14px",
                  marginBottom: "1em"
                }}
              >
                Verificar
              </Button>
              <Button
                variant="contained"
                fullWidth
                size="small"
                color="primary"
                sx={{ mt: 1, mb: 5 }}
                style={{
                  borderRadius: 22,
                  backgroundColor: "#EA9F26",
                  padding: "10px 36px",
                  fontSize: "12px"
                }}
                onClick={(e) => refreshPage(e)}
              >
                Regresar
              </Button>
              <div id="recaptcha-container-link"></div>
              <div id="recaptcha-container-resend" />
            </Box>
          </ModalAnt> */}
          {/* modal autenticación */}
          <ModalAnt
            open={modalVisisble}
            closable={false}
            footer={null}
          >
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              requiredMark={false}
            >
              <Typography component="h1" variant="p" color={'#000000'} align="center" sx={{ mt: 10, mb: 3 }} style={{ fontWeight: "bolder", marginBottom: "20px" }}>
                Agrega un correo y contraseña para inciar sesión con este metodo
              </Typography>
              <Form.Item
                name="email"
                label="Correo electrónico"
                rules={[
                  {
                    type: 'email',
                    message: 'Ingrese un correo valido',
                  },
                  {
                    required: true,
                    message: 'Ingrese un correo',
                  },
                ]}
              >
                <Input style={{ height: "46px" }} placeholder="ejemplo@gmail.com" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Ingrese su contraseña',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (value.length < 6) {
                        return Promise.reject(
                          "La contraseña debe ser mínimo de 6 dígitos"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input.Password style={{ height: "46px" }} />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirme su contraseña"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Confirme su contraseña',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Las contraseñas no coinciden'));
                    },
                  }),
                ]}
              >
                <Input.Password style={{ height: "46px" }} />
              </Form.Item>

              {error && <Alert severity="error">{error}</Alert>}

              {!showLogout ?
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                  style={{
                    color: "#FFFFFF",
                    borderRadius: 28,
                    backgroundColor: "#E81A1A",
                    padding: "10px 36px",
                    fontSize: "14px"
                  }}
                >
                  Guardar
                </Button>
                :
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 1 }}
                  style={{
                    color: "#FFFFFF",
                    borderRadius: 28,
                    backgroundColor: "#E81A1A",
                    padding: "10px 36px",
                    fontSize: "14px"
                  }}
                  onClick={handleLogout}
                >
                  Aceptar
                </Button>
              }

            </Form>
          </ModalAnt>
          {/* modal notifcación */}
          {!modalVisisble &&
            <div>
              <ModalAnt
                open={openModal}
                onClose={handleClose}
                closable={false}
                footer={null}
              >
                <p className='title-modal-payment'>¡ATENCIÓN!</p>
                <p className='span-option-payment'>
                  Para seguir disfrutando de nuestro streaming, debes tener al menos $100.00 en tu cuenta. Por ahora, seguirás teniendo acceso por 24 horas, después el video en vivo será bloqueado hasta que agregues saldo a tu cuenta.
                </p>
                <div className='container-buttons-footer-modal'>
                  <div className='item'>
                    <button
                      key="submit"
                      className='btnSelectPaymentModal'
                      onClick={handleClose}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </ModalAnt>
            </div>}
        </Container>


      </div>
    </>
  );
};

export default HomeUser;
