import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import { FightIdAdmin } from '../context/fightsContext';
import { useNavigate, Link } from 'react-router-dom';
import { Stack } from "@mui/material";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { dbFights } from "../firebase";
import { onSnapshot, collection, query, orderBy, updateDoc, doc } from "firebase/firestore";
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BlockIcon from '@mui/icons-material/Block';
import Pagination from '@mui/material/Pagination';
import TextField from '@mui/material/TextField';
import { useUserAuth } from "../context/UserAuthContext";

function FightsList({ getFightId, getFightIdView, setFightView }) {
    const navigate = useNavigate();
    const [fights, setFights] = useState([]);
    const { setFightIdAdmin } = useContext(FightIdAdmin);
    const {userInfo} = useUserAuth();

    //===== PAGINATION ========================
    const pageSize = 30;
    const [sliceData, setSliceData] = useState([]);
    const [listSize, setListSize] = useState(5);
    const [pagination, setPagination] = useState({
      count: 0,
      from: 0,
      to: pageSize
    });

    const handleChange = (event, page) =>{
        const from = (page - 1) * pageSize;
        const to = (page - 1) * pageSize + pageSize;
  
        setPagination({ ...pagination, from: from, to: to});
    }

    const [search, setSearch] = useState("");

    //=============================================================

    const getFightsRealTime = async (from, to) =>{
      const fightsCollectionRef = collection(dbFights, "Fights");
      const q = query(fightsCollectionRef, orderBy("date", "desc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setFights(items);
        setSliceData(items.slice(from, to));
        setListSize(items.length);
      });
      return () => unsubscribe();
    }

    const privateHandler = async (id, current) => {
      let boolHelpState;
      if(current){
        boolHelpState = false;
      } else boolHelpState = true;
      
      const fightDoc = doc(dbFights, "Fights", id);
      await updateDoc(fightDoc, {
        privateEvent: boolHelpState
      });
  };

    const getFightIdHandler = async (id) => {
      setFightIdAdmin(id);
      navigate("/editar-pelea")
    };

    const handleFightView = (id) => {
      setFightIdAdmin(id);
    };

    const statusToString = (statusData) =>{
      let estatus = "";
      if(statusData){
        estatus = "activa";
      }
      else{
        estatus = "Terminada";
      }
      return estatus;
    }

    const dateOptions = {
      month: "long",
      day: "numeric",
    };

    useEffect(() => {
      getFightsRealTime(pagination.from, pagination.to).then(response =>{
        setPagination({ ...pagination, count: listSize});
      });
      setFightIdAdmin("No ID");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.from, pagination.to]);

    return (
        <Container component="main" width="100%" style={{ paddingBottom: "2em" }}>
          <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            }}
          >
            <Stack 
              sx={{ mt:5, mb:3 }}
              direction="row"
              spacing={5}
              justifyContent="flex-start"
            >
              <Typography component="h1" variant="h4" color={'#1D1D1B'} align="left">
                Peleas
              </Typography>
              <Button variant="contained" color="primary" size="large" startIcon={<AddIcon />} 
                onClick={(e) => navigate("/crear-pelea")}
                style={{
                borderRadius: 35,
                backgroundColor: "#E81A1A",
                padding: "8px 28px",
                fontSize: "13px",
                textTransform: 'none'
              }}>
                Nueva pelea
              </Button>
              <TextField style={{color:"#FFFFFF"}} id="busqueda" variant="outlined" placeholder="Búsqueda"
                sx={{width: { sm: "50px", md: "300px", lg: "320px" }}}
                  onChange={(e) => setSearch(e.target.value)}    
              />
              {userInfo.type === "admin" &&
              <Button variant="contained" color="primary" size="large"
                onClick={(e) => navigate("/report-fight")}
                style={{
                borderRadius: 35,
                backgroundColor: "#E81A1A",
                padding: "8px 28px",
                fontSize: "13px",
                textTransform: 'none'
              }}>
                Reporte
              </Button> 
              }         
            </Stack>
          </Box>
            <TableContainer component={Paper} style={{borderRadius: 20,}}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
              {search.toLowerCase() === "" ?
                  <TableBody>
                  {sliceData.map((doc, index) => (
                      <TableRow
                      key={doc.id}            
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      > 
                        <TableCell component="th" scope="row" align="center">
                          <Box
                            component="img"
                            alt="Imagen previa"
                            src="https://img.freepik.com/fotos-premium/peleas-gallos-juegos-azar-tradicionales-republica-dominicana_168410-2426.jpg?w=2000"
                            style={{width:"70px", height:"70px", marginLeft:"8px", borderRadius: 14,}}
                          />                          
                        </TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{fontSize:"14px", color:'#1D1D1B', fontWeight:"bold", textDecoration: 'none'}}>{doc.fightName}</TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{fontSize:"13px", color:'GrayText', textAlign:"justify", textDecoration: 'none'}}>{doc.description}</TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{fontSize:"13px", color:'GrayText', textDecoration: 'none'}}>
                          {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                        </TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{textDecoration: 'none'}} >{statusToString(doc.status)}</TableCell>
                        <TableCell align="center">
                          <Grid container spacing={2} direction="row" justifyContent="center" >
                            <Grid item xs={4} md={4} lg={4}>
                              <Link to={`/fightview-admin/${doc.id}`}>
                                <VisibilityIcon sx={{fontSize: "20px", color: "#EA9F26", }} 
                                style={{backgroundColor: "#FFFFFF", borderRadius: 24, padding: "0px", textDecoration: 'none'}} 
                                onClick={(e) => handleFightView(doc.id)}>
                                </VisibilityIcon>
                              </Link>                           
                            </Grid>                            
                            <Grid item xs={4} md={4} lg={4}>
                              <EditIcon sx={{fontSize: "20px", color: "#EA9F26", }} 
                              style={{backgroundColor: "#FFFFFF", borderRadius: 24, padding: "0px",}} 
                              onClick={(e) => getFightIdHandler(doc.id)}>
                              </EditIcon>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                              {/*  {doc.ended &&*/}
                              <>
                              {doc.privateEvent &&
                                <BlockIcon sx={{fontSize: "20px", color: "#E81A1A", }} 
                                style={{backgroundColor: "gray", borderRadius: 24, padding: "10px 10px",}} 
                                onClick={(e) => privateHandler(doc.id, doc.privateEvent)}>
                                </BlockIcon>
                              }
                              {!doc.privateEvent &&
                                <BlockIcon sx={{fontSize: "20px", color: "#E81A1A", }} 
                                style={{backgroundColor: "#FFFFFF", borderRadius: 24, padding: "0px",}} 
                                onClick={(e) => privateHandler(doc.id, doc.privateEvent)}>
                                </BlockIcon>
                              }
                              </>
                              {/*}*/}
                            </Grid>                            
                          </Grid>
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
                  :
                  <TableBody>
                  {fights
                  .filter((item) => {
                    return search.toLowerCase() === "" ? item
                    : item.fightName.toLowerCase().includes(search);
                  })                  
                  .map((doc, index) => (
                      <TableRow
                      key={doc.id}            
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      > 
                        <TableCell component="th" scope="row" align="center">
                          <Box
                            component="img"
                            alt="Imagen previa"
                            src="https://img.freepik.com/fotos-premium/peleas-gallos-juegos-azar-tradicionales-republica-dominicana_168410-2426.jpg?w=2000"
                            style={{width:"70px", height:"70px", marginLeft:"8px", borderRadius: 14,}}
                          />                          
                        </TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{fontSize:"14px", color:'#1D1D1B', fontWeight:"bold", textDecoration: 'none'}}>{doc.fightName}</TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{fontSize:"13px", color:'GrayText', textAlign:"justify", textDecoration: 'none'}}>{doc.description}</TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{fontSize:"13px", color:'GrayText', textDecoration: 'none'}}>
                          {new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                        </TableCell>
                        <TableCell align="center" component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)} style={{textDecoration: 'none'}} >{statusToString(doc.status)}</TableCell>
                        <TableCell align="center">
                          <Grid container spacing={2} direction="row" justifyContent="center" >
                            <Grid item xs={4} md={4} lg={4}>
                              <Link to={`/fightview-admin/${doc.id}`}>
                                <VisibilityIcon sx={{fontSize: "20px", color: "#EA9F26", }} 
                                style={{backgroundColor: "#1D1D1B", borderRadius: 24, padding: "10px 10px",}} 
                                component={Link} to={`/fightview-admin/${doc.id}`} onClick={(e) => handleFightView(doc.id)}>
                                </VisibilityIcon>
                                </Link>                              
                            </Grid>                            
                            <Grid item xs={4} md={4} lg={4}>
                              <EditIcon sx={{fontSize: "20px", color: "#EA9F26", }} 
                              style={{backgroundColor: "#1D1D1B", borderRadius: 24, padding: "10px 10px",}} 
                              onClick={(e) => getFightIdHandler(doc.id)}>
                              </EditIcon>
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>
                              {/*  {doc.ended &&*/}
                              <>
                              {doc.privateEvent &&
                                <BlockIcon sx={{fontSize: "20px", color: "#E81A1A", }} 
                                style={{backgroundColor: "gray", borderRadius: 24, padding: "10px 10px",}} 
                                onClick={(e) => privateHandler(doc.id, doc.privateEvent)}>
                                </BlockIcon>
                              }
                              {!doc.privateEvent &&
                                <BlockIcon sx={{fontSize: "20px", color: "#E81A1A", }} 
                                style={{backgroundColor: "#1D1D1B", borderRadius: 24, padding: "10px 10px",}} 
                                onClick={(e) => privateHandler(doc.id, doc.privateEvent)}>
                                </BlockIcon>
                              }
                              </>
                              {/*}*/}
                            </Grid>                            
                          </Grid>
                        </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>}              
              </Table>
            </TableContainer>
            {search.toLowerCase() === "" ?
            <Grid container direction="column" alignItems="center" justifyContent="center" sx={{mt: 8}} >
                <Grid item xs={12} sm={12} md={12} alignItems="center" justifyContent="center"  >
                    <Pagination count={Math.ceil(listSize / pageSize)}
                    onChange={handleChange}
                    color="primary" 
                    sx={{mb: 2, mt: -2}}
                    />
                </Grid>
            </Grid>:
            <></>}
          <Box sx={{mt: 10, mb:5}}>
                <hr></hr>
          </Box>
        </Container>
    );
};

export default FightsList;
