import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import { Modal } from 'antd';

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#000',
    borderColor: '#000',
  },
  '& label.Mui-focused': {
    color: '#000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000',
  },

  '& .MuiInputLabel-root': {
    color: '#000',
    borderColor: '#000',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#000',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#000',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#000',
      },
    }
  }
});

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ffffff',
  },
  '& .MuiInputLabel-root': {
    color: '#ffffff',
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
  },
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [emailReset, setEmailReset] = useState("");
  const [error, setError] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [password, setPassword] = useState("");
  const [btnAceptar, setbtnAceptar] = useState(false);
  const [btnCerrar, setbtnCerrar] = useState(false);
  const { logIn, forgotPass } = useUserAuth();
  const [modalForgotPass, setModalForgotPass] = useState(false);
  const navigate = useNavigate();

  const handleChanged = async (e) => {
    setModalForgotPass(true)
    setbtnAceptar(true)
  }

  const handleClosed = async (e) => {
    setModalForgotPass(false)
    setbtnAceptar(true)
    setbtnCerrar(false)
    setErrorPass("")
    setSuccessMessage("")
    setEmailReset("")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (email === "") {
      setError("Ingresa un correo electrónico");
      return;
    }
    if (password === "") {
      setError("Ingresa una contraseña");
      return;
    }
    try {
      await logIn(email.trim().toLowerCase(), password);
      navigate("/homeuser");
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          setError("¡Comprueba tu correo electrónico!");
          break;
        case "auth/user-not-found":
          setError("¡Usuario no encontrado, revisa tu correo!");
          break;
        case "auth/wrong-password":
          setError("¡Revisa que tu contraseña este bien escrita!");
          break;
        default:
          //En caso de error inesperado se recarga la ventana
          //window.location.reload(false);
          setError("Error inesperado");
      }
    }
    //
  };

  const resetPassword = async (e) => {
    if (emailReset === "") {
      setErrorPass("Ingresa tu correo electrónico");
      return;
    }
    try {
      await forgotPass(emailReset);
      setSuccessMessage("Correo enviado correctamente")
      setErrorPass("")
      setbtnCerrar(true)
      setbtnAceptar(false)
    } catch (err) {
      switch (err.code) {
        case "auth/invalid-email":
          setErrorPass("¡Comprueba tu correo electrónico!");
          break;
        case "auth/user-not-found":
          setErrorPass("¡Usuario no encontrado, revisa tu correo!");
          break;
        case "auth/wrong-password":
          setErrorPass("¡Revisa que tu contraseña este bien escrita!");
          break;
        default:
          setErrorPass("Error inesperado");
      }
    }
  };

  return (
    <>
      {/* Modal Success Upload */}
      <Modal
        open={modalForgotPass}
        closable={true}
        onOk={handleClosed}
        onCancel={handleClosed}
        footer={null}
        centered
      >
        <div>
          <Typography component="h1" variant="h5" color={'#000'} align="center" >
            Ingresa tu correo electrónico
          </Typography>
          <br></br>
          <CssTextField
            margin="normal"
            required
            fullWidth
            id="formEmail"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            type="email"
            onChange={(e) => setEmailReset(e.target.value)}
            value={emailReset}
          />
          {errorPass && <Alert severity="error">{errorPass}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
          <Typography variant="body2" align="center">
            Enviaremos un correo electrónico con un link para restablecer tu contraseña. En caso de no recibirlo, favor de revisar la bandeja de spam.
          </Typography>
          {btnAceptar &&
            <Button
              style={{
                borderRadius: 35,
                backgroundColor: "#603B9A",
                padding: "10px 36px",
                fontSize: "14px"
              }}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 4 }}
              onClick={resetPassword}
            >
              Aceptar
            </Button>
          }
          {btnCerrar &&
            <Button
              style={{
                borderRadius: 35,
                backgroundColor: "rgb(120, 17, 19)",
                padding: "10px 36px",
                fontSize: "14px"
              }}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 4 }}
              onClick={handleClosed}
            >
              Cerrar
            </Button>
          }
        </div>
      </Modal>
      <div className="container-login-image">
        <Typography component="h1" variant="h4" color={'#FFFFFF'} align="center" sx={{ mt: 10, mb: 3 }} style={{ fontStyle: "italic", fontWeight: "bolder" }}>
          Iniciar sesión
        </Typography>
      </div>
      <div style={{ backgroundColor: "#2b2b2b" }}>
        <Container component="main" maxWidth="xs" style={{ backgroundColor: "#2b2b2b" }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
              {error && <Alert severity="error">{error}</Alert>}
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <CssTextFiel
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1 }}
                style={{
                  color: "#FFFFFF",
                  borderRadius: 28,
                  backgroundColor: "#781113",
                  padding: "10px 36px",
                  fontSize: "14px"
                }}
              >
                Iniciar sesión
              </Button>
              {/* <Button
                fullWidth
                variant="contained"
                color={'secondary'}
                sx={{ mt: 2, mb: 8 }}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#b35426",
                  borderRadius: 28,
                  padding: "10px 36px",
                  fontSize: "14px"
                }}
                onClick={(e) => navigate("/phonelogin")}
              >
                Iniciar sesión con el teléfono
              </Button> */}
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
                  <Typography style={{ textAlign: "center", color: "#ffffff" }}>
                    <Button

                      onClick={ handleChanged}
                    >
                      ¿Olvidaste la contraseña?
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
                  <Typography style={{ textAlign: "center", color: "#ffffff" }}>
                    ¿No estás registrado?
                  </Typography>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color={'primary'}
                size={'small'}
                sx={{ mt: 2, mb: 2, }}
                style={{
                  color: "#000000",
                  backgroundColor: "#878484",
                  borderRadius: 28,
                  padding: "10px 36px",
                  fontSize: "14px"
                }}
                onClick={(e) => navigate("/signup")}
              >
                Registrate
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default Login;