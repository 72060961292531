import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core';
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from "../context/UserAuthContext";
import { Divider, List, ListItem, ListItemIcon, ListItemText, Drawer, } from "@material-ui/core";
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack } from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from '../firebase';
import Grid from '@mui/material/Grid';
import { viewsUserType } from '../utils/constants';
import LogoCompleto from "../Images/logoGALLOMX-08.png"
import LogoPequeño from "../Images/logoGALLOMX-11.png"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './Navbar.css'

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  menuSliderContainer: {
    width: 200,
    background: "#660707",
    height: "100vh"
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
    width: theme.spacing(13),
    height: theme.spacing(13)
  },
  listItem: {
    marginTop: "5px",
    color: "#FFFFFF"
  },
  divider: {
    borderTop: "3px solid white"
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  menuSliderContainerAdmin: {
    width: 200,
    background: "#660707",
    height: "100vh"
  },
  drawerPaper: {
    width: 180,
  },
}));


export default function ButtonAppBar() {
  const classes = useStyles();
  const { user, logOut, getUserData } = useUserAuth();
  const navigate = useNavigate();
  const [showNavbarUser, setNavbarUser] = useState(false);
  const [nombre, setNombre] = useState("");
  const [balance, setBalance] = useState(0);

  const [userViews, setUserViews] = useState([]);
  const [navbarIsActive, setNavbarIsActive] = useState(false);
  const { changeCategory } = useUserAuth();

  //Drawer ========
  const [open, setOpen] = useState(false);

  const toggleSlider = (category) => {
    setOpen(!open);
    changeCategory(category?.id)
  };

  const sideList = () => (
    <Box className={classes.menuSliderContainer} component="div">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 1 }}>
        <Grid item xs={12} align="center">
          <Link to={"/homeuser"} state={{ category: "Gallos" }}>
            <img src={LogoCompleto} alt={"logoGALLOMX"} style={{ height: "55px", width: "160px" }}
              onClick={() => toggleSlider(userViews[0])} />
          </Link>
        </Grid>
      </Grid>
      <Divider />
      <List>
        {userViews.map((listItem, index) => (
          <Link to={"/" + listItem.text} key={index} state={{ category: listItem?.category }}>
          <ListItem className={classes.listItem} button key={index}>
            <ListItemIcon className={classes.listItem} onClick={() => toggleSlider(listItem)}>
              {listItem.listIcon}
            </ListItemIcon>
            <ListItemText primary={listItem.listText} onClick={() => toggleSlider(listItem)} />
          </ListItem>
          </Link>
        ))}
        <Stack
          className={classes.listItem}
          sx={{ pt: 2, ml: 2.5 }}
          direction="row"
          spacing={3}
          justifyContent="flex-start"
        >
          <LogoutIcon />
          <Button variant="text" color="inherit" size='small' onClick={handleLogout}>
            cerrar sesión
          </Button>
        </Stack>
      </List>
    </Box>
  );

  const handleLogout = async () => {
    await logOut();
    toggleSlider();
    setNavbarIsActive(false);
    setNavbarUser(false);
    setNombre("");
    setBalance(0);
    navigate("/");
  };

  const getViewsList = async () => {
    if (!user.uid) {
      setNavbarIsActive(false);
      return setNavbarUser(false);
    } else {
      await getUserData(user).then((snapshot) => {
        if (snapshot === null || snapshot.data().userName === null) {
          window.location.reload(false);
          return;
        }
        setNombre(snapshot.data().userName);
        setBalance(snapshot.data().balance);
        setNavbarIsActive(true);
        setNavbarUser(true);
        if (snapshot.data().type === "banned") {
          setUserViews(viewsUserType.active);
        }
        if (snapshot.data().type === "") {
          setUserViews(viewsUserType.active);
        } else {
          setUserViews(viewsUserType[snapshot.data().type]);
        }
      });

      const unsub = onSnapshot(doc(firestore, "Users", user.uid), (doc) => {
        setNombre(doc.data().userName);
        let fixedBalance = doc.data().balance;
        setBalance(fixedBalance.toFixed(2));
      });

      return () => unsub();
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getViewsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      {navbarIsActive ?
        <Box sx={{ flexGrow: 1 }}>
          <>
            <AppBar position="fixed" color="inherit" style={{ backgroundColor: "#fff" }}>
              <Toolbar style={{paddingLeft: "0px", paddingRight: "5px"}}>
                <img src={LogoPequeño} alt={"logoGALLOMX"} style={{ height: "50px", width: "50px" }}
                  onClick={(e) => toggleSlider()} />
                <Typography variant="h6" component="div" color="black" sx={{ flexGrow: 1 }} style={{ fontSize: "15px" }} >
                  {showNavbarUser ? <>{nombre.substring(0, 11)}</> : <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit' }}>GalloWin</Link>}
                </Typography>
                <Drawer open={open} anchor="left" onClose={toggleSlider}>
                  {sideList()}
                </Drawer>
                {showNavbarUser ? <>
                  <Link to={"/next-fight"}>
                    <Typography variant="body1" color="white"
                      style={{
                        borderRadius: 20,
                        padding: "6px 18px",
                        marginRight: "3px",
                        alignItems: "center",
                        display: "flex",
                        backgroundColor: "#b35426"
                      }}>
                      <CalendarMonthIcon />
                    </Typography>
                  </Link>
                  <Link to={"/deposits"}>
                    <Typography variant="body1" color="white"
                      style={{
                        minHeight:"36px",
                        borderRadius: 20,
                        alignItems:"center",
                        display:"flex",
                        backgroundColor: "#781113",
                        padding: "4px 16px",
                        fontSize:"13px"
                      }}>
                      Saldo: ${balance}
                    </Typography>
                  </Link>
                </> : <></>
                }
              </Toolbar>
            </AppBar>
            <div className={classes.offset}></div>
          </>
        </Box>
        : null
      }
    </>
  );
}