import React from 'react';
import { Link } from 'react-router-dom';
import { betsCategories } from '../utils/constants';
import { useUserAuth } from "../context/UserAuthContext";

const Categories = () => {
  const { activeCategory, changeCategory } = useUserAuth();

  const currentCategory = activeCategory === null ? 1 : activeCategory;
  
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", padding: "10px" }}>
      {betsCategories.map((category, index) => {
        return (
          <Link to={category.to} key={index} state={{ category: category.name }} >
            <div 
              style={{ 
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                padding: "5px",            
              }}
              onClick={() => changeCategory(category.id)}
            >
              <div
                style={{ 
                  backgroundColor: "#ffffff",
                  borderRadius: "30px",
                  padding: "5px",
                  border: currentCategory === category.id ? "2px solid #b35426" : "2px solid #ffffff",
                }}
              >
                <img src={category.img} alt={category.name} style={{ width: "40px", height: "40px" }} />
              </div>
              <p style={{ marginLeft: "10px", fontSize: "14px", color: currentCategory === category.id ? "#b35426" : "#ffffff" }}>{category.name}</p>
            </div>
          </Link>        
        )
      })}
    </div>
  );
};

export default Categories;
