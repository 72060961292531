import React, { useEffect, useState } from 'react'
import { Grid, Button, Card, CardContent, CardMedia, Typography, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';
import { useLocalStorage } from '../useLocalStorage';
import { db } from "../firebase";
import { onSnapshot, collection, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'antd';
import './PeleasActuales.css'

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "100px",
    paddingTop: "30px"
  },
  card: {
    minWidth: 320,
    position: 'relative',
    boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
    overflow: 'visible',
    borderRadius: '1.5rem',
    transition: '0.4s',
    '&:hover': {
      transform: 'translateY(-2px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      display: 'block',
      width: '100%',
      bottom: -1,
      height: '100%',
      borderRadius: '1.5rem',
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
  main: {
    overflow: 'hidden',
    borderTopLeftRadius: '1.5rem',
    borderTopRightRadius: '1.5rem',
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to top, #014a7d, rgba(0,0,0,0))',
    },
  },
  content: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
    padding: '1.5rem 1.5rem 1rem',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  tag: {
    display: 'inline-block',
    fontFamily: "'Sen', sans-serif",
    backgroundColor: '#DC1515',
    borderRadius: '1rem',
    padding: '2px 1rem',
    color: '#fff',
    marginBottom: '0.5rem',
  },
  tagBetOpen: {
    marginLeft: "auto",
    marginRight: "5px",
    marginTop: "-35px",
    position: "relative",
    display: 'block',
    width: "fit-content",
    textAlign: "center",
    backgroundColor: '#fff',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1.2rem',
  },
  tagBetClose: {
    marginLeft: "auto",
    marginRight: "5px",
    marginTop: "-35px",
    position: "relative",
    display: 'block',
    width: "fit-content",
    textAlign: "center",
    backgroundColor: '#781113',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1.2rem',
  },
  tagNormal: {
    marginLeft: "0px",
    display: 'block',
    width: "fit-content",
    textAlign: "initial",
    backgroundColor: '#b35426',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1rem',
    color: "#fff"
  },
  title: {
    display: 'inline-block',
    fontFamily: "'Sen', sans-serif",
    fontSize: '2rem',
    fontWeight: 800,
    color: '#fff',
  },
  author: {
    zIndex: 1,
    position: 'relative',
    borderBottomLeftRadius: '1.5rem',
    borderBottomRightRadius: '1.5rem',
  },
  shadow: {
    transition: '0.2s',
    position: 'absolute',
    zIndex: 0,
    width: '88%',
    height: '100%',
    bottom: 0,
    borderRadius: '1.5rem',
    backgroundColor: 'rgba(0,0,0,0.06)',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  shadow2: {
    bottom: 0,
    width: '72%',
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
  action: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
});

const PeleasActuales = ({ category }) => {
  const { user } = useUserAuth();
  const classes = useStyles();
  const [, setUidUser] = useLocalStorage("uidUser", "");
  const [sports, setSports] = useState([]);
  const [sportsLive, setSportsLive] = useState([]);
  //this variable checks if we have more than one fight
  const [noSports, setNoSports] = useState(false);
  const [checked, setChecked] = useState(true);
  const [eventsLocations, seteventsLocations] = useState([]);
  const [sportsFilterLocations, setSportsFilterLocations] = useState([]);
  const [sportsFilter, setSportsFilter] = useState([]);
  const [sportsLiveFilter, setSportsLiveFilter] = useState([]);
  const [evetLocation, setEventLocation] = useState([]);

  const navigate = useNavigate();

  //images
  let image;
  if (category !== "Caballos") {
    image = "https://firebasestorage.googleapis.com/v0/b/gallo-win-dev.appspot.com/o/images%2Fview-sport.jpg?alt=media&token=f2b00fbe-6e39-4396-b7f4-423faa34cf6e";
  } else {
    image = "https://firebasestorage.googleapis.com/v0/b/gallo-win-dev.appspot.com/o/images%2Fview-caballos.avif?alt=media&token=b30df94f-9637-4a39-8d47-8973ad472474"
  }

  const onChange = (e) => {
    if (e.target.checked === false) {
      return;
    } else {
      setChecked(e.target.checked)
      setEventLocation([])
      getSportsRealTime()
    }
  };

  const handleClick = (locationTag, eventSelect) => {
    let eventSelected = false
    setSportsLiveFilter([])
    setSportsFilter([])   

    sportsFilterLocations.filter((item) => {
      if (item.location === eventSelect.location) {
        eventSelected = true
      }
      return false
    })

    if (eventSelected === false) {
      sportsLive.filter((item) => {
        if (item.location === locationTag) {
          sportsFilterLocations.push(item)
          evetLocation.push(item.location)
        }
        return false
      })

      sports.filter((item) => {
        if (item.location === locationTag) {
          sportsFilterLocations.push(item)
          evetLocation.push(item.location)
        }
        return false
      })
    } else {
      const newTags = sportsFilterLocations.filter((event) => event.location !== locationTag);
      const newEvents= evetLocation.filter((event) => event !== locationTag);
      setSportsFilterLocations(newTags)
      setEventLocation(newEvents)
    }

    setChecked(false) 

  };

  const getSportsRealTime = async () => {
    const sportsCollectionRef = collection(db, "Events");
    const q = query(sportsCollectionRef, where("ended", "==", false), where("category", "==", category));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let items = [];
      let itemsLive = [];
      let itemsLocations = []
      let itemsLocationsEvents = []

      querySnapshot.forEach((doc) => {
        if (doc.data().live) {
          itemsLive.push({ ...doc.data(), id: doc.id });
        } else {
          items.push({ ...doc.data(), id: doc.id });
        }
        if (doc.data().status && !doc.data().privateEvent) {
          itemsLocations.push({ ...doc.data(), id: doc.id})
          itemsLocationsEvents.push(doc.data().location)
        }
      });

      items.sort(function (a, b) {
        const dateA = a.date;
        const dateB = b.date;
        if (dateB < dateA) {
          return -1;
        }
        if (dateB > dateA) {
          return 1;
        }
        return 0;
      });

      itemsLive.sort(function (a, b) {
        const dateA = a.date;
        const dateB = b.date;
        if (dateB < dateA) {
          return -1;
        }
        if (dateB > dateA) {
          return 1;
        }
        return 0;
      });

      let newArray = [];
      let lookupObject  = {};
 
      for(let i in itemsLocations) {
         lookupObject[itemsLocations[i]["location"]] = itemsLocations[i];
      }
 
      for(let i in lookupObject) {
          newArray.push(lookupObject[i]);
      }

      setSportsLive(itemsLive);
      setSports(items);
      seteventsLocations(newArray)
      setSportsFilter(items)
      setSportsLiveFilter(itemsLive)
      setSportsFilterLocations([])

      if (items.length === 0 && itemsLive.length === 0) {
        setNoSports(true);
      } else {
        setNoSports(false);
      }
    });

    return () => unsubscribe();
  }

  // request a weekday along with a long date
  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  const dataSave = (idUser) => {
    //This save function helps reload data in eventview
    setUidUser(idUser);
  }

  useEffect(() => {
    getSportsRealTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <Container component="main" maxWidth="lg" >
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center" style={{ marginTop: "10px", }}>
          <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
            <Grid item xs={8} md={12} lg={12} align="center" sx={{ mt: 4 }}>
              <Typography component="h1" variant="h5" style={{ fontSize: "16px", margintop: "10px", color: "#ffffff" }}>
                Ingresa saldo a tu cuenta para comenzar a jugar.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} align="center" sx={{ mt: 4 }}>
              <Button variant="contained" size="medium" color={'primary'}
                onClick={(e) => navigate("/add-money")}
                style={{
                  color: "#FFFFFF",
                  borderRadius: 18,
                  backgroundColor: "#781113",
                  padding: "8px 48px",
                  fontSize: "14px",
                  marginTop: "15px"
                }}>
                Agregar saldo
              </Button>
              <div style={{ height: "15px" }} />
            </Grid>
            {eventsLocations.length > 1 &&
            <Typography component="h1" variant="h5" style={{ fontSize: "16px", margintop: "10px", marginBottom: "10px", color: "#ffffff" }}>
              Ubicación de la pelea.
            </Typography>}
            <div style={{ display: "ruby", maxWidth: "350px", alignItems: "center" }}>
            {eventsLocations.length > 1 &&
              <Checkbox
                className='button-container-tag'
                style={{ marginRight: "8px", marginBottom: "8px" }}
                checked={checked}
                onChange={onChange}>Todos
              </Checkbox>
              }
            
              {eventsLocations.map((doc, index) => {
                return (
                  <>
                  {eventsLocations.length > 1 &&
                    <div
                    style={{ display: "flex", justifyContent: "center" }}
                    className={evetLocation.includes(doc.location) ? 'animate-button-selected' : 'animate-button'}
                    closable
                    key={`btn-${index}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(doc.location, doc);
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <div class="hoverEffect">
                      <div>
                      </div>
                    </div>
                    <span style={{ position: "relative" }}>{doc.location}</span>
                  </div>
              }
                  </>
                );
              })}
            </div>
          </Grid>

          {!noSports ?
            <Grid item xs={12} sm={6} md={4}>
              {sportsLiveFilter.map((doc, index) => {
                if (!doc.status) {
                  return null;
                };
                return (
                  <>
                    {!doc.privateEvent &&
                      <Card style={{ marginBottom: "50px", marginTop: "20px", backgroundColor: "#1D1D1B", borderRadius: 16 }} key={doc.id}>
                        <CardActionArea component={Link} to={`/eventview/${doc.id}`} onClick={(e) => dataSave(user.uid)}>
                          <CardContent>
                            {!doc.live ?
                              <Typography sx={{ fontSize: 14 }} className={classes.tagNormal} gutterBottom>
                                {
                                  new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)
                                }
                              </Typography>
                              :
                              <Typography sx={{ fontSize: 14 }} className={classes.tag} color="error" gutterBottom>
                                En vivo</Typography>
                            }
                            <CardMedia
                              component="img"
                              height="120"
                              image={image}
                            />
                            {doc.betStatus ?
                              <Typography sx={{ fontSize: 14 }} className={classes.tagBetOpen} gutterBottom style={{ color: "#31AF2B" }}>
                                Apuesta abierta
                              </Typography>
                              :
                              <Typography sx={{ fontSize: 14 }} className={classes.tagBetClose} gutterBottom style={{ color: "#F4EFF4" }}>
                                Apuesta cerrada</Typography>
                            }
                            <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", marginBottom: "4px", fontSize: "22px" }} >
                              {doc.eventName}
                            </Typography>
                            <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", fontSize: "12px", marginBottom: "12px" }}>
                              {doc.description}
                            </Typography>
                            <Typography className={classes.tagNormal} color="primary">
                              Hora: {" "}
                              {
                                new Date(doc.time.seconds * 1000).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                              }
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>}  </>
                );
              })}
              {sportsFilter.map((doc, index) => {
                if (!doc.status) {
                  return null;
                };
                return (
                  <li key={doc.id} style={{ listStyle: "none" }} >
                    {!doc.privateEvent &&
                      <Card style={{ marginBottom: "50px", marginTop: "20px", backgroundColor: "#1D1D1B", borderRadius: 16 }}>
                        <CardActionArea component={Link} to={`/eventview/${doc.id}`} onClick={(e) => dataSave(user.uid)}>
                          <CardContent>
                            {!doc.live ?
                              <Typography sx={{ fontSize: 14 }} className={classes.tagNormal} gutterBottom>
                                {
                                  new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)
                                }
                              </Typography>
                              :
                              <Typography sx={{ fontSize: 14 }} className={classes.tag} color="error" gutterBottom>
                                En vivo</Typography>
                            }
                            <CardMedia
                              component="img"
                              height="120"
                              image={image}
                            />
                            {doc.betStatus ?
                              <Typography sx={{ fontSize: 14 }} className={classes.tagBetOpen} gutterBottom style={{ color: "#31AF2B" }}>
                                Apuesta abierta
                              </Typography>
                              :
                              <Typography sx={{ fontSize: 14 }} className={classes.tagBetClose} gutterBottom style={{ color: "#F4EFF4" }}>
                                Apuesta cerrada</Typography>
                            }
                            <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", marginBottom: "4px", fontSize: "22px" }} >
                              {doc.eventName}
                            </Typography>
                            <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", fontSize: "12px", marginBottom: "12px" }}>
                              {doc.description}
                            </Typography>
                            <Typography className={classes.tagNormal} color="primary">
                              Hora: {" "}
                              {
                                new Date(doc.time.seconds * 1000).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                              }
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    } </li>
                );
              })}
              {sportsFilterLocations.map((doc, index) => {
                if (!doc.status) {
                  return null;
                };
                return (
                  <li key={doc.id} style={{ listStyle: "none" }} >
                    {!doc.privateEvent &&
                      <Card style={{ marginBottom: "50px", marginTop: "20px", backgroundColor: "#1D1D1B", borderRadius: 16 }}>
                        <CardActionArea component={Link} to={`/eventview/${doc.id}`} onClick={(e) => dataSave(user.uid)}>
                          <CardContent>
                            {!doc.live ?
                              <Typography sx={{ fontSize: 14 }} className={classes.tagNormal} gutterBottom>
                                {
                                  new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)
                                }
                              </Typography>
                              :
                              <Typography sx={{ fontSize: 14 }} className={classes.tag} color="error" gutterBottom>
                                En vivo</Typography>
                            }
                            <CardMedia
                              component="img"
                              height="120"
                              image={image}
                            />
                            {doc.betStatus ?
                              <Typography sx={{ fontSize: 14 }} className={classes.tagBetOpen} gutterBottom style={{ color: "#31AF2B" }}>
                                Apuesta abierta
                              </Typography>
                              :
                              <Typography sx={{ fontSize: 14 }} className={classes.tagBetClose} gutterBottom style={{ color: "#F4EFF4" }}>
                                Apuesta cerrada</Typography>
                            }
                            <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", marginBottom: "4px", fontSize: "22px" }} >
                              {doc.eventName}
                            </Typography>
                            <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", fontSize: "12px", marginBottom: "12px" }}>
                              {doc.description}
                            </Typography>
                            <Typography className={classes.tagNormal} color="primary">
                              Hora: {" "}
                              {
                                new Date(doc.time.seconds * 1000).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
                              }
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    } </li>
                );
              })}
            </Grid> :
            <Grid item xs sx={{ mt: 4 }}>
              <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#603B9A', fontWeight: "bold" }}>
                No hay eventos actualmente
              </Typography>
            </Grid>
          }
        </Grid>
      </Box>
      <Box sx={{ mt: 5, mb: 1 }} style={{ minHeight: "230px" }} />
    </Container>
  );
};

export default PeleasActuales;