import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = ({
  isAllowed,
  redirectTo = "/",
  children,
  userType = "active",
  validRoles = ["active"]
}) => {
  const location = useLocation();

  if (!isAllowed) {
    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  }

  if (!validRoles.includes(userType)) {
    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoutes;