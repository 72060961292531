import { dbTutorials } from "../firebase";
import { collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const tutorialsCollectionRef = collection(dbTutorials, "Tutorials");
const dataName = "Tutorials";

class TutorialsDataService {
    addTutorial = (newTutorial) => {
        return addDoc(tutorialsCollectionRef, newTutorial)
    };

    updateTutorial = (id, updatedTutorial) => {
        const TutorialDoc = doc(dbTutorials, dataName, id);
        return updateDoc(TutorialDoc, updatedTutorial);
    };

    deleteTutorial = (id) => {
        const TutorialDoc = doc(dbTutorials, dataName, id);
        return deleteDoc(TutorialDoc);
    };

    getAllTutorials = () => {
        return getDocs(tutorialsCollectionRef);
    };

    getTutorial = (id) => {
        const TutorialDoc = doc(dbTutorials, dataName, id);
        return getDoc(TutorialDoc);
    };


}

export default new TutorialsDataService();