import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const LoginRedirect = ({children}) => {
    const {user} = useUserAuth();

    if(user){
        return <Navigate to="/homeuser" />;
    }

    return children;
};

export default LoginRedirect;