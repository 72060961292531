import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper } from "@material-ui/core";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { collection,  getDocs, query, where, addDoc, onSnapshot, doc, updateDoc, increment} from "firebase/firestore";
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    }
  }
});

const RetirarFondos = () => {
    //Data variables
    const [name, setName] = useState("");
    const [cardNumber, setCardNumber] = useState(0);
    const [bank, setBank] = useState("");
    const [email, setEmail] = useState("");
    const [moneyAmount, setMoneyAmount] = useState(0);
    const [withdrawalDate, setWithdrawalDate] = useState(new Date());
    const [status, setStatus] = useState("");
    const [uid, setUid] = useState("");
    const [userName, setUserName] = useState("");

    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const { user } = useUserAuth();

    const [success, setSuccess] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [moneyUser, setMoneyUser] = useState(0);

    const [myWithdrawals, setMyWithdrawals] = useState([]);

    const getMyWithdrawals = async () => {
      const q = query(withdrawalsCollectionRef, where("uid", "==", user.uid));
   
      const querySnapshot = await getDocs(q);
      const items = [];
      querySnapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
      });
      setMyWithdrawals(items);
    };

    const getUserData = async () =>{
      const unsubscribe = onSnapshot(doc(firestore, "Users", user.uid), (doc) => {
        setMoneyUser(doc.data().balance);
        setUserName(doc.data().userName);
      });
      return () => unsubscribe();
    }

    const navigate = useNavigate();
    const redirect = () =>{
      setTimeout(function() {
        navigate("/homeuser");
      }, 5000);
    }

    useEffect(() => {
      if(user?.uid){
        getUserData();
        getMyWithdrawals();        
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    

    const handleSubmit = async (e) =>{
        e.preventDefault();
        if(!user.uid){
            setAlertMessage("Usuerio no encontrado");
            return;
        }

        if(moneyAmount <= 0){
          setAlertMessage("Cantidad invalida");
          setSuccessMessage("");
          setSuccess(false);
          return;
        }

        if(name === "" || cardNumber === "" || email === "" || bank === ""){
          setAlertMessage("Campos vacios!");
          setSuccessMessage("");
          setSuccess(false);
          return;
        }
        
        if (moneyUser < moneyAmount){
          setAlertMessage("No dispones de esa cantidad");
          setSuccessMessage("");
          setSuccess(false);
          return;
        }
        try{
            setUid(user.uid);
            setWithdrawalDate(new Date());
            setStatus("waiting")
            setSuccess(true);
            setAlertMessage("");
            setSuccessMessage("Tu retiro ha sido enviado a revisión!");
        } catch (error) {
            console.error(error);
            setAlertMessage("Error inesperado");
            setSuccess(false);
        }
    };

    const handleChange = (event) => {
      if (event.target.value.match(/[^0-9]/)) {
          event.preventDefault();
        }
        setMoneyAmount(parseFloat(event.target.value))
  };
    
    const validatePaste = (event) => {
      event.preventDefault();
    }

    async function CreateData(){
      //Create db structure
      const newWithdrawal = {
          name,
          cardNumber,
          bank,
          email,
          moneyAmount,
          approvedDate: "",
          aui: "",
          authorizedUserName: "",
          status,
          uid,
          voucher: "",
          withdrawalDate,
          userName
      }
      await addDoc(withdrawalsCollectionRef, newWithdrawal)
    }

    const balanceAfterWithdrawal = async () =>{
      const userDoc = doc(firestore, "Users", user.uid);
      await updateDoc(userDoc, {
          balance: increment(-moneyAmount)
      });
    };

    // request a weekday along with a long date
    const dateOptions = {
        month: "long",
        day: "numeric",
    };   

    useEffect(() => {
        if(success){
            CreateData();
            balanceAfterWithdrawal();
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

  return (
    <>
    <div style={{ backgroundColor: "#2b2b2b" }}>
        <Container component="main" maxWidth="xs" style={{backgroundColor:"#2b2b2b"}}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
          <Grid container sx={{width:"90%"}}>
            <Grid item xs sx={{ mt: 2,}} >
              <Typography component="h1" variant="h5" color={'#ffffff'} fontSize="17px" align="center" >
                Retirar fondos
              </Typography>
              <br></br>
            </Grid>
          </Grid>
          <Typography component="h1" color={'#ffffff'} variant="body1" fontSize="15px" align="justify" sx={{width:"90%"}}>
                Ingresa los datos de tu cuenta bancaria para realizar la solicitud de retiro.
          </Typography>
  
          <Box 
          style={{backgroundColor:"#4a4949"}}
            sx={{
                width: "90%",
                boxShadow: 2,
                justifyContent: 'center',
                flexDirection: 'column',
                p: 3,
                m: 2,
                bgcolor: 'background.paper',
                borderRadius: 2,
            }}>
              <CssTextField
                margin="normal"
                required              
                fullWidth  
                id="formName"
                label="Nombre completo"
                name="name"
                type="text"
                onChange={(e) => setName(e.target.value)}
              />
              <CssTextField
                margin="normal"
                required
                fullWidth
                id="formCardNumber"
                label="No. de tarjeta o CLABE"
                name="cardNumber"
                onChange={(e) => setCardNumber(e.target.value)}
              />
              <CssTextField
                margin="normal"
                required
                fullWidth
                id="formBank"
                label="Nombre del banco"
                name="bank"
                type="text"
                onChange={(e) => setBank(e.target.value)}
              />
              <CssTextField
                margin="normal"
                required
                fullWidth
                id="formEmail"
                label="Ingresa tu email"
                name="email"
                autoComplete="email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormControlLabel control={<Checkbox />} label="Guardar datos" />
              <CssTextField
                margin="normal"
                required
                fullWidth
                id="formMoneyAmount"
                label="Cantidad a retirar"
                name="moneyAmount"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={moneyAmount}
                onChange={handleChange}
                onPaste={validatePaste}
              />
            </Box>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                {alertMessage !== "" &&
                <Grid item xs={12} sx={{ mb:1}}>
                  <Alert severity="error">{alertMessage}</Alert>
                </Grid>}
                {successMessage !== "" &&
                <Grid item xs={12} sx={{ mb:1}}>
                  <Alert severity="success">{successMessage}</Alert>
                </Grid>}

                <Button
                    style={{
                        borderRadius: 35,
                        backgroundColor: "#781113",
                        padding: "6px 43px",
                        fontSize: "14px"
                    }}
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mt: 1.5, mb: 2}}
                >
                    Enviar solicitud
                </Button>
              </Box>
              <Typography variant="caption" color= "#FFFFFF" align="center" sx={{width:"85%", fontSize:12}}>
                    Tu retiro estará listo a más tardar 1 día hábil o si existe algún inconveniente te lo notificaremos.
              </Typography>
              <Divider sx={{ pt: 3}}/>
              <TableContainer component={Paper} style={{backgroundColor:"#4a4949", color:"ffffff"}}>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                    <TableHead>
                    <TableRow >
                        <TableCell style={{color:"#ffffff"}}>Fecha</TableCell>
                        <TableCell style={{color:"#ffffff"}} align="right">Estatus</TableCell>
                        <TableCell style={{color:"#ffffff"}} align="right">Cantidad</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {myWithdrawals.map((doc, index) => (
                        <TableRow
                        key={doc.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                        {new Date(doc.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions)}
                        </TableCell>

                        {doc.status === "waiting" &&
                        <TableCell align="right" style={{fontSize:"14px", color:'#603B9A'}}>En espera</TableCell>}
                        {doc.status === "declined" &&
                        <TableCell align="right" style={{fontSize:"14px", color:'#B3261E'}}>Declinado</TableCell>}
                        {doc.status === "approved" &&
                        <TableCell align="right" style={{fontSize:"14px", color:'#50AF48'}}>Aprobado</TableCell>}

                        {doc.status === "declined" &&
                        <TableCell align="right">+ ${doc.moneyAmount}</TableCell>}
                        {doc.status !== "declined" &&
                        <TableCell align="right">- ${doc.moneyAmount}</TableCell>}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </TableContainer>
              <Divider sx={{ pt: 5, pb: 5}}/>
          </Box>
        </Container>
        </div>
    </>
  );

}

export default RetirarFondos;
