import React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Logo from "../Images/logoGALLOMX-08.png"
import './Inicio.css';

const Inicio = () => {
  const navigate = useNavigate();

  return (
    <div style={{
      width: "100%",
      minHeight: "70vh",
      backgroundColor: "#343A40"
    }}>
      <AppBar position="fixed" color="inherit" style={{ backgroundColor: "#2b2b2b" }}>
        <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="btn-login"
            onClick={(e) => navigate("/login")}
          >
            Iniciar sesión
          </button>
        </Toolbar>
      </AppBar>
      <div className="container-bg-image">
        <img src={Logo} alt={"logoGALLOMX"} style={{ height: "60px", width: "170px" }} />
        <Typography component="h1" variant="h5" color={'#FFFFFF'} align="center"
          sx={{ mt: 2, mb: 1 }} style={{ fontWeight: "bold", fontSize: "26px", maxWidth: "400px", minWidth: "200px" }}>
          La mejor casa de Apuestas de Gallos en México
        </Typography>
        <Typography component="h1" variant="h5" color={'#FFFFFF'} align="center"
          sx={{ mt: 2, mb: 3 }} style={{ fontWeight: "bold", fontSize: "18px", marginTop: "0px" }}>
          Regístrate para jugar
        </Typography>
        <button
          onClick={(e) => navigate("/signup")}
          className="btn-login"
        >
          ¡REGISTRARME!
        </button>
        {/* <button
          onClick={(e) => navigate("/home-tutorials")}
          className="btn-login"
          style={{ backgroundColor: "#b35426", marginTop: "1em" }}
        >
          TUTORIALES
        </button> */}
      </div>

      <div className="container-steps">
        <div className="container-steps-parrafo">
          <LooksOneIcon color="inherit" fontSize="inherit" style={{ fontSize: "33px", color: "#FFFFFF", paddingTop: "10px" }} />
          <Stack direction="column" justifyContent="center" alignItems="start" >
            <Typography component="h1" variant="h5" color={'#FFFFFF'} align="left" sx={{ mt: 1, mb: 1 }} style={{ fontWeight: "bolder", fontSize: "22px" }}>
              Regístrate
            </Typography>
            <Typography component="h1" variant="body1" color={"#FFFFFF"} align="left" sx={{ mb: 1 }} fontSize={"12px"}>
              Si aun no eres usuario ¿Que esperas para registrate? Abre una cuenta es gratis te tomara solo unos segundos
            </Typography>
          </Stack>
        </div>
        <div className="container-steps-parrafo">
          <LooksTwoIcon color="inherit" fontSize="inherit" style={{ fontSize: "33px", color: "#FFFFFF", paddingTop: "10px" }} />
          <Stack direction="column" justifyContent="center" alignItems="start" >
            <Typography component="h1" variant="h5" color={'#FFFFFF'} align="left" sx={{ mt: 1, mb: 1 }} style={{ fontWeight: "bolder", fontSize: "22px" }}>
              Apuesta
            </Typography>
            <Typography component="h1" variant="body1" color={'#FFFFFF'} align="left" sx={{ mb: 1 }} fontSize={"12px"}>
              Elige tu favorito, mete tu apuesta y prepárate para ganar en cada pelea
            </Typography>
          </Stack>
        </div>

        <div className="container-steps-parrafo">
          <Looks3Icon color="inherit" fontSize="inherit" style={{ fontSize: "33px", color: "#FFFFFF", paddingTop: "10px" }} />
          <Stack direction="column" justifyContent="center" alignItems="start" >
            <Typography component="h1" variant="h5" color={'#FFFFFF'} align="left" sx={{ mt: 1, mb: 1 }} style={{ fontWeight: "bolder", fontSize: "22px" }}>
              Gana
            </Typography>
            <Typography component="h1" variant="body1" color={'#FFFFFF'} align="left" sx={{ mb: 1 }} fontSize={"12px"}>
              Se todo un Gallo. Llena tu bolsillo y retira tus ganancias fácil y sencillo
            </Typography>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Inicio;