import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SportsMmaOutlinedIcon from '@mui/icons-material/SportsMmaOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { firestore } from "../firebase";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { DateRange } from "react-date-range";
import * as rdrLocales from 'react-date-range/dist/locale';
import { addDays, format } from 'date-fns'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { CSVLink } from "react-csv";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Loading from "../components/Loading";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function FightsPanel() {
    const navigate = useNavigate();
    const usersCollectionRef = collection(firestore, "Users");
    const fightsCollectionRef = collection(firestore, "Fights");
    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const depositsCollectionRef = collection(firestore, "Deposits");
    const betsCollectionRef = collection(firestore, "Bets");
    const [users, setUsers] = useState(0);
    const [fights, setFights] = useState(0);
    const [fightsLive, setFightsLive] = useState(0);
    const [withdrawals, setWithdrawals] = useState(0);
    const [deposits, setDeposits] = useState(0);
    const [depositsAmount, setDepositsAmount] = useState(0);
    const [betsDateFlag, setBetsDateFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    let betsDate1 = new Date();
    let betsDate2 = new Date();

    const [barBets, setBarBets] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                borderColor: 'rgb(54, 147, 255)',
                backgroundColor: 'rgba(54, 147, 255)',
                borderWidth: 2,
            },
        ],
    });

    const [barBetsInfo, setBarBetsInfo] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                borderColor: 'rgb(54, 147, 255)',
                backgroundColor: 'rgba(54, 147, 255)',
                borderWidth: 2,
            },
        ],
    });

    const [depositsDateFlag, setDepositsBetsDateFlag] = useState(false);
    let dateDeposits1 = new Date();
    let dateDeposits2 = new Date();

    const dateOptions = {
        month: "long",
        day: "numeric",
        year: "numeric",
    };

    // date state
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 0),
            key: 'selection'
        }
    ])

    // open close
    const [open, setOpen] = useState(false);
    const [openDeposits, setOpenDeposits] = useState(false);

    // get the target element to toggle 
    const refOne = useRef(null)

    const [allBets, setAllBets] = useState([]);
    const [allDeposits, setAllDeposits] = useState([]);
    const [allWithdrawals, setAllWithdrawals] = useState([]);

    const getAllUsers = async () => {
        const q = query(usersCollectionRef);

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setUsers(items.length);
    };

    const getAllFights = async () => {
        const q = query(fightsCollectionRef);

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setFights(items.length);
    };

    const getAllWithdrawals = async () => {
        const q = query(withdrawalsCollectionRef);

        const querySnapshot = await getDocs(q);
        const items = [];
        const itemsFixed = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
            if (doc.data().status === "approved") {
                itemsFixed.push({ ...doc.data(), id: doc.id });
            }
        });
        setAllWithdrawals(items);
    };

    const getAllFightsLive = async () => {
        const q = query(fightsCollectionRef, where("live", "==", true));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            if (!doc.data().ended) {
                items.push({ ...doc.data(), id: doc.id });
            }
        });
        setFightsLive(items.length);
    };

    const getWaitingWithdrawals = async () => {
        const q = query(withdrawalsCollectionRef, where("status", "==", "waiting"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setWithdrawals(items.length);
    };

    const getWaitingDeposits = async () => {
        const q = query(depositsCollectionRef, where("status", "==", "waiting"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setDeposits(items.length);
    };

    const getDepositsAmount = async () => {
        const q = query(depositsCollectionRef, where("status", "==", "approved"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setDepositsAmount((items.reduce((a, v) => a = a + v.amount, 0)))
    };

    const [totalLoseBets, setTotalLoseBets] = useState(0);
    const [totalWinBets, setTotalWinBets] = useState(0);
    const [totalCasadas, setTotalCasadas] = useState(0);
    const [totalRechazadas, setTotalRechazadas] = useState(0);
    const [totalGanancias, setTotalGanancias] = useState(0);
    const getTotalBets = async () => {
        const q = query(betsCollectionRef);
        const querySnapshot = await getDocs(q);

        const items = [];
        const itemsL = [];
        const itemsRechazadas = [];
        querySnapshot.forEach((doc) => {
            if (doc.data().status === "apuesta ganadora") {
                items.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "apuesta perdedora") {
                itemsL.push({ ...doc.data(), id: doc.id });
            }
            if (doc.data().status === "rechazada") {
                itemsRechazadas.push({ ...doc.data(), id: doc.id });
            }
        });

        let ganado = (items.reduce((a, v) => a = a + v.bet, 0));

        let perdido = (itemsL.reduce((a, v) => a = a + v.bet, 0));

        let casadas = ganado + perdido;

        setTotalGanancias(casadas * 0.05);
        setTotalCasadas(casadas.toFixed(2));
        setTotalWinBets((items.reduce((a, v) => a = a + v.bet, 0)).toFixed(2));
        setTotalLoseBets((itemsL.reduce((a, v) => a = a + v.bet, 0)).toFixed(2));
        setTotalRechazadas((itemsRechazadas.reduce((a, v) => a = a + v.bet, 0)).toFixed(2));

        const labels = ['Total Casadas', 'Apuestas Ganadoras', 'Apuestas Perdedoras', 'Apuestas Rechazadas', 'Ganancias Esperadas']
        setBarBetsInfo({
            labels,
            datasets: [
                {
                    label: ['Total Casadas', 'Apuestas Ganadoras', 'Apuestas Perdedoras', 'Apuestas Rechazadas', 'Ganancias Esperadas'],
                    data: [totalCasadas, totalWinBets, totalLoseBets, totalRechazadas, totalGanancias.toFixed(2)],
                    borderColor: 'rgb(54, 147, 255)',
                    backgroundColor: 'rgba(54, 147, 255)',
                    borderWidth: 2,
                },
            ],
        });
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };

    const getBets = async () => {
        const q = query(betsCollectionRef, orderBy("date"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });

        const bets = [];
        const label = items.reduce((acc, obj) => {
            const key = obj["date"];
            const date = new Date(key.seconds * 1000).toLocaleDateString("locale", dateOptions);
            bets.push(date);
            const curGroup = acc[new Date(key.seconds * 1000).toLocaleDateString("locale", dateOptions)] ?? [];
            return { ...acc, [new Date(key.seconds * 1000).toLocaleDateString("locale", dateOptions)]: [...curGroup, obj.bet] };
        }, {});

        const keys = []
        const BetsWithNoDuplicates = bets.reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue)) {
                keys.push(currentValue);
                return [...accumulator, currentValue];
            }
            return accumulator;
        }, []);

        const initialValue = 0;
        const dataLabel = label;
        const getAmounts = keys.map((data) => dataLabel[data].reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            initialValue)
        );

        setChartData({
            labels: BetsWithNoDuplicates,
            datasets: [
                {
                    data: getAmounts,
                    borderColor: 'rgb(54, 147, 255)',
                    backgroundColor: 'rgba(54, 147, 255)',
                    borderWidth: 2,
                    tension: .5,
                    pointBorderWidth: 2,
                },
            ],
        });
    };

    const getDeposits = async () => {
        const q = query(depositsCollectionRef, where("approvedDate", "!=", ""), orderBy("approvedDate"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            if (doc.data().status === "approved") {
                items.push({ ...doc.data(), id: doc.id });
            }
        });
        setAllDeposits(items);

        //.setHours(0,0,0,0);
        const fechas = [];
        const dateFormat = [];
        const label = items.reduce((acc, obj) => {
            const key = obj["approvedDate"];
            const test = new Date(key.seconds * 1000).toLocaleDateString("locale", dateOptions);
            fechas.push(test);
            const date = new Date(key.seconds * 1000);
            dateFormat.push(format(date, "MM-dd-yyyy"));
            const curGroup = acc[new Date(key.seconds * 1000).toLocaleDateString("locale", dateOptions)] ?? [];
            return { ...acc, [new Date(key.seconds * 1000).toLocaleDateString("locale", dateOptions)]: [...curGroup, obj.amount] };
        }, {});
        const filteredLabel = items.reduce((acc, obj) => {
            const key = obj["approvedDate"];
            const date = new Date(key.seconds * 1000);
            const dateFormat2 = format(date, "MM-dd-yyyy");
            const curGroup = acc[dateFormat2] ?? [];
            return { ...acc, [dateFormat2]: [...curGroup, obj.amount] };
        }, {});

        const keys = [];
        fechas.reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue)) {
                keys.push(currentValue);
                return [...accumulator, currentValue];
            }
            return accumulator;
        }, []);
        const newKeys = [];
        dateFormat.reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue)) {
                newKeys.push(currentValue);
                return [...accumulator, currentValue];
            }
            return accumulator;
        }, []);

        const filteredDates = [];
        if (depositsDateFlag) {
            newKeys.forEach((data) => {
                const date = new Date(data);
                if (date >= dateDeposits1 && date <= dateDeposits2) {
                    filteredDates.push(format(date, "MM-dd-yyyy"));
                }
            });
            const initialValueFiltered = 0;
            const getAmountsFiltered = filteredDates.map((data) => filteredLabel[data].reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                initialValueFiltered)
            );
            setDepositsAmount((getAmountsFiltered.reduce((a, v) => a = a + v, 0)))

            setChartDataIncomes({
                labels: filteredDates,
                datasets: [
                    {
                        data: getAmountsFiltered,
                        borderColor: 'rgb(253, 166, 85)',
                        backgroundColor: 'rgb(255, 130, 15)',
                        borderWidth: 2,
                        tension: .5,
                        pointBorderWidth: 2,
                    },
                ],
            });
        }
        else {
            const initialValue = 0;
            const prueba = label;
            const getAmounts = keys.map((data) => prueba[data].reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                initialValue)
            );
            setDepositsAmount((getAmounts.reduce((a, v) => a = a + v, 0)))

            setChartDataIncomes({
                labels: keys,
                datasets: [
                    {
                        data: getAmounts,
                        borderColor: 'rgb(253, 166, 85)',
                        backgroundColor: 'rgb(255, 130, 15)',
                        borderWidth: 2,
                        tension: .5,
                        pointBorderWidth: 2,
                    },
                ],
            });
        }
    };

    const startAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd 00:00:00.000');
        return new Date(parsed);
    };

    const endAt = (date) => {
        const parsed = format(date, 'yyyy-MM-dd 23:59:00.000');
        return new Date(parsed);
    };

    const getBetsEarningsByDay = async (startDateRange, endDateRange) => {
        setLoading(true)
        const startDate = startAt(startDateRange);
        const endDate = endAt(endDateRange);
        const fightsCollectionRef = collection(firestore, "Fights");
        const queryFights = query(fightsCollectionRef, where('date', '>=', startDate), where('date', '<=', endDate));
        let dayFights = [];
        const querySnapshot = await getDocs(queryFights);
        querySnapshot.forEach(async (doc) => {
            const fight = { ...doc.data(), id: doc.id };
            const date = fight.date.toDate().toLocaleDateString("es-MX");
            dayFights.push({ ...fight, fecha: date });
        });
        for (let i = 0; i < dayFights.length; i++) {
            let totalRedBets = 0;
            let totalGreenBets = 0;
            if (dayFights[i].winner === "Empate") {
                dayFights[i].greenBets = totalGreenBets;
                dayFights[i].redBets = totalRedBets;
                dayFights[i].earnings = 0;
            } else {
                const betsRef = collection(firestore, "Bets");
                const queryBets = query(betsRef, where("uidFight", "==", dayFights[i].id));
                const querySnapshotB = await getDocs(queryBets);
                querySnapshotB.forEach((doc) => {
                    const currentBet = doc.data();
                    if (currentBet.open === false && currentBet.color === "Rojo") {
                        totalRedBets = totalRedBets + currentBet.bet;
                    }
                    if (currentBet.open === false && currentBet.color === "Verde") {
                        totalGreenBets = totalGreenBets + currentBet.bet;
                    }
                })
                dayFights[i].greenBets = totalGreenBets.toFixed(2);
                dayFights[i].redBets = totalRedBets.toFixed(2);
                dayFights[i].earnings = ((totalGreenBets + totalRedBets) * 0.05).toFixed(2);
            }
        }

        const totalGreen = []
        const totalRed = []
        const ganancia = []
        let gananciaTotal = 0
        let sumaGreen = 0
        let sumaRed = 0
        for (let i = 0; i < dayFights.length; i++) {
            sumaGreen = sumaGreen + Number(dayFights[i].greenBets)
            sumaRed = sumaRed + Number(dayFights[i].redBets)
            gananciaTotal = gananciaTotal + Number(dayFights[i].earnings)
            totalGreen.push({ totalGreen: dayFights[i].greenBets, type: "Verde" })
            totalRed.push({ totalRed: dayFights[i].redBets, type: "Rojo" })

        }
        ganancia.push({ Verde: sumaGreen, Rojo: sumaRed, Total: gananciaTotal })
        setTotalCasadas(gananciaTotal)

        const labels = ['Verde', 'Rojo']
        setBarBets({
            labels,
            datasets: [
                {
                    label: ['Verde', 'Rojo'],
                    data: [ganancia[0].Verde, ganancia[0].Rojo],
                    borderColor: 'rgb(54, 147, 255)',
                    backgroundColor: 'rgba(54, 147, 255)',
                    borderWidth: 2,
                },
            ],
        });

        dayFights.sort((a, b) => a.date > b.date);
        setLoading(false)
    }

    const setGraphicBarBets = async () => {
        const q = query(betsCollectionRef, orderBy("date"));

        const querySnapshot = await getDocs(q);
        const items = [];
        querySnapshot.forEach((doc) => {
            items.push({ ...doc.data(), id: doc.id });
        });
        setAllBets(items);

        const fights = [];

        const keys = []
        fights.reduce((accumulator, currentValue) => {
            if (!accumulator.includes(currentValue)) {
                keys.push(currentValue);
                return [...accumulator, currentValue];
            }
            return accumulator;
        }, []);

        const q2 = query(fightsCollectionRef, orderBy("date"));
        const querySnapshot2 = await getDocs(q2);
        const items2 = [];
        querySnapshot2.forEach((doc) => {
            items2.push({ ...doc.data(), id: doc.id });
        });
        const names = [];
        const uids = [];
        const validatedDates = [];
        items2.forEach((doc, index) => {
            let currentId = doc.id;
            let currentName = doc.fightName;
            const currentDate = doc.date;
            keys.forEach((doc) => {
                if (doc === currentId) {
                    if (betsDateFlag && currentDate.toDate() >= betsDate1 && currentDate.toDate() <= betsDate2) {
                        uids.push(doc);
                        names.push(currentName);
                    }
                    if (!betsDateFlag) {
                        uids.push(doc);
                        names.push(currentName);
                        validatedDates.push(currentDate.toDate());
                    }
                }
            });
        });
    }



    const [, setChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                borderColor: 'rgb(54, 147, 255)',
                backgroundColor: 'rgba(54, 147, 255)',
                borderWidth: 2,
                tension: .5,
                pointBorderWidth: 5,
            },
        ],
    });

    const [chartDataIncomes, setChartDataIncomes] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                borderColor: 'rgb(255, 130, 15)',
                backgroundColor: 'rgba(255, 130, 15)',
                borderWidth: 2,
                tension: .5,
                pointBorderWidth: 5,
            },
        ],
    });

    useEffect(() => {
        getAllUsers();
        getAllFights();
        getAllFightsLive();
        getWaitingWithdrawals();
        getWaitingDeposits();
        getTotalBets();
        getDepositsAmount();
        getBets();
        getDeposits();
        setGraphicBarBets();
        getAllWithdrawals();
        const startDate = startAt(range[0].startDate);
        const endDate = endAt(range[0].endDate);
        getBetsEarningsByDay(startDate, endDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCalendarClick = () => {
        if (open) {
            setOpen(false);
            getDateSelectedBetGraphic();
        }
        else {
            setOpen(true);
            setBetsDateFlag(true);
        }
    }

    const handleCalendarClickDeposits = () => {
        if (openDeposits) {
            setOpenDeposits(false);
            getDateSelectedDepositsGraphic();
            getDeposits();
        }
        else {
            setOpenDeposits(true);
            setDepositsBetsDateFlag(true);
        }
    }

    const getDateSelectedBetGraphic = () => {
        let date1;
        let date2;
        range.forEach((data) => {
            date1 = data.startDate;
            date2 = data.endDate;
        });
        betsDate1 = new Date(date1);
        betsDate2 = new Date(date2);
        getBetsEarningsByDay(betsDate1, betsDate2)
    }

    const getDateSelectedDepositsGraphic = () => {
        let date1;
        let date2;
        range.forEach((data) => {
            date1 = data.startDate;
            date2 = data.endDate;
        });
        dateDeposits1 = new Date(date1);
        dateDeposits2 = new Date(date2);
    }

    return (
        <>
            <Container component="main" maxWidth="lg">
                <Box
                    sx={{
                        marginTop: 6,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid direction="row" justifyContent="center" container rowSpacing={4} columnSpacing={{ sx: 0, sm: 0, md: 2, lg: 2 }}>
                        <Grid item xs={12} md={3} lg={3}>
                            <Paper sx={{ height: 140 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                    <PersonOutlineIcon sx={{ fontSize: "28px", color: "#1D1D1B", pt: 1 }}
                                        style={{ backgroundColor: "#F8F6FD", borderRadius: 12, padding: "4px", }}
                                        onClick={(e) => navigate("/usersPanel")} />
                                    <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                        Usuarios
                                    </Typography>
                                </Stack>
                                <Typography sx={{ fontSize: 28, mt: 2, ml: 4, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                    {users}
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ height: 140 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Grid direction="row" justifyContent="center" container>
                                    <Grid item xs={8} md={7} lg={7}>
                                        <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                            <SportsMmaOutlinedIcon sx={{ fontSize: "28px", color: "#DC362E", pt: 1 }}
                                                style={{ backgroundColor: "#FAF2F2", borderRadius: 12, padding: "4px", }}
                                                onClick={(e) => navigate("/lista-peleas")} />
                                            <Typography sx={{ fontSize: 14, pt: 2 }} color={'#1C1B1F'} align="left" >
                                                Total de peleas
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <Box sx={{ pt: 3, maxWidth: "120px" }}>
                                            <Typography sx={{ fontSize: 12, pt: 1.5, }} color={'white'} align="center"
                                                style={{ borderRadius: 35, backgroundColor: "#B3261E", padding: "6px 30px", }}>
                                                EN VIVO
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} md={2} lg={2}>
                                        <Box sx={{ pt: 3 }} />
                                    </Grid>
                                    <Grid item xs={3} md={2} lg={2} sx={{ pt: 2 }}>
                                        <Typography sx={{ fontSize: 28, pl: 2, fontWeight: "bold" }} color={'#332D41'} align="center" >
                                            {fights}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} md={5} lg={5}>
                                        <Box sx={{ pt: 3 }} />
                                    </Grid>
                                    <Grid item xs={4} md={5} lg={5} >
                                        <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
                                            <Typography sx={{ fontSize: 28, pl: 2, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                                {fightsLive}
                                            </Typography>
                                            <Box sx={{ maxWidth: "60px" }}>
                                                <Typography sx={{ fontSize: 12, pt: 0.3 }} color={'#DF2451'} align="left" >
                                                    peleas activas
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3} lg={3}>
                            <Paper sx={{ height: 140 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                    <ListOutlinedIcon sx={{ fontSize: "28px", color: "#50AF48", pt: 1 }}
                                        style={{ backgroundColor: "#F3FDF2", borderRadius: 12, padding: "4px", }}
                                        onClick={(e) => navigate("/depositos-admin")} />
                                    <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                        Solicitudes de retiros
                                    </Typography>
                                </Stack>
                                <Grid direction="row" justifyContent="space-around" container sx={{ mt: 1 }} columnSpacing={{ sx: 2, sm: 3, md: 4, lg: 4 }}>
                                    <Grid item xs={4} md={3} lg={3} sx={{ ml: 1 }}>
                                        <Typography sx={{ fontSize: 28, mt: 0.5, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                            {withdrawals}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={3} lg={3} sx={{ mr: 4 }}>
                                        <CSVLink data={allWithdrawals} filename={"retiros.csv"}>
                                            <SimCardDownloadIcon
                                                sx={{ fontSize: "22px", color: "rgb(234, 159, 38)", pt: 1.5, pl: 3 }}
                                                style={{ backgroundColor: "rgb(234 159 38 / 30%)", borderRadius: 8, padding: "4px", marginLeft: "20px", marginTop: "4px" }}
                                            />
                                        </CSVLink>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid direction="row" justifyContent="center" container rowSpacing={4} columnSpacing={{ sx: 2, sm: 3, md: 4, lg: 4 }} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ maxHeight: 460 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                    <PaidOutlinedIcon sx={{ fontSize: "28px", color: "#4858EA", pt: 1 }}
                                        style={{ backgroundColor: "#F6F9FF", borderRadius: 12, padding: "4px", }} />
                                    <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                        Total de apuestas
                                    </Typography>
                                    <CSVLink data={allBets} filename={"apuestas.csv"}>
                                        <SimCardDownloadIcon
                                            sx={{ fontSize: "22px", color: "rgb(234, 159, 38)", pt: 1.5, pl: 3 }}
                                            style={{ backgroundColor: "rgb(234 159 38 / 30%)", borderRadius: 8, padding: "4px", marginLeft: "20px", marginTop: "4px" }}
                                        />
                                    </CSVLink>
                                    <CalendarTodayIcon sx={{ fontSize: "22px", color: "rgb(234, 159, 38)", pt: 1.5, pl: 3 }}
                                        style={{ backgroundColor: "rgb(234 159 38 / 30%)", borderRadius: 8, padding: "4px", marginLeft: "20px", marginTop: "4px" }}
                                        onClick={(e) => handleCalendarClick()} />
                                    <div className="calendarWrap">
                                        <div ref={refOne}>
                                            {open &&
                                                <DateRange
                                                    locale={rdrLocales.es}
                                                    onChange={item => setRange([item.selection])}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={range}
                                                    months={1}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                />
                                            }
                                        </div>
                                    </div>
                                </Stack>
                                {loading ? <Loading /> :
                                    <Grid direction="row" justifyContent="center" container>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Typography sx={{ fontSize: 28, mt: 2, ml: 4, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                                ${totalCasadas}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                                            <Bar options={options} data={barBets} style={{ maxHeight: 250 }} />
                                        </Grid>
                                        <Grid item xs={0} md={12} lg={12} sx={{ mt: 10, mb: 5 }}>
                                        </Grid>
                                        <Grid item xs={12} md={0} lg={0} sx={{ mt: 1, mb: 1 }}>
                                        </Grid>
                                    </Grid>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ maxHeight: 460 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 2 }}>
                                    <CurrencyExchangeOutlinedIcon sx={{ fontSize: "28px", color: "#E28026", pt: 1 }}
                                        style={{ backgroundColor: "#FFF8F1", borderRadius: 12, padding: "4px", }}
                                        onClick={(e) => navigate("/depositos-admin")} />
                                    <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                        Ingresos
                                    </Typography>
                                    <CSVLink data={allDeposits} filename={"depositos.csv"}>
                                        <SimCardDownloadIcon
                                            sx={{ fontSize: "22px", color: "rgb(234, 159, 38)", pt: 1.5, pl: 3 }}
                                            style={{ backgroundColor: "rgb(234 159 38 / 30%)", borderRadius: 8, padding: "4px", marginLeft: "20px", marginTop: "4px" }}
                                        />
                                    </CSVLink>
                                    <CalendarTodayIcon sx={{ fontSize: "22px", color: "rgb(234, 159, 38)", pt: 1.5, pl: 3 }}
                                        style={{ backgroundColor: "rgb(234 159 38 / 30%)", borderRadius: 8, padding: "4px", marginLeft: "20px", marginTop: "4px" }}
                                        onClick={(e) => handleCalendarClickDeposits()} />
                                    <div className="calendarWrap">
                                        <div ref={refOne}>
                                            {openDeposits &&
                                                <DateRange
                                                    locale={rdrLocales.es}
                                                    onChange={item => setRange([item.selection])}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={range}
                                                    months={1}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                />
                                            }
                                        </div>
                                    </div>
                                </Stack>
                                <Grid direction="row" justifyContent="center" container>
                                    <Grid item xs={12} md={12} lg={12} >
                                        <Typography sx={{ fontSize: 28, mt: 1, ml: 4, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                            ${depositsAmount.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                                        <Line options={options} data={chartDataIncomes} />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} >
                                        <Stack direction="row" justifyContent="initial" spacing={2} sx={{ ml: 3, pt: 1, pb: 2 }}>
                                            <ListOutlinedIcon sx={{ fontSize: "28px", color: "#E28026", pt: 1 }}
                                                onClick={(e) => navigate("/depositos-admin")} />
                                            <Typography sx={{ fontSize: 14, pt: 1.5 }} color={'#1C1B1F'} align="left" >
                                                Solicitudes de ingresos
                                            </Typography>
                                            <Typography sx={{ fontSize: 28, mt: 2, pl: 2, fontWeight: "bold" }} color={'#332D41'} align="left" >
                                                {deposits}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid direction="row" justifyContent="center" container rowSpacing={4} columnSpacing={{ sx: 2, sm: 3, md: 4, lg: 4 }} sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ maxHeight: 460, minHeight: 260 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Grid direction="row" justifyContent="center" container>
                                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 3, ml: 4, mb: 1 }} >
                                        <Typography sx={{ fontSize: 18 }} color={'#4858EA'} align="left" style={{ fontWeight: "bold", marginBottom: "2em" }} >
                                            Información de apuestas
                                        </Typography>
                                        <Grid direction="row" justifyContent="center" container>
                                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                                                <Bar options={options} data={barBetsInfo} style={{ maxHeight: 250 }} />
                                            </Grid>
                                            <Grid item xs={0} md={12} lg={12} sx={{ mt: 10, mb: 5 }}>
                                            </Grid>
                                            <Grid item xs={12} md={0} lg={0} sx={{ mt: 1, mb: 1 }}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Paper sx={{ maxHeight: 460, minHeight: 260 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                                <Grid direction="row" justifyContent="center" container>
                                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 3, ml: 4, mb: 1 }} >
                                        <Typography sx={{ fontSize: 18 }} color={'#E28026'} align="left" style={{ fontWeight: "bold" }} >
                                            Ingresos y retiros
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ mt: 10, mb: 5, minHeight: 50, maxHeight: 150 }} />
            </Container>
        </>
    );
};
export default FightsPanel;
