import React, { useState } from "react";
import { Routes, Route } from 'react-router-dom';
// import WhatsAppButton from "./components/WhatsAppButton";
import './App.css';
import "./styles.css";
//===COMPONENTS & PAGES
import Login from "./components/Login";
import Signup from "./components/Signup";
import HomeUser from "./components/HomeUser";
import { useUserAuth } from "./context/UserAuthContext";
// import PhoneSignUp from "./components/PhoneSignUp";
import FightsPanel from "./Pages/FightsPanel";
// import PhoneLogin from "./components/PhoneLogin";
import Inicio from "./Pages/Inicio";
import Navbar from "./NavBar/Navbar";
import Footer from "./components/Footer";
import { ThemeProvider } from "@material-ui/core/styles"
import theme from "./temaConfig"
import CreateFight from "./components/CreateFight";
import CreateBetRoulette from "./components/CreateBetRoulette";
import FightsList from "./components/FightsList";
import EditFight from "./components/EditFight";
import EditRoulette from "./components/EditRoulette";
import AddMoney from "./components/AddMoney";
import RetirarFondos from "./components/RetirarFondos";
import Perfil from "./components/Perfil";
import HandleLogout from "./components/HandleLogout";
import { FightID } from "./context/fightsContext";
import { FightIdAdmin } from "./context/fightsContext";
import { RouletteIdAdmin } from "./context/fightsContext";
import LoginRedirect from "./components/LoginRedirect";
import ManageDeposits from "./components/ManageDeposits";
import FightStatus from "./components/FightStatus";
import RouletteStatus from "./components/RouletteStatus";
import Loading from "./components/Loading";
import AllUsersPanel from "./components/AllUsersPanel";
import UserDetails from "./components/UserDetails";
import FightsV2 from "./components/FightsV2";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { validRoles } from "./utils/constants";
import ReportFight from "./components/ReportFight";
import FightLog from "./components/FightLog";
import TerminosCondiciones from "./components/TerminosCondiciones";
import RouletteList from "./components/RouletteList"
import NextFight from "./components/NextFight";
import ADS from "./components/ADS";
import Tutorials from "./components/Tutorials";
// import HomeTutorials from "./components/HomeTutorials";
import SportsList from "./components/SportsList";
import CreateSportGame from "./components/CreateSportGame";
import EditSportGame from "./components/EditSportGame";
import SportsUser from "./components/SportsUser";
import EventView from "./components/EventView";
import SportStatus from "./components/SportStatus";
import SportReport from "./components/SportReport";
import Deposits from "./components/Deposits";

function App() {
  const [fightID, setFightID] = useState("");
  const [fightIdAdmin, setFightIdAdmin] = useState("");
  const [rouletteIdAdmin, setRouletteIdAdmin] = useState("");

  const { user, loading, userInfo } = useUserAuth();
  const isUserAuthenticated = user === null ? false : true;

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <WhatsAppButton /> */}
      <FightID.Provider value={{ fightID, setFightID }}>
        <FightIdAdmin.Provider value={{ fightIdAdmin, setFightIdAdmin }}>
          <RouletteIdAdmin.Provider value={{ rouletteIdAdmin, setRouletteIdAdmin }}>
            <Navbar />
            <Routes>
              <Route path="/" element={
                <LoginRedirect>
                  <Inicio />
                </LoginRedirect>
              }
              />
              <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
              {/* Public sections */}
              <Route element={<ProtectedRoutes isAllowed={!isUserAuthenticated} />}>
                <Route path="/login" element={<Login />} />
                {/* <Route path="/phonelogin" element={<PhoneLogin />} /> */}
                {/* <Route path="/phonesignup" element={<PhoneSignUp />} /> */}
                <Route path="/signup" element={<Signup />} />
                {/* <Route path="/home-tutorials" element={<HomeTutorials />} /> */}
              </Route>

              {/* All Users */}
              <Route element={<ProtectedRoutes isAllowed={isUserAuthenticated} />}>
                <Route path="/homeuser" element={<HomeUser />} />
                <Route path="/sports-user" element={<SportsUser />} />
                <Route path="/perfil" element={<Perfil />} />
                <Route path="/logout" element={<HandleLogout />} />
                <Route path="/fightview/:idFight" element={<FightsV2 />} />
                <Route path="/eventview/:idEvent" element={<EventView />} />
                <Route path="/next-fight" element={<NextFight />} />
                <Route path="/tutorials" element={<Tutorials />} />
                <Route path="/deposits" element={<Deposits />} />
              </Route>

              {/* Client sections */}
              <Route element={<ProtectedRoutes isAllowed={isUserAuthenticated} userType={userInfo?.type} validRoles={validRoles.client} />}>
                <Route path="/add-money" element={<AddMoney />} />
                <Route path="/retiros" element={<RetirarFondos />} />
              </Route>

              {/* User Panel for Admin, Finance and Marketing */}
              <Route element={<ProtectedRoutes isAllowed={isUserAuthenticated} userType={userInfo?.type} validRoles={validRoles.usersPanel} />}>
                <Route path="/usersPanel" element={<AllUsersPanel />} />
                <Route path="/userDetail" element={<UserDetails />} />
                <Route path="/ads" element={<ADS />} />
              </Route>

              {/* Fights sections for Admin and Streaming */}
              <Route element={<ProtectedRoutes isAllowed={isUserAuthenticated} userType={userInfo?.type} validRoles={validRoles.streaming} />}>
                <Route path="/lista-sports" element={<SportsList />} />
                <Route path="/lista-peleas" element={<FightsList />} />
                <Route path="/ruleta-admin" element={<RouletteList />} />
                <Route path="/crear-pelea" element={<CreateFight />} />
                <Route path="/crear-rifa-ruleta" element={<CreateBetRoulette />} />
                <Route path="/crear-juego" element={<CreateSportGame />} />
                <Route path="/fightview-admin/:idFight" element={<FightStatus />} />
                <Route path="/sportview-admin/:idEvent" element={<SportStatus />} />
                <Route path="/rouletteview-admin/:idRoulette" element={<RouletteStatus />} />
                <Route path="/editar-pelea" element={<EditFight />} />
                <Route path="/editar-ruleta/:idRuleta/:status" element={<EditRoulette />} />
                <Route path="/editar-juego/:idJuego/" element={<EditSportGame />} />
                <Route path="/fight-log/:idFight" element={<FightLog />} />
              </Route>

              {/* Fights sections for Admin */}
              <Route element={<ProtectedRoutes isAllowed={isUserAuthenticated} userType={userInfo?.type} validRoles={validRoles.admin} />}>
                <Route path="/report-fight" element={<ReportFight />} />
                <Route path="/report-sport" element={<SportReport />} />
              </Route>

              {/* Deposits for Admin and Finance */}
              <Route element={<ProtectedRoutes isAllowed={isUserAuthenticated} userType={userInfo?.type} validRoles={validRoles.finance} />}>
                <Route path="/adminpanel" element={<FightsPanel />} />
                <Route path="/depositos-admin" element={<ManageDeposits />} />
              </Route>
            </Routes>
            <Footer />
          </RouletteIdAdmin.Provider>
        </FightIdAdmin.Provider>
      </FightID.Provider>
    </ThemeProvider>
  );
}

export default App;