import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import { doc, updateDoc, getDoc, collection,  getDocs, query, where, orderBy} from "firebase/firestore";
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
});

const CssTextField = styled(TextField)({
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    }
  }
});

const Perfil = () => {
  const { user } = useUserAuth();
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");

  const [editMode, setEditMode] = useState(false);
  const [nombreUneditable, setNombreUneditable] = useState("");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(false);

  const [nom, setNom] = useState("");
  const [tel, setTel] = useState("");
  const [ema, setEma] = useState("");
  const [dir, setDir] = useState("");

  const [tabValue, setTabValue] = useState(0);
  //0 = Historial
  //1 = Editar perfil

  const [userBets, setUserBets] = useState([]);
  const [rouletteBets, setRouletteBets] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const blockEdit = false;
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  const getData = async () => {
    const userDoc = doc(firestore, "Users", user.uid);
    const docSnap = await getDoc(userDoc);
    setNombre(docSnap.data().userName);
    setTelefono(docSnap.data().phoneNumber);
    setEmail(docSnap.data().email);
    setDireccion(docSnap.data().address);
    setNombreUneditable(docSnap.data().userName);
  }

  const getBetsRoulleteData = async () => {
    const betsCollectionRef = collection(firestore, "RouletteBets");
    const q = query(betsCollectionRef, where("uidUser", "==", user.uid));
 
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
    });
    if(items !== null){
      setRouletteBets(items);
    }
  };

  const getBetsData = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(betsCollectionRef, where("uidUser", "==", user.uid));
 
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
    });
    if(items !== null){
      setUserBets(items);
    }
  };

  const columnsBetsRoulette = [
    { field: 'amount', headerName: 'Precio', width: 80 },
    { field: 'selectedNumber', headerName: 'Número', width: 80 },
    { field: 'status', headerName: 'Estatus', width: 140 },
    { field: 'date', headerName: 'Fecha', width: 120 },
  ];

  const rowsRouletteBets = rouletteBets.map((row) =>({
    id: row.id,
    amount: row.amount,
    selectedNumber: row.selectedNumber,
    status: row.status,
    date: new Date(row.date.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const columnsBets = [
    { field: 'bet', headerName: 'Apuesta', width: 80 },
    { field: 'color', headerName: 'Color', width: 60 },
    { field: 'status', headerName: 'Estatus', width: 140 },
    { field: 'date', headerName: 'Fecha', width: 120 },
  ];
  
  const rowsBets = userBets.map((row) =>({
    id: row.id,
    bet: row.bet,
    color: row.color,
    status: row.status,
    date: new Date(row.date.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const getDepositsData = async () => {
    const depositsCollectionRef = collection(firestore, "Deposits");
    const q = query(depositsCollectionRef, where("uid", "==", user.uid), orderBy("depositDate", "desc"));
 
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
    });
    if(items !== null){
      setDeposits(items);
    }
  };

  const columnsDeposits = [
    { field: 'amount', headerName: 'Cantidad', width: 110 },
    { field: 'status', headerName: 'Estatus', width: 120 },
    { field: 'depositDate', headerName: 'Fecha', width: 120 },
  ];
  
  const rowsDeposits = deposits.map((row) =>({
    id: row.id,
    amount: row.amount,
    status: row.status,
    depositDate: new Date(row.depositDate.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const getWithdrawalsData = async () => {
    const withdrawalsCollectionRef = collection(firestore, "Withdrawals");
    const q = query(withdrawalsCollectionRef, where("uid", "==", user.uid));
 
    const querySnapshot = await getDocs(q);
    const items = [];
    querySnapshot.forEach((doc) => {
        items.push({ ...doc.data(), id: doc.id });
    });
    if(items !== null){
      setWithdrawals(items);
    }
  };

  const columnsWithdrawals = [
    { field: 'moneyAmount', headerName: 'Cantidad', width: 110 },
    { field: 'status', headerName: 'Estatus', width: 120 },
    { field: 'withdrawalDate', headerName: 'Fecha', width: 120 },
  ];
  
  const rowsWithdrawals = withdrawals.map((row) =>({
    id: row.id,
    moneyAmount: row.moneyAmount,
    status: row.status,
    withdrawalDate: new Date(row.withdrawalDate.seconds * 1000).toLocaleDateString("locale", dateOptions),
  }));

  const handleEdit = async () =>{
    setNom(nombre);
    setTel(telefono);
    setEma(email);
    setDir(direccion);
    setError(false);
    setOpenError(false);
    setSuccess(false);
    setOpen(false);

    setEditMode(true);
  }

  const saveChanges = async () => {
    setError(false);
    setOpenError(false);
    setSuccess(false);
    setOpen(false);

    if (nom === nombre && tel === telefono && ema === email && dir === direccion){
      setEditMode(false);
      return;
    }

    if(nombre === ""){
      setOpenError(true);
      setError(true);
      return;
    }
    if(!error){
      const userDoc = doc(firestore, "Users", user.uid);
      try{
        await updateDoc(userDoc, {
          userName: nombre,
          phoneNumber: telefono,
          email: email,
          address: direccion
        });
        setNombreUneditable(nombre);
        setSuccess(true);
        setOpen(true);
        setEditMode(false);
      } catch (err) {
        console.log(err);
        setOpenError(true);
        setError(true);
      }
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
      var stringArray = name.split(/(\s+)/);
      if(stringArray.length === 1){
          return {
              sx: {
              bgcolor: stringToColor(name),
              },
              children: `${name.split(' ')[0][0]}`,
          };
      }
      if(stringArray.length >= 2){
          return {
              sx: {
              bgcolor: stringToColor(name),
              },
              children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
          };
      }
  }

  useEffect(()=> {
    if (user?.uid) {
      getData();
      getBetsData();
      getBetsRoulleteData();
      getDepositsData();
      getWithdrawalsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
    <div style={{backgroundColor:"#2b2b2b"}}>
    <Container component="main" maxWidth="xs" style={{backgroundColor:"#2b2b2b"}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid container direction="row"  justifyContent="center" alignItems="center" sx={{width:"90%"}}>
          <Grid item xs={3} sx={{ mt: 2, mr: 1}} >
            <Avatar style={{ width: 54, height: 54, backgroundColor:"#781113" }} {...stringAvatar(nombreUneditable)} />
          </Grid>
          <Grid item xs={8} sx={{ mt: 2,}} >
            <Typography component="h1" variant="h5" color={'#ffffff'} style={{fontSize:"17px", fontWeight:"bold"}} >
              {nombreUneditable.split(" ")[0]}
            </Typography>
            <Typography component="h1" variant="body1" fontSize="12px" color={'#ffffff'} >
              Perfil de usuario
            </Typography>
          </Grid>
        </Grid>
        <StyledTabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor=""
          textColor="inherit"
          sx={{width: "100%", mt: 2}}
        >
          <Tab label="Editar perfil" sx={{width: "50%"}} style={{back: "green", color:"#ffffff"}} />
          <Tab label="Historial" sx={{width: "50%", color:"#ffffff"}} />
        </StyledTabs>
        {tabValue === 1 &&
        <Box sx={{
          width: "90%",
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 4}}
        >
          <Typography component="h1" variant="h5" color={'#ffffff'} style={{fontSize:"22px", fontWeight:"bold"}} sx={{ mb: 1}}>
            Apuestas
          </Typography>
          <div style={{ height: 400, width: '100%', backgroundColor:'#4a4949'}}>
            <DataGrid style={{color:"#ffffff"}}
              rows={rowsBets}
              columns={columnsBets}
              autoPageSize={true}
            />
          </div>
          <Typography component="h1" variant="h5" color={'#ffffff'} style={{fontSize:"22px", fontWeight:"bold"}} sx={{mt: 5, mb: 1}}>
            Ruletas
          </Typography>
          <div style={{ height: 400, width: '100%', backgroundColor:'#4a4949'}}>
            <DataGrid style={{color:"#ffffff"}}
              rows={rowsRouletteBets}
              columns={columnsBetsRoulette}
              autoPageSize={true}
            />
          </div>
          <Typography component="h1" variant="h5" color={'#ffffff'} style={{fontSize:"22px", fontWeight:"bold"}} sx={{mt: 5, mb: 1}} >
            Depositos
          </Typography>
          <div style={{ height: 400, width: '100%', backgroundColor:'#4a4949'}}>
            <DataGrid style={{color:"#ffffff"}}
              rows={rowsDeposits}
              columns={columnsDeposits}
              autoPageSize={true}
            />
          </div>
          <Typography component="h1" variant="h5" color={'#ffffff'} style={{fontSize:"22px", fontWeight:"bold"}} sx={{mt: 5, mb: 1}}>
            Retiros
          </Typography>
          <div style={{ height: 400, width: '100%', backgroundColor:'#4a4949' }}>
            <DataGrid style={{color:"#ffffff"}}
              rows={rowsWithdrawals}
              columns={columnsWithdrawals}
              autoPageSize={true}
            />
          </div>
          <div style={{height: 100}} />
        </Box>}
        {tabValue === 0 &&       
        <Box sx={{
            width: "90%",
            justifyContent: 'center',
            flexDirection: 'column',
            p: 3,
            m: 2,
            borderRadius: 2,
        }}>
          <Divider sx={{mb:1}} />
          <CssTextField
          style={{backgroundColor:"#838484",}}
            required
            id="Nombre"
            label = "Nombre"
            fullWidth
            value={nombre}
            InputProps={{
              readOnly: !editMode,
            }}
            onChange={(e) => setNombre(e.target.value)}
            variant={!editMode? "filled": "standard"}
          />
          <Divider sx={{mt: 2, mb: 1}} />
          <CssTextField
            style={{backgroundColor:"#838484"}}
            id="Telefono"
            label="Teléfono:"
            fullWidth
            value={telefono}
            InputProps={{
              readOnly: !editMode,
            }}
            onChange={(e) => setTelefono(e.target.value)}
            variant={!editMode? "filled": "standard"}
          />
          <Divider sx={{mt: 2, mb: 1}} />
          <CssTextField
            style={{backgroundColor:"#838484"}}
            id="Email"
            label="Email:"
            fullWidth
            value={email}
            InputProps={{
              readOnly: true,
            }}
            onChange={(e) => setEmail(e.target.value)}
            variant={!editMode? "filled": "standard"}
          />
          <Divider sx={{mt: 2, mb: 1}} />
          <CssTextField
            style={{backgroundColor:"#838484"}}
            id="Direccion"
            label="Dirección:"
            fullWidth
            value={direccion}
            InputProps={{
              readOnly: !editMode,
            }}
            onChange={(e) => setDireccion(e.target.value)}
            variant={!editMode? "filled": "standard"}
          />
          {success?
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 3}}
            >
              Tus cambios se han guardado!
            </Alert> 
          </Collapse> : <></> }
          {error?
          <Collapse in={openError}>
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 3}}
            >
              Comprueba que tus datos esten completos!
            </Alert>
          </Collapse> : <></> }
          {blockEdit !== false &&
          <div>
          {!editMode?
          <Button
            style={{
              borderRadius: 35,
              backgroundColor: "#781113",
              padding: "6px 30px",
              fontSize: "14px"
            }}
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 4, mb: 2}}
              onClick={(e) => handleEdit()}
            >
              Editar
          </Button>:
          <Button
            style={{
              borderRadius: 35,
              backgroundColor: "#603B9A",
              padding: "10px 105px",
              fontSize: "14px"
            }}
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 5, mb: 2}}
              onClick={(e) => saveChanges()}
            >
              Guardar
          </Button>          
          }
          </div>}
        </Box>}
      </Box>
    </Container>
    </div>
    </>
  );
};

export default Perfil;
