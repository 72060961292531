import { dbEvents } from "../firebase";
import { collection, getDoc, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const fightsCollectionRef = collection(dbEvents, "NextEvents");
const dataName = "NextEvents";

class EventsDataService {
    addEvents = (newEvent) => {
        return addDoc(fightsCollectionRef, newEvent)
    };

    updateEvent = (id, updatedEvent) => {
        const EventDoc = doc(dbEvents, dataName, id);
        return updateDoc(EventDoc, updatedEvent);
    };

    deleteEvent = (id) => {
        const EventDoc = doc(dbEvents, dataName, id);
        return deleteDoc(EventDoc);
    };

    getAllEvents = () => {
        return getDocs(fightsCollectionRef);
    };

    getEvent = (id) => {
        const EventDoc = doc(dbEvents, dataName, id);
        return getDoc(EventDoc);
    };


}

export default new EventsDataService();