import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DepositsDataService from "../Services/deposits.services"
import { useUserAuth } from "../context/UserAuthContext";
import { firestore, storage } from "../firebase";
import { updateDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { styled } from '@mui/material/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Upload, message, Modal, Spin } from "antd";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";

import './AddMoney.css';

const LabelStepStyled = styled(StepLabel)({
  '& .MuiStepLabel-label.MuiStepLabel-active': {
    color: '#fff',
  },
  '& .MuiStepLabel-label.MuiStepLabel-completed': {
    color: '#3f51b5',
  },
  '& .MuiStepLabel-label': {
    color: 'rgba(255, 255, 255, 0.4)',
  },
});

const CssTextField = styled(TextField)({
  maxWidth: 400,
  '& MuiFormHelperText-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& label.Mui-focused': {
    color: '#ffffff',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#ffffff',
  },

  '& .MuiInputLabel-root': {
    color: '#ffffff',
    borderColor: '#ffffff',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#ffffff',
    },
  },
  '& .MuiInputBase-input': {
    '&.MuiOutlinedInput-input': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-root:hover': {
      '&.MuiOutlinedInput-notchedOutline': {
        borderColor: '#ffffff',
      },
    }
  }
});

const AddMoney = () => {
  const [openCopy, setOpenCopy] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { user, userInfo } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [amount, setDeposito] = useState(0);
  const [file, setFile] = useState({});
  const [id, setId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const steps = ["Ingresa la cantidad", "Realiza el depósito o transferencia", "Sube el comprobante"];

  const handleClickCopySPEI = (value) => {
    setOpenCopy(true);
    navigator.clipboard.writeText(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonPressed(true);
    setLoading(true);
    const depositoParse = parseFloat(amount);
    setDeposito(depositoParse);
    if (!amount || amount <= 0) {
      setError(true);
      setLoading(false);
      setButtonPressed(false);
      return;
    }

    try {
      const newDeposit = {
        amount,
        approvedDate: "",
        aui: "",
        authorizedUserName: "",
        depositDate: new Date(),
        payMethod: "default",
        status: "waiting",
        uid: user.uid,
        userName: userInfo.userName,
        voucher: "",
      }
      const response = await DepositsDataService.addDeposit(newDeposit);
      console.log(response);
      setId(response.id);
      setSuccess(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }
    setDeposito(parseInt(event.target.value));
  };

  const validatePaste = (event) => {
    event.preventDefault();
  }

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "white",
      padding: 2,
      margin: 2,
      marginTop: "40px",
      flexGrow: 1
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const DocuPDF = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "38px" }}>Ficha digital</Text>
          </View>
          <View style={{ padding: 30, backgroundColor: "#E3E3E3", marginTop: "20px" }}>
            <Text style={{ fontSize: "18px" }}>
              Instrucciones
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "10px" }}>
              1. Realiza tu pago con cualquiera de los establecimientos permitidos.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              2. Toma una foto de tu comprobante de pago.
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              3. Ingresa a tu cuenta y dirigete a "Agregar saldo".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              4. Navega en la parte inferior, verás el estado de tu saldo a añadir. Toca o da click en "Subir comprobante".
            </Text>
            <Text style={{ fontSize: "16px", marginTop: "4px" }}>
              5. En los proximos minutos un miembro de nuestro equipo revisará tu comprobante y se añadirá el saldo a tu cuenta.
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            <Text style={{ color: "gray", fontWeight: "extrabold", fontSize: "20px" }}>MONTO A PAGAR</Text>
            <Text style={{ marginTop: "20px", fontWeight: "bold", fontSize: "32px" }}>
              $ {amount} MXN
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Transferencia electrónica {"(SPEI)"}:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              CLABE: 058320000000893020
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Banco: Banregio
            </Text>
          </View>
          <View style={{ marginTop: "40px" }}>
            <Text style={{ color: "#603B9A", fontSize: "22px" }}>
              Deposito en efectivo:
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "10px" }}>
              Número de tarjeta: 4741 7406 0220 7885
            </Text>
            <Text style={{ fontSize: "18px", marginTop: "4px" }}>
              Beneficiario: GalloWin
            </Text>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "20px" }}>
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Oxxo_Logo.svg/2560px-Oxxo_Logo.svg.png"
                alt="OXXO"
                style={{ maxWidth: "80px", maxHeight: "40px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/7-eleven_logo.svg/2110px-7-eleven_logo.svg.png"
                alt="7Eleven"
                style={{ maxWidth: "40px", maxHeight: "40px", marginLeft: "30px" }}
              />
              <Image
                src="https://pbs.twimg.com/profile_images/1082319678893023234/QM2qBfnH_400x400.jpg"
                alt="Soriana"
                style={{ maxWidth: "100px", maxHeight: "50px", marginLeft: "25px" }}
              />
              <Image
                src="https://upload.wikimedia.org/wikipedia/en/b/be/Tiendas_Extra_logo.png"
                alt="Extra"
                style={{ maxWidth: "100px", maxHeight: "100px", paddingBottom: "50px", marginLeft: "25px" }}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  }

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleBeforeUpload = (file) => {
    const isImage = file.type.indexOf("image/") === 0;
    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 3;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 3MB!", 3);
      return Upload.LIST_IGNORE;
    }
    setFile(file)
    return false;
  };

  const handleUpdate = async () => {
    setLoading(true);

    const metadata = {
      contentType: "image/jpeg",
    };
    const storageRef = ref(storage, `comprobantes/${id}`);

    try {
      await uploadBytes(storageRef, file, metadata);
      const urlFile = await getDownloadURL(storageRef);
      const depositDoc = doc(firestore, "Deposits", id);
      await updateDoc(depositDoc, {
        voucher: urlFile,
      });

      setFile({});
      setLoading(false);
      setOpenModal(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const redirectHome = () => {
    navigate("/homeuser");
  };

  return (
    <Spin
      size="large"
      spinning={loading}
    >
      <div className="container-add-money">
        <div className="container-stepper">
          <div>
            <Stepper alternativeLabel activeStep={activeStep} style={{ backgroundColor: "#2b2b2b" }}>
              {steps.map((label) => (
                <Step key={label}>
                  <LabelStepStyled>{label}</LabelStepStyled>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        <div>
          {activeStep === 0 && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 6 }}>
                <CssTextField
                  margin="normal"
                  required
                  fullWidth
                  id="formDeposito"
                  label="Cantidad a depositar"
                  type="number"
                  name="deposito"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={amount}
                  onChange={handleChange}
                  onPaste={validatePaste}
                />
                {success &&
                  <Alert sx={{ mt: 1, mb: 1 }} severity="success">
                    Tu petición ha sido enviada con exito!
                  </Alert>}
                {error &&
                  <Alert sx={{ mt: 1, mb: 1 }} severity="warning">
                    Ingresa una cantidad valida!
                  </Alert>}
                {!buttonPressed &&
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      style={{
                        borderRadius: 35,
                        backgroundColor: "#781113",
                        padding: "6px 0px",
                        fontSize: "14px"
                      }}
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{ mt: 2, mb: 4 }}
                    >
                      Enviar
                    </Button>
                  </div>
                }
              </Box>
            </div>
          )}
          {activeStep === 1 && (
            <Container component="main" maxWidth="xs" style={{ backgroundColor: "#2b2b2b" }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ mt: 2 }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: "15px" }}>
                    <Typography sx={{ fontSize: 14 }} gutterBottom style={{ paddingBottom: "5px", textAlign: "center", color: "#fff" }}>
                      Cantidad: ${amount} MXN
                    </Typography>
                  </div>
                  <Card sx={{ minWidth: 300 }} style={{ backgroundColor: '#4a4949' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                        Transferencia electrónica {"(SPEI)"}:
                      </Typography>
                      <Grid container >
                        <Grid item xs={10}>
                          <Typography variant="body2" color="#ffffff">
                            CLABE: 058320000000893020
                            <br />
                            Banco: Banregio
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("058320000000893020")}>
                            <ContentCopyIcon />
                          </IconButton>
                          <Snackbar
                            message="SPEI copiado"
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            autoHideDuration={1500}
                            onClose={() => setOpenCopy(false)}
                            open={openCopy}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card sx={{ minWidth: 300, mt: 2 }} style={{ backgroundColor: '#4a4949' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                        Deposito en efectivo:
                      </Typography>
                      <Grid container >
                        <Grid item xs={10}>
                          <Typography variant="body2" color="#ffffff">
                            Número de tarjeta: 4741 7406 0220 7885
                            <br />
                            Beneficiario: GalloWin
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton style={{ color: "#ffffff" }} onClick={() => handleClickCopySPEI("4741 7406 0220 7885")}>
                            <ContentCopyIcon />
                          </IconButton>
                          <Snackbar
                            message="SPEI copiado"
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            autoHideDuration={1500}
                            onClose={() => setOpenCopy(false)}
                            open={openCopy}
                          />
                        </Grid>
                        <Grid item sx={{ mt: 2 }} xs={3}>
                          <img src="https://w7.pngwing.com/pngs/785/454/png-transparent-oxxo-hd-logo.png"
                            alt="oxxo" style={{ marginLeft: "10px", width: "50%", height: "auto" }}
                          />
                        </Grid>
                        <Grid item sx={{ mt: 2 }} xs={3}>
                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/7-eleven_logo.svg/2110px-7-eleven_logo.svg.png"
                            alt="7eleven" style={{ marginLeft: "10px", width: "35%", height: "auto" }}
                          />
                        </Grid>
                        <Grid item sx={{ mt: 2 }} xs={3}>
                          <img src="https://w7.pngwing.com/pngs/890/945/png-transparent-soriana-hd-logo.png"
                            alt="soriana" style={{ width: "80%", height: "auto", }}
                          />
                        </Grid>
                        <Grid item sx={{ mt: 2 }} xs={3}>
                          <img src="https://seeklogo.com/images/E/extra-logo-63D58EACBA-seeklogo.com.png"
                            alt="extra" style={{ marginLeft: "10px", width: "60%", height: "auto" }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingBottom: "10px", paddingTop: "10px" }}>
                    <PDFDownloadLink document={<DocuPDF />} fileName="ficha.pdf">
                      <button className="btn-download-file">
                        Descargar ficha digital  <DownloadOutlined />
                      </button>
                    </PDFDownloadLink>
                  </div>
                  <Card sx={{ minWidth: 300}} style={{ backgroundColor: '#4a4949' }}>
                    <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="#ffffff" gutterBottom>
                        Intrucciones
                      </Typography>
                      <Grid container >
                        <Grid item xs={12}>
                          <Typography variant="body2" color="#ffffff" sx={{ fontSize: 12 }}>
                            1. Realiza tu pago con cualquiera de los establecimientos permitidos.
                            <br />
                            2. Toma una foto de tu comprobante de pago.
                            <br />
                            3. Ingresa a tu cuenta y dirigete a "Agregar saldo".
                            <br />
                            4. Navega en la parte inferior, verás el estado de tu saldo a añadir. Toca o da click en "Subir comprobante".
                            <br />
                            5. En los proximos minutos un miembro de nuestro equipo revisará tu comprobante y se añadirá el saldo a tu cuenta.
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Alert style={{ backgroundColor: '#7b7a7a', color: '#000000' }} sx={{ mt: 3 }} severity="info">Envíanos tu comprobante de pago para actualizar tu saldo.</Alert>
                </Box>
              </Box>
            </Container>
          )}
          {activeStep === 2 && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "60px", paddingBottom: "40px" }}>
              <Upload
                beforeUpload={(file) => handleBeforeUpload(file)}
                onRemove={() => setFile({})}
                maxCount={1}
                listType="picture"
              >
                <button className="btn-download-file">
                  Seleccionar comprobante <UploadOutlined />
                </button>
              </Upload>

              {file?.name && (
                <div style={{ paddingTop: "50px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdate}
                    style={{
                      borderRadius: "35px",
                      padding: "10px 36px",
                      fontSize: "14px",
                      backgroundColor: "#781113"
                    }}
                  >
                    Enviar comprobante
                  </Button>
                </div>
              )}
            </div>
          )}
          {activeStep !== 0 && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", paddingTop: "10px", paddingBottom: "20px" }}>
              <Button
                disabled={activeStep === 1}
                onClick={handleBack}
                style={{
                  color: activeStep === 1 ? "rgba(255, 255, 255, 0.4)" : "#3f51b5",
                }}
              >
                Atras
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1 ? true : false}
                style={{
                  borderRadius: "35px",
                  padding: "10px 36px",
                  fontSize: "14px",
                  backgroundColor: activeStep === steps.length - 1 ? "transparent" : "#781113"
                }}
              >
                Siguiente
              </Button>
            </div>
          )}
        </div>
      </div>

      {/* Modal Success Upload */}
      <Modal
        open={openModal}
        closable={false}
        onOk={redirectHome}
        onCancel={redirectHome}
        footer={null}
        centered
      >
        <div>
          <Typography component="h1" variant="h5" color={'#50AF48'} align="center" >
            Tu comprobante fue enviado con éxito!
          </Typography>
          <br></br>
          <Typography variant="body2" align="center">
            Revisaremos tu comprobante! Tu nuevo saldo se verá reflejado en tu cuenta pronto.
          </Typography>
          <Button
            style={{
              borderRadius: 35,
              backgroundColor: "#603B9A",
              padding: "10px 36px",
              fontSize: "14px"
            }}
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{ mt: 4, mb: 4 }}
            onClick={(e) => navigate("/homeuser")}
          >
            Volver al inicio
          </Button>
        </div>
      </Modal>
    </Spin>
  )
}

export default AddMoney;
