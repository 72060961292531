import React, { useState, useEffect, useRef } from "react";
import { firestore } from "../firebase";
import { collection, query, Timestamp, getDocs, where } from "firebase/firestore";
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Table } from 'antd';
import Typography from '@mui/material/Typography';
import { format, addDays } from 'date-fns';
import Loading from "./Loading";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as rdrLocales from 'react-date-range/dist/locale';

const SportReport = () => {
  const [events, setEvents] = useState([]);
  const [type, setType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const refOne = useRef(null)

  const columns = [
    {
      title: 'Número de pelea',
      dataIndex: 'eventName',
    },
    {
      title: 'Nombre de la pelea',
      dataIndex: 'eventName',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      title: 'Apuesta equipo 1',
      dataIndex: 'team1',
    },
    {
      title: 'Apuesta equipo 2',
      dataIndex: 'team2',
    },
    {
      title: 'Ganador',
      dataIndex: 'winner',
    },
    {
      title: 'Ganancia',
      dataIndex: 'earnings',
    },
    {
      title: 'Tipo de deporte',
      dataIndex: 'type',
      filters: type,
      onFilter: (value, record) => record.type.indexOf(value) === 0,
    },
  ];

  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'selection'
    }
  ])

  const onChange = (values) => {
    if (values) {
      const startDate = Timestamp.fromDate(values[0].startDate).toDate()
      const endDate = Timestamp.fromDate(values[0].endDate).toDate()
      getBetsEarningsByDay(startDate, endDate)
      setRange(values)
    }
  }

  const openCalendar = () => {
    if (openDateRange === false) {
      setOpenDateRange(true)
    }
  }

  const startAt = (date) => {
    const parsed = format(date, 'yyyy-MM-dd 00:00:00.000');
    return new Date(parsed);
  };

  const endAt = (date) => {
    const parsed = format(date, 'yyyy-MM-dd 23:59:00.000');
    return new Date(parsed);
  };

  const getBetsEarningsByDay = async (startDateRange, endDateRange) => {
    setLoading(true);
    setOpenDateRange(false)
    const startDate = startAt(startDateRange);
    const endDate = endAt(endDateRange);
    const EventsCollectionRef = collection(firestore, "Events");
    const queryEvents = query(EventsCollectionRef, where('date', '>=', startDate), where('date', '<=', endDate));
    let dayEvents = [];
    const querySnapshot = await getDocs(queryEvents);
    querySnapshot.forEach(async (doc) => {
      const fight = { ...doc.data(), id: doc.id };
      const date = fight.date.toDate().toLocaleDateString("es-MX");
      dayEvents.push({ ...fight, fecha: date });
    });

    for (let i = 0; i < dayEvents.length; i++) {
      let totalteam1Bets = 0;
      let totalteam2Bets = 0;
      if (dayEvents[i].winner === "Empate") {
        dayEvents[i].teams[0].id = totalteam1Bets;
        dayEvents[i].teams[1].id = totalteam2Bets;
        dayEvents[i].earnings = 0;
      } else {
        const betsRef = collection(firestore, "EventsBets");
        const queryBets = query(betsRef, where("uidEvent", "==", dayEvents[i].id));
        const querySnapshotB = await getDocs(queryBets);
        querySnapshotB.forEach((doc) => {
          const currentBet = doc.data();
          if (currentBet.open === false && currentBet.idTeam === "2") {
            totalteam2Bets = totalteam2Bets + currentBet.bet;
          }
          if (currentBet.open === false && currentBet.idTeam === "1") {
            totalteam1Bets = totalteam1Bets + currentBet.bet;
          }
        })
        dayEvents[i].team1 = totalteam1Bets.toFixed(2);
        dayEvents[i].team2 = totalteam2Bets.toFixed(2);
        dayEvents[i].earnings = ((totalteam1Bets + totalteam2Bets) * 0.05).toFixed(2);
      }
    }
    dayEvents.sort((a, b) => a.date > b.date);
    setEvents(dayEvents);
    setLoading(false);
    setOpenDateRange(false)
  }

  const getSportsType = async () => {
    const sportType = []
    const EventsCollectionRef = collection(firestore, "Events");
    const queryEvents = query(EventsCollectionRef);
    const querySnapshot = await getDocs(queryEvents);
    querySnapshot.forEach(async (doc) => {
      sportType.push({ value: doc.data().type, text: doc.data().type })
    });
    var hash = {};
    setType(sportType.filter(function (current) {
      var exists = !hash[current.value];
      hash[current.value] = true;
      return exists;
    }))
  }

  useEffect(() => {
    const currentDate = new Date();
    getBetsEarningsByDay(currentDate, currentDate)
    getSportsType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container component="main" style={{ marginBottom: "25em", marginTop: "2em" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" color="white"
            style={{
              borderRadius: 8,
              backgroundColor: "#781113",
              padding: "10px 18px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center"
            }}
            onClick={(e) => openCalendar()}>
            <CalendarMonthIcon /><p style={{ marginBottom: 0 }}>Seleccionar Fecha</p>
            <div className="calendarWrap">
              <div ref={refOne}>
                {openDateRange &&
                  <DateRange
                    locale={rdrLocales.es}
                    onChange={item => onChange([item.selection])}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    months={1}
                    direction="horizontal"
                    className="calendarElement"
                  />
                }
              </div>
            </div>
          </Typography>
        </div>
        {loading ?
          <Loading /> :
          <TableContainer style={{ maxWidth: "100%" }} component={Paper} sx={{ mt: 6 }}>
            <Table columns={columns} dataSource={events} />
          </TableContainer>
        }
      </Container>
    </>
  )
}

export default SportReport;
