import React from 'react'
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from 'react-router-dom';

const HandleLogout = () => {
    const { logOut } = useUserAuth();
    const navigate = useNavigate();

    const logout = async () => {
        await logOut();
        navigate("/");
    };

    logout();

  return (
    <div style={{marginTop:"50px"}}> Cerrando sesion...</div>
  )
}

export default HandleLogout;
