import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

let firebaseConfig;
if (process.env.REACT_APP_ENV === "dev") {
  firebaseConfig = {
    apiKey: "AIzaSyBZwiY9FwNtBmKb0pgjqKwXNDu0ltr7L0A",
    authDomain: "gallo-win-dev.firebaseapp.com",
    projectId: "gallo-win-dev",
    storageBucket: "gallo-win-dev.appspot.com",
    messagingSenderId: "647206319982",
    appId: "1:647206319982:web:b875d02f0908c8a7e7737f"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyAgWtzDdsogqw2GprWowfM2Qi5AzWU3G3Y",
    authDomain: "gallo-win.firebaseapp.com",
    databaseURL: "https://gallo-win-default-rtdb.firebaseio.com",
    projectId: "gallo-win",
    storageBucket: "gallo-win.appspot.com",
    messagingSenderId: "486672386000",
    appId: "1:486672386000:web:0c7ada6cca10446cf89bd5"
  };
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;

//Firestore
export const db = getFirestore(app);
export const dbTutorials = getFirestore(app);
export const dbEvents = getFirestore(app);
export const dbFights = getFirestore(app);
export const dbRoulette = getFirestore(app);
export const firestore = getFirestore(app);

//Storage
export const storage = getStorage(app);

export async function uploadFile(file) {
  const storageRef = ref(storage, "comprobantes/" + v4());
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}