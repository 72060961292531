import React, { useState, useEffect } from "react";
import FightsDataService from "../Services/fights.services";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Switch from '@mui/material/Switch';
import {
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { channels } from "../utils/constants";
import { Upload } from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from "uuid";
import { storage } from "../firebase";
import './CreateFight.css'

const CssTextFiel = styled(TextField)({
  '& label.Mui-focused': {
    color: '#EA9F26',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#EA9F26',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#EA9F26',
    },
  },
  '& .ant-upload-list-picture': {
    '&.ant-upload-list-item-name': {
      color: '#000',
    }
  },
});

const CssUpload = styled(Upload)({
  '& .ant-upload-list-picture .ant-upload-list-item-name': {
    color: '#000',
  },
  '.ant-upload-list-item-card-actions .anticon': {
    color: '#000',
  }
});

const CssButton = styled(Button)({
  '& .button-add-image': {
    backgroundColor: "rgb(120, 17, 19)",
    color: " #fff",
    borderRadius: " 16px",
    padding: "7px 16px 7px 16px",
    marginTop: "1em",
    display: "flex",
    alignItems: "center",
  },
});

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#6750A4",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#6750A4"["400"],
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

const CreateFight = () => {
  //Database "Fights" data
  const [fightName, setFightName] = useState("");
  const [description, setDescription] = useState("");
  const [greenRooster, setGreenRooster] = useState("");
  const [redRooster, setRedRooster] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [mandatoryFights, setMandatoryFights] = useState("");
  const [confirmedGames, setConfirmedGames] = useState("");
  const [amountRoosters, setAmountRoosters] = useState("");
  const [amountCover, setAmountCover] = useState("");
  const [status, setStatus] = useState(true);
  const [password, setPassword] = useState("");
  const [privateEvent, setPrivateEvent] = useState(false);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());
  const [createDate, setCreateDate] = useState(new Date());
  //Nuevos datos
  const [betStatus, setBetStatus] = useState(false);
  const [live, setLive] = useState(false);
  const [winner, setWinner] = useState("");

  const [message, setMessage] = useState({ error: false, msg: "" });

  const [checked, setChecked] = useState(false);
  const [betCheck, setBetChecked] = useState(false);
  const [statusCheck, setStatusCheck] = useState(true);
  const [channel, setChannel] = useState(null);

  const [eventImage, setEventImage] = useState({});

  const handleChangeChannel = (event) => {
    setChannel(event.target.value);
  };

  const navigate = useNavigate();

  const classes = useStyles();

  const handleBeforeUpload = (file, type) => {
    const isImage = file.type.indexOf("image/") === 0;
    const isJpg = file.type.includes("jpg");
    const isJpeg = file.type.includes("jpeg");
    const isPng = file.type.includes("png");
    const isWebp = file.type.includes("webp");

    if (!isJpg && !isJpeg && !isPng && !isWebp) {
      message.error("Sólo se permite formato jpg, jpeg, png y webp!", 3);
      return Upload.LIST_IGNORE;
    }

    if (!isImage) {
      message.error("Solo se pueden subir imagenes!", 3);
      return Upload.LIST_IGNORE;
    }
    const isLt5M = file.size / 1024 / 1024 < 2;
    if (!isLt5M) {
      message.error("La imagen debe ser menor a 2MB!", 3);
      return Upload.LIST_IGNORE;
    }
    if (type === "event") setEventImage(file);
    return false;
  };

  const handleRemove = (file, type) => {
    if (type === "event") setEventImage({});
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (
      fightName === "" ||
      description === "" ||
      greenRooster === "" ||
      redRooster === "" ||
      location === "" ||
      type === "" ||
      channel === null
    ) {
      setMessage({ error: true, msg: "Campos vacios!!" });
      return;
    }
    if (checked && password === "") {
      setMessage({ error: true, msg: "Ingresa una contraseña para una pelea privada" });
      return;
    }

    try {
      const id = uuidv4();
      const storageRefEvent = ref(storage, `event/${id}/card-event.png`);
      const metadata = {
        contentType: "image/jpeg",
        customMetadata: {
          eventId: id
        }
      };
      await uploadBytes(storageRefEvent, eventImage, metadata);
      let urlIconCard;
      if (!eventImage.uid) {
        urlIconCard = ""
      } else {
        urlIconCard = await getDownloadURL(storageRefEvent);
      }

      setCreateDate(new Date());
      const newFight = {
        fightName,
        description,
        greenRooster,
        redRooster,
        location,
        type,
        mandatoryFights,
        confirmedGames,
        amountRoosters,
        amountCover,
        status,
        urlVideo: channels[channel].url,
        idVideo: channels[channel].id,
        password,
        privateEvent,
        date,
        time,
        createDate,
        betStatus,
        live,
        winner,
        ended: false,
        chat: true,
        urlIconCard
      };
      await FightsDataService.addFights(newFight);
      setMessage({ error: false, msg: "¡Nueva pelea añadida!" });
      clearFields();
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  const clearFields = () => {
    setFightName("");
    setDescription("");
    setGreenRooster("");
    setRedRooster("");
    setLocation("");
    setType("");
    setMandatoryFights("")
    setConfirmedGames("")
    setAmountCover("")
    setAmountRoosters("")
    setStatus(true);
    setChannel(null);
    setPassword("");
    setPrivateEvent(false);
    setDate(new Date());
    setTime(new Date());
    setCreateDate(new Date());
    setBetStatus(false);
    setWinner("");
    setLive(false);
    //Switches
    setChecked(false);
    setStatusCheck(true);
    setBetChecked(false);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setPrivateEvent(event.target.checked);
  };

  const handleChangeBetStatus = (event) => {
    setBetChecked(event.target.checked);
    setBetStatus(event.target.checked);
  };

  const handleChangeStatusFight = (event) => {
    setStatusCheck(event.target.checked);
    setStatus(event.target.checked);
  };

  useEffect(() => {
    setPrivateEvent(false);
  }, []);

  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2} direction="row" alignItems="inherit">
            <Grid item xs={0.5} sx={{ mt: 2.5, mb: 0.5, mr: 2 }}>
              <ArrowBackIosNewIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#1D1D1B' }}
                onClick={(e) => navigate("/lista-peleas")} />
            </Grid>
            <Grid item xs={6} sx={{ mt: 2, mb: 0.5 }}>
              <Typography component="h1" variant="h5" align="left" style={{ fontSize: "26px", color: '#1D1D1B' }}>
                Crear pelea
              </Typography>
            </Grid>
          </Grid>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formFightName"
              label="Nombre de la pelea"
              name="fightName"
              value={fightName}
              onChange={(e) => setFightName(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formDescription"
              label="Descripción de la pelea"
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formRedRooster"
              label="Nombre del gallo rojo"
              name="redRooster"
              value={redRooster}
              onChange={(e) => setRedRooster(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formGreenRooster"
              label="Nombre del gallo verde"
              name="GreenRooster"
              value={greenRooster}
              onChange={(e) => setGreenRooster(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formLocation"
              label="Lugar de la pelea"
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <CssTextFiel
              margin="normal"
              required
              fullWidth
              id="formType"
              label="Tipo de pelea"
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              inputProps={{
                style: {
                  height: "40px",
                  paddingLeft: "15px"
                },
              }}
            />
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <CssTextFiel
                style={{ width: "25%", marginRight:"1em" }}
                margin="normal"
                required
                id="mandatoryFights"
                label="Peleas obligatorias"
                name="mandatoryFights"
                type="number"
                value={mandatoryFights}
                onChange={(e) => setMandatoryFights(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                style={{ width: "25%", marginRight:"1em" }}
                margin="normal"
                required
                id="confirmedGames"
                label="Partidos confirmados"
                name="confirmedGames"
                type="number"
                value={confirmedGames}
                onChange={(e) => setConfirmedGames(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                style={{ width: "25%", marginRight:"1em" }}
                margin="normal"
                required
                id="amountRoosters"
                label="Número de gallos"
                name="amountRoosters"
                type="number"
                value={amountRoosters}
                onChange={(e) => setAmountRoosters(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
              <CssTextFiel
                style={{ width: "25%" }}
                margin="normal"
                required
                id="amountCover"
                label="Costo de la entrada"
                name="amountCover"
                type="number"
                value={amountCover}
                onChange={(e) => setAmountCover(e.target.value)}
                inputProps={{
                  style: {
                    height: "40px",
                    paddingLeft: "15px"
                  },
                }}
              />
            </div>
            <div>
              <CssUpload
                beforeUpload={(file) => handleBeforeUpload(file, "event")}
                onRemove={(file) => handleRemove(file, "event")}
                maxCount={1}
                listType="picture"
              >
                <CssButton
                  style={{ backgroundColor: "rgb(120, 17, 19)", color: "#fff", borderRadius: "16px", padding: "7px 16px 7px 16px", marginTop: "1em", marginBottom: "1em", display: "flex", alignItems: "center" }}
                  icon={<UploadOutlined />}>
                  Cargar imágen
                  <div style={{ marginLeft: "5px" }}><UploadOutlined /></div>
                </CssButton>
              </CssUpload>
            </div>
            <Grid container direction="column" alignItems="flex-start">
              <FormControl className={classes.formControl}>
                <InputLabel>Canal</InputLabel>
                <Select
                  value={channel}
                  onChange={handleChangeChannel}
                >
                  <MenuItem value={1}>Canal 1</MenuItem>
                  <MenuItem value={2}>Canal 2</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item xs={12} sx={{ mb: 4 }}>
                <FormControlLabel
                  sx={{ mt: 2, }}
                  control={<Switch sx={{ m: 1 }} />}
                  checked={statusCheck}
                  onChange={handleChangeStatusFight}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Estatus de la pelea" />
                <FormControlLabel
                  sx={{ mt: 2, }}
                  control={<Switch />}
                  checked={betCheck}
                  onChange={handleChangeBetStatus}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Apuestas abiertas" />
                <FormControlLabel
                  sx={{ mt: 2, }}
                  control={<Switch />}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Pelea privada" />
                {privateEvent ?
                  <CssTextFiel
                    margin="normal"
                    required
                    fullWidth
                    id="formPassword"
                    label="Contraseña de la pelea"
                    name="passwordFight"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  /> : <></>
                }
              </Grid>
            </Grid>
            <Grid direction="row" justifyContent="flex-start" container rowSpacing={3} columnSpacing={{ sx: 0, sm: 0, md: 2, lg: 2 }}>
              <Grid item xs={12} sx={{ mb: 4 }}></Grid>
              <ThemeProvider theme={materialTheme}>
                <Grid item md={3} xs={6}  >
                  <DatePicker label="Fecha" style={{ marginLeft: "10px" }} value={date} onChange={setDate} />
                </Grid>
                <Grid item md={3} xs={6} sx={{ mb: 2 }}>
                  <TimePicker label="Hora" style={{ marginLeft: "10px" }} value={time} onChange={setTime} />
                </Grid>
              </ThemeProvider>
            </Grid>
            {message?.msg && (
              <Alert
                severity={message?.error ? "error" : "success"}
                dismissible
                onClose={() => setMessage("")}
              >
                {message?.msg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              sx={{ mt: 5, mb: 4 }}
              style={{
                color: "#FFFFFF",
                borderRadius: 28,
                backgroundColor: "rgb(120, 17, 19)",
                padding: "10px 36px",
                fontSize: "14px"
              }}
            >
              Crear Pelea
            </Button>
          </Box>
        </Box>
      </Container>
      <div style={{ height: "50px" }} ></div>
    </>
  );
};

export default CreateFight;