import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { firestore } from "../firebase";
import { getAuth, getIdToken } from "firebase/auth";
import { collection, getDoc, updateDoc, doc, query, where, onSnapshot } from "firebase/firestore";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Spin, Table as AntTable } from "antd";
import Modal from '@mui/material/Modal';
import ErrorIcon from '@mui/icons-material/Error';
import './FightStatus.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { rouletteStatus } from "../utils/constants";

const styleSuccess = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  minHeight: 250,
  maxHeight: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 8
};

const RouletteStatus = () => {
  const navigate = useNavigate();
  const paramsIdRoulette = useParams();

  //Others
  const urlVideoLocal = "https://iframe.dacast.com/live/e7e061db-7350-6fd1-4fc0-b0f4b7e4b7fb/fe7e9cb2-6a28-f0c0-d3bd-80ba025d3161";

  const [loading, setLoading] = useState(false);

  const [openModalError, setOpenModalError] = useState(false);
  const [numberWinner, setNumberWinner] = useState(null);
  const [roulette, setRoulette] = useState([]);
  const [huntError, setHuntError] = useState(null);
  const [openSuccessRoulette, setOpenSuccessRoulette] = useState(false);
  //===============================================================
  const [numbers, setNumbers] = useState([]);
  const [purchasedNumbers, setPurchasedNumbers] = useState([]);
  const [allTickesSold, setAllTickesSold] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleCloseSuccess = () => {
    setOpenSuccessRoulette(false)
  }

  const getBetDataRoulette = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Roulette", paramsIdRoulette.idRoulette), (doc) => {
      const roulette = { ...doc.data(), id: doc.id };
      let tickesSold = true
      for (let i = 0; i < roulette.listOfNumbers.length; i++) {
        if (roulette.listOfNumbers[i].available) {
          tickesSold = false;
        }
      }
      setAllTickesSold(tickesSold);
      setRoulette(roulette);
      setNumbers(roulette.listOfNumbers)
    });

    return () => unsubscribe();
  }

  const getListOfPurchasedNumbers = async () => {
    const betsRouletteCollectionRef = collection(firestore, "RouletteBets");
    const q = query(betsRouletteCollectionRef, where("idRoulette", "==", paramsIdRoulette.idRoulette));

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      let bets = [];
      querySnapshot.forEach(async (document) => {
        if (document.data().type === "number") {
          bets.push({ ...document.data(), id: document.id });
        }
      });

      bets.sort(function (a, b) {
        const ticketA = a.selectedNumber;
        const ticketB = b.selectedNumber;
        if (ticketA < ticketB) {
          return -1;
        }
        if (ticketA > ticketB) {
          return 1;
        }
        return 0;
      });

      for (let i = 0; i < bets.length; i++) {
        const docRef = doc(firestore, "Users", bets[i].uidUser);
        const response = await getDoc(docRef);
        bets[i].userName = response.data().userName;
      }

      setPurchasedNumbers(bets);
    });

    return () => unsubscribe();
  }

  const generateWinnerRoulette = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/pay-bets-roulette`, {
        method: "POST",
        body: JSON.stringify({ id: paramsIdRoulette.idRoulette, numberWinner: numberWinner }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Res: ", response)
        setHuntError(data.message);
        setOpenModalError(true);
        setLoading(false);
        return;
      }
      setOpenSuccessRoulette(true);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error)
      setHuntError(error?.message);
      setOpenModalError(true);
      setLoading(false);
    }
  }

  const closeRoulette = async () => {
    const rouletteDoc = doc(firestore, "Roulette", paramsIdRoulette.idRoulette);
    try {
      await updateDoc(rouletteDoc, {
        status: rouletteStatus.ended,
      });
    } catch (err) {
      console.log(err);
    }
  }

  const cancelRoulette = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/cancel-roulette`, {
        method: "POST",
        body: JSON.stringify({ id: paramsIdRoulette.idRoulette }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Res: ", response)
        setHuntError(data.message);
        setOpenModalError(true);
        setLoading(false);
        return;
      }
      setOpenSuccessModal(true);
      setNumberWinner(null);
      setSuccessMessage('¡Rifa cancelada correctamente!');
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error)
      setHuntError(error?.message);
      setOpenModalError(true);
      setLoading(false);
    }
  }

  const returEarnings = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/return-roulette-earnings`, {
        method: "POST",
        body: JSON.stringify({ id: paramsIdRoulette.idRoulette }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Res: ", response)
        setHuntError(data.message);
        setOpenModalError(true);
        setLoading(false);
        return;
      }
      setOpenSuccessModal(true);
      setNumberWinner(null);
      setSuccessMessage('¡Ganancias regresadas correctamente!');
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error)
      setHuntError(error?.message);
      setOpenModalError(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (paramsIdRoulette.idRoulette !== undefined && paramsIdRoulette.idRoulette !== "") {
      getListOfPurchasedNumbers()
      getBetDataRoulette()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Número',
      dataIndex: 'selectedNumber',
      key: 'selectedNumber',
      render: (value, record) => {
        return (
          <>
            <p
              style={{
                margin: 0,
                color: record.selectedNumber % 2 === 0 ? 'red' : 'green'
              }}
            >{record.selectedNumber}</p>
          </>
        );
      }
    },
    {
      title: 'Nombre',
      dataIndex: 'userName',
      key: 'userName',
      render: (value, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <p style={{ paddingLeft: '5px', margin: 0 }}>{record.userName}</p>
          </div>
        );
      },
    },
  ];

  return (
    <Spin
      style={{
        position: 'fixed', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      size="large"
      spinning={loading}
    >
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2} direction="row" alignItems="inherit">
            <Grid item xs={0.5} sx={{ mt: 4, mb: 4, ml: 4 }}>
              <ArrowBackIosNewIcon fontSize="inherit" color="success" style={{ fontSize: "22px", color: '#1D1D1B' }}
                onClick={(e) => navigate("/ruleta-admin")} />
            </Grid>
            <Grid item xs={4.5} sx={{ mt: 4, mb: 4 }}>
              <Typography component="h1" variant="h5" align="left" style={{ fontSize: "26px", color: '#1D1D1B' }}>
                {roulette.rouletteName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Container maxWidth="md" >
          <div style={{ position: "relative", borderRadius: 22, paddingBottom: "56.25%", overflow: "hidden", height: "0", maxWidth: "100%" }}>
            {roulette.live ?
              <iframe id="fe7e9cb2-6a28-f0c0-d3bd-80ba025d3161"
                src={urlVideoLocal}
                width="100%"
                height="100%"
                frameborder="0"
                scrolling="no"
                allow="autoplay;encrypted-media"
                allowfullscreen
                webkitallowfullscreen
                mozallowfullscreen
                oallowfullscreen
                msallowfullscreen
                style={{ position: "absolute", top: "0", left: "0" }}
                title='Live'>
              </iframe> :
              <Box sx={{
                display: 'flex',
                backgroundColor: "black",
                height: "500px"
              }}></Box>
            }
          </div>
          <TableContainer component={Paper} sx={{ mt: 4, boxShadow: 4, width: "100%", }} style={{ borderRadius: 20 }}>
            <div style={{ padding: "0em 1em 3px 1em" }}>
              <Typography style={{
                textAlign: "center",
              }}><Box
                  component="img"
                  alt="Imagen previa"
                  src="https://www.casino.es/imagenes/juegos/ruleta/hero-ruleta.svg"
                  style={{ width: "70px", height: "70px", marginLeft: "8px", marginBottom: "1em", borderRadius: 14, marginTop: "10px" }}
                />
              </Typography>
            </div>

            <div style={{ backgroundColor: "#ffffff", borderRadius: "10px", height: "auto", padding: "1em" }}>
              {roulette.status === rouletteStatus.ended && (
                <>
                  <Typography style={{ textAlign: "center" }}>Ganador de la rifa.</Typography>
                  <div>
                    <Typography
                      style={{
                        textAlign: "center",
                        color: "#D50004",
                        fontWeight: "bold",
                        fontSize: "25px"
                      }}>{roulette.winner}
                    </Typography>
                  </div>
                </>
              )}

              <div className='containerFooter'>
                <div style={{ height: "auto", overflow: "hidden" }} className='container-numbers-event-details-admin'>
                  {numbers.map((item, index) => {
                    if (roulette.status === rouletteStatus.active && !roulette.winner) {
                      return (
                        <button
                          className={item.number % 2 === 0 ? 'btn-ticket-red' : 'btn-ticket-green'}
                          key={`btn-${index}`}
                          onClick={() => setNumberWinner(item.number)}
                          style={numberWinner === item.number ? { backgroundColor: 'black', color: item.color } : null}
                        >
                          {item.number}
                        </button>
                      );
                    } else {
                      return (
                        <button
                          className={'btn-ticket-disable-ed'}
                          key={`btn-${index}`}
                          disabled={true}
                        >
                          {item.number}
                        </button>
                      );
                    }
                  })}
                </div>
              </div>
              {(numberWinner && roulette.status === rouletteStatus.active && !roulette.winner && allTickesSold) && (
                <div style={{ display: "grid", justifyContent: "center", marginTop: "20px" }}>
                <Typography
                  style={{
                    textAlign: "center",
                  }}
                >
                  Elegiste el número {numberWinner}, presiona "CONFIRMAR" para repartir el premio.
                </Typography>
                <div className="container-buttons-bet">
                  <Button variant="outlined" size="large"
                    onClick={generateWinnerRoulette}
                    style={{
                      width: "288px",
                      backgroundColor: "#50AF48",
                      color: "#ffffff",
                      border: "1px solid rgb(0 0 0 / 25%)",
                      borderRadius: 35,
                      padding: "10px 30px",
                      fontSize: "12px",
                    }}>
                    Confirmar
                  </Button>
                </div>
              </div>
              )}

              {(roulette.winner && roulette.status === rouletteStatus.active) && (
                <div style={{ display: "grid", justifyContent: "center", marginTop: "20px" }}>
                  <Typography
                    style={{
                      textAlign: "center",
                    }}
                  >
                    El número {roulette.winner}, ganó.
                  </Typography>
                </div>
              )}

              {(!allTickesSold && numberWinner) && (
                <div style={{ display: "grid", justifyContent: "center", marginTop: "20px" }}>
                  <Typography
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Se deben vender todos los números para entregar el premio.
                  </Typography>
                </div>
              )}

              {(roulette.winner && roulette.status === rouletteStatus.active ) && (
                <div className="container-buttons-bet">
                  <Button variant="outlined" size="large"
                    onClick={closeRoulette}
                    style={{
                      width: "288px",
                      backgroundColor: "#50AF48",
                      color: "#ffffff",
                      border: "1px solid rgb(0 0 0 / 25%)",
                      borderRadius: 35,
                      padding: "10px 30px",
                      fontSize: "12px",
                    }}>
                    Cerrar Rifa
                  </Button>
                </div>
              )}

              {(roulette.winner) && (
                <div className="container-buttons-bet">
                  <Button variant="outlined" size="large"
                    onClick={returEarnings}
                    style={{
                      width: "288px",
                      backgroundColor: "rgb(232, 26, 26)",
                      color: "#ffffff",
                      border: "1px solid rgb(0 0 0 / 25%)",
                      borderRadius: 35,
                      padding: "10px 30px",
                      fontSize: "12px",
                    }}>
                    Regresar ganancias repartidas
                  </Button>
                </div>
              )}
            </div>
          </TableContainer>
        </Container>
        <div style={{ display: "flex", flexDirection: "row", padding: "20px", paddingBottom: 0 }}>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <Typography>Precio por cada número: ${roulette.price}</Typography>
            <Typography>Ganancia esperada: ${roulette.price * 4}</Typography>
          </div>
          {roulette.status === rouletteStatus.active && (
            <div style={{ display: "flex", flexDirection: "column", flex: 1, alignItems: "flex-end" }}>
              <Button variant="outlined" size="large"
                onClick={cancelRoulette}
                style={{
                  width: "180px",
                  backgroundColor: "rgb(232, 26, 26)",
                  color: "#ffffff",
                  border: "1px solid rgb(0 0 0 / 25%)",
                  borderRadius: 35,
                  padding: "10px 30px",
                  fontSize: "11px",
                }}>
                Cancelar Rifa
              </Button>
            </div>
          )}
          
        </div>
        <Box sx={{ mt: 5, mb: 5, }}>
          <hr></hr>
        </Box>
        <div>
          <AntTable
            dataSource={purchasedNumbers}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: 30, showSizeChanger: false }}
          />
        </div>
        <Box sx={{ mt: 10, mb: 5 }}>
          <hr></hr>
        </Box>
      </Container>

      {/* Modal generate winner success*/}
      <Modal
        open={openSuccessRoulette}
        onClose={handleCloseSuccess}
        aria-labelledby="modal-modaFl-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSuccess}>
          <Grid direction="column" container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
              <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                ¡Ganador generado correctamente!
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
              <CheckCircleOutlineIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "green" }} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} >
              <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                Número: {roulette.winner}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
              <Button variant="contained" color="inherit" size="large"
                onClick={(e) => handleCloseSuccess()}
                style={{
                  borderRadius: 35,
                  padding: "12px 50px",
                  fontSize: "14px",
                  backgroundColor: "#EA9F26",
                  color: "white"
                }}>
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal success*/}
      <Modal
        open={openSuccessModal}
        onClose={() => setOpenSuccessModal(false)}
        aria-labelledby="modal-modaFl-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSuccess}>
          <Grid direction="column" container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
              <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                {successMessage}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
              <Button variant="contained" color="inherit" size="large"
                onClick={() => setOpenSuccessModal(false)}
                style={{
                  borderRadius: 35,
                  padding: "12px 50px",
                  fontSize: "14px",
                  backgroundColor: "#EA9F26",
                  color: "white"
                }}>
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* Modal generate winner error*/}
      <Modal
        open={openModalError}
        onClose={() => setOpenModalError(false)}
        aria-labelledby="modal-modaFl-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSuccess}>
          <Grid direction="column" container alignItems="center" justifyContent="center">
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
              <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                {huntError}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
              <ErrorIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "red" }} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
              <Button variant="contained" size="small" sx={{ ml: 1 }}
                onClick={() => setOpenModalError(false)}
                style={{
                  borderRadius: 35,
                  padding: "12px 50px",
                  fontSize: "12px",
                  width: "100px",
                  backgroundColor: "#EA9F26",
                  color: "white"
                }}>
                Aceptar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

    </Spin>
  )
}

export default RouletteStatus
