import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App'
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"
import esLocale from "date-fns/locale/es"
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { UserAuthContextProvider } from './context/UserAuthContext';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);;
root.render(
  <UserAuthContextProvider>
    <StrictMode>
      <Router>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <App />
        </MuiPickersUtilsProvider>
      </Router>
    </StrictMode>
  </UserAuthContextProvider>
);


