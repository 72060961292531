import React, { useState, useEffect, useRef } from "react";
import LogoCompleto from "../Images/logoGALLOMX-07.png"
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth, getIdToken } from "firebase/auth";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { Stack } from "@mui/material";
import { dbFights } from "../firebase";
import { doc, onSnapshot, collection, query, where, limit, getDocs, getDoc, orderBy } from "firebase/firestore";
import Divider from '@mui/material/Divider';
import { useLocalStorage } from "../useLocalStorage";
import { useUserAuth } from "../context/UserAuthContext";
import { firestore } from "../firebase";
import Chat from "./Chat";
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import { Spin } from "antd";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import RecommendIcon from '@mui/icons-material/Recommend';
import NumbersIcon from '@mui/icons-material/Numbers';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleSuccess = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  minHeight: 250,
  maxHeight: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 8
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#EA9F26',
  },
});

const FightsV2 = () => {
  const [uidUser] = useLocalStorage("uidUser", "");
  const { user } = useUserAuth();
  const paramsIdFight = useParams(); //=========>
  const navigate = useNavigate();
  const [nextFightLive, setNextFightLive] = useState("");

  //Database "Fights" data
  const [fightName, setFightName] = useState("");
  const [allFights, setAllFights] = useState("");
  const [betStatus, setBetStatus] = useState();
  const [live, setLive] = useState(false);
  const [noCredit, setNoCredit] = useState(false);
  const [fightStatus, setFightStatus] = useState(true);
  const [winner, setWinner] = useState("");
  const [urlVideo, setUrlVideo] = useState("");
  const [idVideo, setIdVideo] = useState("");

  //Data related to Bets
  const [colorSelected, setColorSelected] = useState("");
  const [betAmount, setBetAmount] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [betDone, setBetDone] = useState(false);
  const [currentBet, setCurrentBet] = useState([]);

  const [alertMessage, setAlertMessage] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [cancelRedirect, setCancelRedirect] = useState(false);
  const [originalUidFight, setOriginalUidFight] = useState("");

  const [allBets, setAllBets] = useState([]);
  const [checkBets, setCheckAllBets] = useState(false);
  const [successBetMSG, setSuccessBetMSG] = useState(false);
  const [successBetRoulette, setSuccessBetRoulette] = useState(false);
  const [totalV, setTotalV] = useState(0);
  const [totalR, setTotalR] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [activeLive, setActiveLive] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [ticketsTotal, setTicketsTotal] = useState([]);
  const [roulette, setRoulette] = useState([]);
  const [loadingRoulette, setLoadingRoulette] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateBets, setDateBets] = useState(false);

  const betType = 1;

  //===============================================================
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setSelectedTickets([])
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const [tabValue, setTabValue] = useState(0);

  const handleTicketFromList = (ticket) => {
    const alreadyAddTicket = selectedTickets.includes(ticket);
    if (alreadyAddTicket) {
      removeTicket(ticket);
      return;
    }
    setSelectedTickets([ticket]);
  };

  const removeTicket = (value) => {
    const filteredTickets = selectedTickets.filter((item) => item !== value);
    setSelectedTickets(filteredTickets);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const validateInput = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }

    setBetAmount(parseFloat(event.target.value))
    // otherwise, continue with the rest of your logic
    // ...
  }

  const validateInputEdit = (event) => {
    if (event.target.value.match(/[^0-9]/)) {
      event.preventDefault();
    }

    setBetAmountEdit(parseFloat(event.target.value))
    // otherwise, continue with the rest of your logic
    // ...
  }

  const validatePaste = (event) => {
    event.preventDefault();
  }

  const handleCancelRedirect = () => {
    setCancelRedirect(true);
    handleClose();
    setNextFightLive(originalUidFight);
  }

  const getUserData = async () => {
    const unsubscribe = onSnapshot(doc(firestore, "Users", uidUser), (doc) => {
      if(doc.data().dateBalance){
        const currentDate = new Date()
        const date = doc.data().dateBalance.toDate()
        const dif = currentDate.getTime() - date.getTime()
        const days = dif / (1000 * 60 * 60 * 24)
        if (days >= 1) {
          setNoCredit(true)
        }
      }
      setUserBalance(doc.data().balance);
      setActiveLive(doc.data().activeLive);
    });
    return () => unsubscribe();
  }

  const getRealData = async () => {
    // let dateTemp;
    // let timeTemp;
    const unsubscribe = onSnapshot(doc(dbFights, "Fights", paramsIdFight.idFight), (doc) => {
      setAllFights(doc.data())
      setFightName(doc.data().fightName);
      setBetStatus(doc.data().betStatus);
      setLive(doc.data().live);
      setFightStatus(doc.data().status);
      setWinner(doc.data().winner);
      setUrlVideo(doc.data().urlVideo);
      setIdVideo(doc.data().idVideo);
      // dateTemp = doc.data().date;
      // timeTemp = doc.data().time;
      // currentDate = new Date(dateTemp * 1000);
      // currentDate = new Date(timeTemp * 1000);
    });
    return () => unsubscribe();
  }

  const getBetData = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(betsCollectionRef, where("uidFight", "==", paramsIdFight.idFight), where("uidUser", "==", uidUser));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
      });
      setCurrentBet(bet);
      if (bet.length !== 0) {
        setBetDone(true);
      }
    });
    return () => unsubscribe();
  }

  const getUserBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(betsCollectionRef, where("uidUser", "==", uidUser), orderBy("date", "desc"), limit(1));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
      });

      const currentDate = new Date()
      const date = bet[0].date.toDate()
      const dif = currentDate.getTime() - date.getTime()
      const days = dif / (1000 * 60 * 60 * 24)

      if (days >= 21) {
        setDateBets(true)
      } else {
        setDateBets(false)
      }
    });
    return () => unsubscribe();
  }

  const getBetDataRoulette = async () => {
    const betsCollectionRef = collection(firestore, "Roulette");
    const q = query(betsCollectionRef, where("status", "==", 1));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const rouletteTickets = [];
      const rouletteArray = [];
      querySnapshot.forEach((doc) => {
        rouletteTickets.push(...doc.data().listOfNumbers);
        rouletteArray.push({ ...doc.data(), id: doc.id });
      });
      setRoulette(rouletteArray[0])
      rouletteTickets.sort(function (a, b) {
        const ticketA = a.number;
        const ticketB = b.number;
        if (ticketA < ticketB) {
          return -1;
        }
        if (ticketA > ticketB) {
          return 1;
        }
        return 0;
      });
      setTicketsTotal(rouletteTickets);
    });
    return () => unsubscribe();
  }

  const createBetRoulette = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoadingRoulette(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/buy-number`, {
        method: "POST",
        body: JSON.stringify({ id: roulette.id, selectedNumber: selectedTickets[0] }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Res: ", response)
        setHuntError(data.message);
        setOpenModalError(true);
        setLoadingRoulette(false);
        setAnchorEl(null);
        setSelectedTickets([])
        return;
      }
      setOpenSuccessRoulette(true);
      setSuccessBetRoulette(true);
      setLoadingRoulette(false);
      setSelectedTickets([])
    } catch (error) {
      console.error("Error: ", error)
      setAlertMessage(error?.message);
      setHuntError(error?.message);
      setOpenModalError(true);
      setLoadingRoulette(false);
      setSelectedTickets([])
    }
  }



  const GetAllBets = async () => {
    const betsCollectionRef = collection(firestore, "Bets");
    const q = query(betsCollectionRef, where("uidFight", "==", paramsIdFight.idFight));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bet = [];
      const betsV = [];
      const betsR = [];
      querySnapshot.forEach((doc) => {
        bet.push({ ...doc.data(), id: doc.id });
        if (doc.data().color === "Verde") {
          betsV.push({ ...doc.data(), id: doc.id });
        }
        if (doc.data().color === "Rojo") {
          betsR.push({ ...doc.data(), id: doc.id });
        }
      });
      setAllBets(bet);
      if (bet.length !== 0) {
        setCheckAllBets(true);
        setTotalV((betsV.reduce((a, v) => a = a + v.bet, 0)))
        setTotalR((betsR.reduce((a, v) => a = a + v.bet, 0)))
      }

    });
    return () => unsubscribe();
  }

  //============ MAKE BET =========================================

  const handleBetStandarAmount = async (amount) => {
    setBetAmount(amount);
  }

  const handleMakeBet = async (e) => {
    e.preventDefault();

    let betHelper = true;
    setAlertMessage("");
    setSuccessBetMSG(false);

    //Validations
    if (colorSelected === "") {
      setAlertMessage("Primero debes de apostar a algun color");
      betHelper = false;
      return;
    }
    if (!betAmount) {
      setAlertMessage("Debes de realizar una apuesta");
      betHelper = false;
      return;
    }

    if (betAmount < 50) {
      setAlertMessage("La apuesta debe ser mayor o igual a 50");
      betHelper = false;
      return;
    }

    if (userBalance < betAmount) {
      setAlertMessage("Saldo insuficiente");
      betHelper = false;
      return;
    }

    if (betHelper) {
      makeBet();
    }
  }

  const makeBet = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-bet`, {
        method: "POST",
        body: JSON.stringify({ figthId: paramsIdFight.idFight, color: colorSelected, bet: betAmount }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status !== 200) {
        console.error("Res: ", response)
        setAlertMessage(response?.message);
        setLoading(false);
        return;
      }
      setBetDone(true);
      setOpenSuccess(true);
      setSuccessBet(true);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error)
      setAlertMessage(error?.message);
      setLoading(false);
    }
  }

  //=================================================================

  //================= EDIT MODE =====================================

  const [colorEdit, setColorEdit] = useState("");
  const [amountEdit, setAmountEdit] = useState(0);
  const [idEdit, setIdEdit] = useState("");
  const [colorSelectedEdit, setColorSelectedEdit] = useState("");
  const [betAmountEdit, setBetAmountEdit] = useState(0);
  const [alertMessageEdit, setAlertMessageEdit] = useState("");
  const [newAmount, setNewAmount] = useState(0);

  const [editSuccess, setEditSuccess] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleOpenEditModal = (idToEdit, bet, color) => {
    setOpenEditModal(true);
    handleEditMode(idToEdit);
    setAmountEdit(bet);
    setColorEdit(color);
    setBetAmountEdit(bet);
    setEditSuccess(false);
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    handleCancelEdit();
  }

  const handleCancelEdit = () => {
    setEditMode(false);
    setColorEdit("");
    setAmountEdit(0);
    setIdEdit("");
    setColorSelectedEdit("");
    setBetAmountEdit(0);
    setEditSuccess(false);
    setAlertMessageEdit("");
  }

  const handleEditMode = (idToEdit) => {
    if (editMode) {
      return;
    }
    else {
      getEditBet(idToEdit);
    }
  }

  const getEditBet = async (idToEdit) => {
    const ref = doc(firestore, "Bets", idToEdit);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      setColorEdit(docSnap.data().color);
      setAmountEdit(docSnap.data().bet);
      setIdEdit(idToEdit);
      setEditMode(true);
    } else {
      setEditMode(false)
    }
  }

  const handleSaveEditBet = () => {
    let betHelper = true;
    setAlertMessageEdit("");
    setNewAmount(betAmountEdit - amountEdit);

    //Validations
    if (colorSelectedEdit === "") {
      setAlertMessageEdit("Primero debes de apostar a algun color");
      betHelper = false;
      return;
    }
    if (!betAmountEdit || betAmountEdit === null) {
      setAlertMessageEdit("Debes de realizar una apuesta");
      betHelper = false;
      return;
    }

    if (betAmountEdit < amountEdit) {
      setAlertMessageEdit("La apuesta debe ser mayor o igual a la previa: ", amountEdit.toFixed(2));
      betHelper = false;
      return;
    }

    if (userBalance < newAmount) {
      setAlertMessageEdit("Saldo insuficiente");
      betHelper = false;
      return;
    }

    if (betHelper) {
      editBet();
      setOpenEditModal(false);
    }
  }

  const editBet = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/edit-bet`, {
        method: "POST",
        body: JSON.stringify({ betId: idEdit, color: colorSelectedEdit, bet: betAmountEdit }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Res: ", response)
        setHuntError(data.message);
        setOpenModalError(true);
        setLoading(false);
        return;
      }
      setEditSuccess(true);
      setOpenEditModal(true);
      setLoading(false);
    } catch (error) {
      console.error("Error: ", error)
      console.error(error);
      setColorEdit("");
      setAmountEdit(0);
      setIdEdit("");
      setColorSelectedEdit("");
      setBetAmountEdit(0);
      setHuntError(error?.message);
      setEditMode(false);
      setOpenModalError(true);
      setLoading(false);
    }
  }

  //=================================================================

  const searchFightLive = async () => {
    const ref = collection(firestore, "Fights");
    const q = query(ref, where("live", "==", true), limit(1));

    const item = [];
    let isFightActive = false;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push({ ...doc.data(), id: doc.id });
    });
    if (item.length === 0) {
      return;
    }
    item.forEach((data, index) => {
      if (data.id !== paramsIdFight.idFight && data.status === true) {
        setNextFightLive(data.id);
        isFightActive = true;
      }
    });

    if (isFightActive) {
      handleOpen();
    }
  }

  useEffect(() => {
    if (paramsIdFight.idFight !== undefined && paramsIdFight.idFight !== "") {
      getBetDataRoulette()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paramsIdFight.idFight !== undefined && paramsIdFight.idFight !== "") {
      getRealData();
      getUserData();
      getUserBets();
      GetAllBets();
      getBetData();
      setOriginalUidFight(paramsIdFight.idFight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fightStatus) {
      searchFightLive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightStatus]);

  const [countDown, setCountDown] = useState(10);
  const timerId = useRef();

  useEffect(() => {
    if (open) {
      timerId.current = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000)
      return () => clearInterval(timerId.current);
    }
  }, [open]);

  useEffect(() => {
    if (countDown <= 0 && !cancelRedirect) {
      clearInterval(timerId.current);
      navigate("/fightview/" + nextFightLive);
      window.location.reload(false);
    }
    if (countDown === -2) {
      navigate("/fightview/" + nextFightLive);
      window.location.reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSuccessRoulette, setOpenSuccessRoulette] = useState(false);
  const [successBet, setSuccessBet] = useState(false);

  const handleCloseSuccess = () => {
    setOpenSuccessRoulette(false)
    setSuccessBetRoulette(false);
    setOpenSuccess(false);
    setSuccessBet(false);
    //reset variables in "create new bet"
    setAnchorEl(null);
    setBetAmount(0);
    setColorSelected("");
    setAlertMessage("");
  }

  //=================================================================

  //================= Hunt Bet =====================================

  const [openHuntModal, setOpenHuntModal] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [huntError, setHuntError] = useState(null);
  const [betHunt, setBetHunt] = useState(null);
  const [betToHunt, setBetToHunt] = useState(null);

  const handleOpenHuntBet = (values) => {
    if (values.bet > userBalance) {
      setHuntError("Saldo insuficiente")
      setOpenModalError(true);
      return;
    }
    let color = values.color === "Rojo" ? "Verde" : "Rojo";
    const bet = { ...values, color: color };
    setBetHunt(bet);
    setBetToHunt(values);
    setOpenHuntModal(true);
  }

  const huntBet = async () => {
    const auth = getAuth()
    const { currentUser } = auth
    const token = await getIdToken(currentUser)
    setOpenHuntModal(false);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/hunt-bet`, {
        method: "POST",
        body: JSON.stringify({ betId: betToHunt.id, color: betToHunt.color, bet: betToHunt.bet }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.status !== 200) {
        console.error("Res: ", response)
        setHuntError(data.message);
        setOpenModalError(true);
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setHuntError(error?.message);
      setOpenHuntModal(false);
      setOpenModalError(true);
      setLoading(false);
    }
  }

  return (
    <Spin
      style={{
        position: 'fixed', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
      size="large"
      spinning={loading}
    >
      <div style={{ backgroundColor: "#2b2b2b" }}>
        <Container component="main" maxWidth="lg" style={{ backgroundColor: "#2b2b2b", paddingBottom: "3em" }}>
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/*== MODAL'S ==================================================================================== */}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: "#000000" }}>
                  La pelea ha terminado!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 1 }} style={{ color: "#000000" }}>
                  No te preocupes, te llevaremos a la siguiente pelea automaticamente en: {countDown}
                </Typography>
                <Button variant="outlined" color="error" size="large" sx={{ mt: 4 }}
                  onClick={(e) => handleCancelRedirect()}
                  style={{
                    borderRadius: 35,
                    padding: "12px 50px",
                    fontSize: "12px"
                  }}>
                  Quedarme aquí
                </Button>
              </Box>
            </Modal>
            {/*== MODAL'S END ==================================================================================== */}

            <Container maxWidth="lg" sx={{ mt: 3 }} >
              {live && activeLive === true && dateBets === false ?
                <div style={{ position: "relative", paddingBottom: "56.25%", overflow: "hidden", height: "100%", maxWidth: "100%" }}>
                  <iframe
                    id={idVideo}
                    src={urlVideo}
                    type="application/x-mpegURL"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    allow="autoplay;encrypted-media"
                    allowFullScreen={true}
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    oallowfullscreen="true"
                    msallowfullscreen="true"
                    style={{ position: "absolute", top: "0", left: "0" }}
                    title='Live'>
                  </iframe>
                </div> :
                <Box sx={{
                  display: 'flex',
                  backgroundColor: "black",
                  height: {
                    xs: 220,
                    md: 420,
                    lg: 550
                  },
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                  style={{ borderRadius: 20 }}>
                  <Typography component="h1" variant="h5" align="center" sx={{ mt: 4 }} style={{ fontSize: "20px", color: 'white', fontWeight: "normal" }}>
                    Transmisión apagada
                  </Typography>
                </Box>
              }

            </Container>
            <Grid container spacing={2} direction="row" alignItems="inherit">
              <Grid item xs={12} sx={{ mt: 2, mb: 1, ml: 2 }}>
                {dateBets &&
                  <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold", marginBottom: "1em" }}>
                    Tienes varios días sin apostar, es necesario realizar por lo menos una apuesta para poder seguir disfrutando de las  transmisiones en vivo en MisGallosMx
                  </Typography>
                }
                {noCredit &&
                  <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold", marginBottom: "1em" }}>
                    Han pasado las 24 horas de acceso gratuito a las transmisiones en vivo. Para seguir disfrutando de nuestro streaming, debes tener al menos $100.00 en tu cuenta.
                  </Typography>
                }
                <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold" }}>
                  {fightName}
                </Typography>
                {winner !== "" &&
                  <Typography component="h1" variant="h5" align="center" style={{ fontSize: "20px", color: '#FFFFFF', fontWeight: "bold" }}>
                    (Pelea finalizada)
                  </Typography>
                }
                <Divider sx={{ pt: 1 }} />
              </Grid>
            </Grid>

            {!fightStatus ?
              <>
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }} direction="column" alignItems="center" justifyContent="center" >
                  <Grid item xs={12} sx={{ mt: 1, mb: 3 }}>
                    <Typography component="h1" variant="h5"
                      style={{ textAlign: "center", fontSize: "18px", color: '#ffffff' }}>
                      ¡La pelea ha terminado!
                    </Typography>
                    <Typography component="h1" variant="h5" sx={{ mt: 0.5, pb: 0.5 }}
                      style={{ textAlign: "center", fontSize: "18px", color: '#ffffff' }}>
                      El lado ganador es
                    </Typography>
                    {winner === "Verde" &&
                      <Box align="center" sx={{ mt: 1 }}
                        style={{
                          backgroundColor: "#50AF48",
                          borderRadius: 35,
                          padding: "12px 20px",
                          fontSize: "12px"
                        }}>
                        <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                          style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                          {winner}
                        </Typography>
                      </Box>
                    }
                    {winner === "Rojo" &&
                      <Box align="center" sx={{ mt: 1 }}
                        style={{
                          backgroundColor: "#D32F2F",
                          borderRadius: 35,
                          padding: "12px 20px",
                          fontSize: "12px"
                        }}>
                        <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                          style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                          {winner}
                        </Typography>
                      </Box>
                    }
                    {winner === "Empate" &&
                      <Box align="center" sx={{ mt: 1 }}
                        style={{
                          backgroundColor: "#1D1D1B",
                          borderRadius: 35,
                          padding: "12px 20px",
                          fontSize: "12px"
                        }}>
                        <Typography component="h1" variant="h5" sx={{ mt: 0.5 }}
                          style={{ textAlign: "center", fontSize: "18px", color: 'white' }}>
                          {winner}
                        </Typography>
                      </Box>
                    }
                  </Grid>
                </Grid>
              </> :
              <></>
            }
            {/* ========= MAKE BET PANEL================================================================ */}
            {betStatus ?
              <>
                {/* Modal hunt bet */}
                <Modal
                  open={openHuntModal}
                  onClose={() => setOpenHuntModal(false)}
                  aria-labelledby="modal-modaFl-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleSuccess}>
                    <Grid direction="column" container alignItems="center" justifyContent="center">
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
                        <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                          ¿Estas seguro de cazar apuesta?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                        <WarningIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "orange" }} />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} >
                        <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                          Color: {betHunt?.color}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                        <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                          Cantidad: ${betHunt?.bet.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
                        <Button variant="outlined" color="warning" size="small"
                          onClick={() => setOpenHuntModal(false)}
                          style={{
                            borderRadius: 35,
                            padding: "12px 50px",
                            fontSize: "12px",
                            width: "100px"
                          }}>
                          Cancelar
                        </Button>
                        <Button variant="contained" color="primary" size="small" sx={{ ml: 1 }}
                          onClick={huntBet}
                          style={{
                            borderRadius: 35,
                            padding: "12px 50px",
                            fontSize: "12px",
                            width: "100px"
                          }}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                {/* Modal hunt bet error*/}
                <Modal
                  open={openModalError}
                  onClose={() => setOpenModalError(false)}
                  aria-labelledby="modal-modaFl-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleSuccess}>
                    <Grid direction="column" container alignItems="center" justifyContent="center">
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
                        <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                          {huntError}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                        <ErrorIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "red" }} />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
                        <Button variant="contained" size="small" sx={{ ml: 1 }}
                          onClick={() => setOpenModalError(false)}
                          style={{
                            borderRadius: 35,
                            padding: "12px 50px",
                            fontSize: "12px",
                            width: "100px",
                            backgroundColor: "#EA9F26",
                            color: "white"
                          }}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                {!editMode ?
                  <>
                    {successBet &&
                      <div>
                        <Modal
                          open={openSuccess}
                          onClose={handleCloseSuccess}
                          aria-labelledby="modal-modaFl-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={styleSuccess}>
                            <Grid direction="column" container alignItems="center" justifyContent="center">
                              <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
                                <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                                  ¡Tu apuesta se creó!
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                <CheckCircleOutlineIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "green" }} />
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} >
                                <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                  Color: {colorSelected}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                  Cantidad: ${betAmount.toFixed(2)}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
                                <Button variant="contained" color="inherit" size="large"
                                  onClick={(e) => handleCloseSuccess()}
                                  style={{
                                    borderRadius: 35,
                                    padding: "12px 50px",
                                    fontSize: "14px",
                                    backgroundColor: "#EA9F26",
                                    color: "white"
                                  }}>
                                  Aceptar
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                      </div>}

                    {successBetRoulette &&
                      <div>
                        <Modal
                          open={openSuccessRoulette}
                          onClose={handleCloseSuccess}
                          aria-labelledby="modal-modaFl-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={styleSuccess}>
                            <Grid direction="column" container alignItems="center" justifyContent="center">
                              <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
                                <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                                  ¡Número comprado exitosamente!
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                <CheckCircleOutlineIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "green" }} />
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} >
                                <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                  Número: {selectedTickets}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                  Costo: ${roulette.price}.00
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
                                <Button variant="contained" color="inherit" size="large"
                                  onClick={(e) => handleCloseSuccess()}
                                  style={{
                                    borderRadius: 35,
                                    padding: "12px 50px",
                                    fontSize: "14px",
                                    backgroundColor: "#EA9F26",
                                    color: "white"
                                  }}>
                                  Aceptar
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Modal>
                      </div>}
                    <div className="container-counters">
                      <div className="horizontal-container-cards">
                        <div className="cards-counters">
                          <div>
                            <Typography sx={{ fontSize: 14, display: "flex", alignItems: "center", justifyContent: "center" }} align="left"  >
                              <SportsMmaIcon style={{ color: "rgb(232, 26, 26)" }} />Peleas obligatorias:
                            </Typography>
                          </div>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                            <Typography sx={{ fontSize: 19, fontWeight: "bolder" }} align="left"  >
                              {allFights.mandatoryFights}
                            </Typography>
                          </div>
                        </div>
                        <div className="cards-counters">
                          <Typography sx={{ fontSize: 13 }} align="left" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} >
                            <RecommendIcon style={{ color: "rgb(0 175 31)", marginRight: "5px" }} /> Partidos confirmados:
                          </Typography>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                            <Typography sx={{ fontSize: 19, fontWeight: "bolder" }} align="left"  >
                              {allFights.confirmedGames}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div className="horizontal-container-cards">
                        <div className="cards-counters">
                          <Typography sx={{ fontSize: 14 }} align="left" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} >
                            <NumbersIcon style={{ color: "rgb(26 59 232)", marginRight: "5px" }} />Número de gallos:
                          </Typography>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                            <Typography sx={{ fontSize: 19, fontWeight: "bolder" }} align="left"  >
                              {allFights.amountRoosters}
                            </Typography>
                          </div>
                        </div>
                        <div className="cards-counters">
                          <Typography sx={{ fontSize: 14 }} align="left" style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }} >
                            <MonetizationOnIcon style={{ color: "rgb(179, 84, 38)", marginRight: "5px" }} />Costo de entrada:
                          </Typography>
                          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }}>
                            <Typography sx={{ fontSize: 19, fontWeight: "bolder" }} align="left"  >
                              ${allFights.amountCover}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Grid direction="row" justifyContent="center" container columnSpacing={{ sx: 2, sm: 3, md: 4, lg: 4 }} sx={{ mt: 3, mb: 2 }}>
                      <Grid item xs={12} md={11} lg={11.5}>
                        <Paper sx={{ maxHeight: 600, minHeight: 260 }} style={{ borderRadius: 20, boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                          <Grid direction="column" container alignItems="center" justifyContent="center">
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 3, }} >
                              <Typography sx={{ fontSize: 16 }} align="left" style={{ textAlign: "center" }} >
                                Elige un color
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
                              {colorSelected !== "Verde" &&
                                <Button variant="outlined" color="success" size="large"
                                  onClick={(e) => setColorSelected("Verde")}
                                  style={{
                                    borderRadius: 35,
                                    padding: "12px 50px",
                                    fontSize: "12px"
                                  }}>
                                  Verde
                                </Button>}
                              {colorSelected === "Verde" &&
                                <Button variant="contained" color="success" size="large"
                                  onClick={(e) => setColorSelected("Verde")}
                                  style={{
                                    borderRadius: 35,
                                    padding: "12px 50px",
                                    fontSize: "12px"
                                  }}>
                                  Verde
                                </Button>}
                              {colorSelected !== "Rojo" &&
                                <Button variant="outlined" color="error" size="large" sx={{ ml: 2 }}
                                  onClick={(e) => setColorSelected("Rojo")}
                                  style={{
                                    borderRadius: 35,
                                    padding: "12px 50px",
                                    fontSize: "12px"
                                  }}>
                                  Rojo
                                </Button>}
                              {colorSelected === "Rojo" &&
                                <Button variant="contained" color="error" size="large" sx={{ ml: 2 }}
                                  onClick={(e) => setColorSelected("Rojo")}
                                  style={{
                                    borderRadius: 35,
                                    padding: "12px 50px",
                                    fontSize: "12px"
                                  }}>
                                  Rojo
                                </Button>}
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mt: 3, mb: 2 }}>
                              <Typography sx={{ fontSize: 16 }} align="left" style={{ textAlign: "center" }} >
                                ¿Cuánto quieres apostar?
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                              <Stack
                                sx={{ pb: 1 }}
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                              >
                                <Button variant="contained" size="large"
                                  onClick={(e) => handleBetStandarAmount(100)}
                                  style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                                  $100
                                </Button>
                                <Button variant="contained" size="large"
                                  onClick={(e) => handleBetStandarAmount(200)}
                                  style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                                  $200
                                </Button>
                                <Button variant="contained" size="large"
                                  onClick={(e) => handleBetStandarAmount(500)}
                                  style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                                  $500
                                </Button>
                                <Button variant="contained" size="large"
                                  onClick={(e) => handleBetStandarAmount(1000)}
                                  style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                                  $1,000
                                </Button>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 4, mt: 2 }}>
                              <Stack component="form" onSubmit={handleMakeBet} noValidate
                                sx={{ pb: 1 }}
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                              >
                                <TextField id="bet" label="Otra cantidad" type="number" InputProps={{ inputProps: { min: 0 } }} sx={{ maxWidth: 190 }}
                                  value={betAmount}
                                  onChange={validateInput}
                                  onPaste={validatePaste}
                                />
                                <Button variant="contained" color="inherit" size="small" type="submit"
                                  style={{
                                    backgroundColor: "#E81A1A",
                                    color: "#FFFFFF",
                                    borderRadius: 35,
                                    padding: "8px 30px",
                                    fontSize: "12px"
                                  }}>
                                  Apostar
                                </Button>
                              </Stack>
                            </Grid>
                            {alertMessage !== "" &&
                              <Grid item xs={12} sx={{ mb: 3 }}>
                                <Alert severity="error">{alertMessage}</Alert>
                              </Grid>}
                            {successBetMSG &&
                              <Grid item xs={12} sx={{ mb: 3, }}>
                                <Alert severity="success">{"Tu apuesta se ha creado!"}</Alert>
                              </Grid>}
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                  :
                  <>
                    {/*=========== MODAL DE EDICION  ========================================================= */}
                    <div>
                      <Modal
                        open={openEditModal}
                        onClose={handleCloseEditModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={styleSuccess}>
                          {!editSuccess ?
                            <>
                              <Grid direction="row" container alignItems="baseline" justifyContent="end">
                                <Grid item xs={11}>
                                  <Typography sx={{ fontSize: 22, mb: .5 }} align="left" style={{ textAlign: "center" }} >
                                    Estás editando tu apuesta
                                  </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                  <DisabledByDefaultRoundedIcon fontSize="inherit" color="inherit" style={{ fontSize: "32px", color: "#D32F2F" }}
                                    onClick={(e) => handleCancelEdit()} />
                                </Grid>
                              </Grid>
                              <Grid direction="column" container alignItems="center" justifyContent="center">
                                <Grid item xs={12} md={12} lg={12} >
                                  <Typography sx={{ fontSize: 16 }} align="left" style={{ textAlign: "center" }} >
                                    Color: {colorEdit}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                                  <Typography sx={{ fontSize: 16 }} align="left" style={{ textAlign: "center" }} >
                                    Cantidad: ${amountEdit.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                  <hr style={{ minWidth: "260px" }} />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }} >
                                  <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                    Elige un color
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 1 }}>
                                  {colorSelectedEdit !== "Verde" &&
                                    <Button variant="outlined" color="success" size="large"
                                      onClick={(e) => setColorSelectedEdit("Verde")}
                                      style={{
                                        borderRadius: 35,
                                        padding: "10px 30px",
                                        fontSize: "12px"
                                      }}>
                                      Verde
                                    </Button>}
                                  {colorSelectedEdit === "Verde" &&
                                    <Button variant="contained" color="success" size="large"
                                      onClick={(e) => setColorSelectedEdit("Verde")}
                                      style={{
                                        borderRadius: 35,
                                        padding: "10px 30px",
                                        fontSize: "12px"
                                      }}>
                                      Verde
                                    </Button>}
                                  {colorSelectedEdit !== "Rojo" &&
                                    <Button variant="outlined" color="error" size="large" sx={{ ml: 2 }}
                                      onClick={(e) => setColorSelectedEdit("Rojo")}
                                      style={{
                                        borderRadius: 35,
                                        padding: "10px 30px",
                                        fontSize: "12px"
                                      }}>
                                      Rojo
                                    </Button>}
                                  {colorSelectedEdit === "Rojo" &&
                                    <Button variant="contained" color="error" size="large" sx={{ ml: 2 }}
                                      onClick={(e) => setColorSelectedEdit("Rojo")}
                                      style={{
                                        borderRadius: 35,
                                        padding: "10px 30px",
                                        fontSize: "12px"
                                      }}>
                                      Rojo
                                    </Button>}
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                                  <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                    ¿Cuánto quieres apostar?
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                  <Typography sx={{ fontSize: 12 }} align="left" style={{ textAlign: "center" }} >
                                    No puedes apostar una cantidad menor a la que estás cambiando.
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                  <TextField id="betEdit" label="Cantidad" type="number" InputProps={{ inputProps: { min: 0 } }} sx={{ maxWidth: 220 }}
                                    value={betAmountEdit}
                                    onChange={validateInputEdit}
                                    onPaste={validatePaste}
                                  />
                                </Grid>
                                {alertMessageEdit !== "" &&
                                  <Grid item xs={12} sx={{ mb: 1 }}>
                                    <Alert severity="error">{alertMessageEdit}</Alert>
                                  </Grid>}
                                <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
                                  <Button variant="contained" color="inherit" size="large"
                                    onClick={handleSaveEditBet}
                                    style={{
                                      borderRadius: 35,
                                      padding: "12px 50px",
                                      fontSize: "14px",
                                      backgroundColor: "#EA9F26",
                                      color: "white"
                                    }}>
                                    Guardar
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                            :
                            <>
                              <Grid direction="column" container alignItems="center" justifyContent="center">
                                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 1 }}>
                                  <Typography sx={{ fontSize: 24 }} align="left" style={{ textAlign: "center" }} >
                                    ¡Tu apuesta se editó!
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                  <CheckCircleOutlineIcon fontSize="inherit" color="inherit" style={{ fontSize: "98px", color: "green" }} />
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} >
                                  <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                    Color: {colorSelectedEdit}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                                  <Typography sx={{ fontSize: 18 }} align="left" style={{ textAlign: "center" }} >
                                    Cantidad: ${betAmountEdit.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} sx={{ mb: 1, mt: 2 }}>
                                  <Button variant="contained" color="inherit" size="large"
                                    onClick={(e) => handleCloseEditModal()}
                                    style={{
                                      borderRadius: 35,
                                      padding: "12px 50px",
                                      fontSize: "14px",
                                      backgroundColor: "#EA9F26",
                                      color: "white"
                                    }}>
                                    Aceptar
                                  </Button>
                                </Grid>
                              </Grid>
                            </>}
                        </Box>
                      </Modal>
                    </div>
                  </>}
                {/*===========  FIN DE MODAL DE EDICION  ========================================================= */}
                {/*===================================================================================== */}
              </>
              :
              <>
                <Grid container spacing={2} sx={{ mt: 1 }} direction="column" alignItems="center" justifyContent="center">
                  <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
                    <Typography sx={{ fontSize: 16 }} align="left" style={{ textAlign: "center", color: "#EA9F26", backgroundColor: "#1D1D1B", borderRadius: 20, padding: "8px 30px" }} >
                      Las apuestas se han detenido.
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }

            {/* =============== TABLAS DE APUESTAS ========================================================== */}
            {betDone ?
              <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6} lg={6} align="center" sx={{ mt: 4 }}>
                  <StyledTabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor=""
                    textColor="inherit"
                    sx={{ width: "100%" }}>
                    <Tab label="Mis apuestas" sx={{ width: "50%" }} style={{ back: "green", color: "#ffffff" }} />
                    <Tab label="Ver todas las apuestas" sx={{ width: "50%" }} style={{ color: "#ffffff" }} />
                  </StyledTabs>
                </Grid>
                {tabValue === 0 &&
                  <Grid item xs={12} sx={{ mt: 1, mb: 6, minWidth: 350, maxWidth: 350 }} >
                    <TableContainer component={Paper} sx={{ mt: 2, maxHeight: "400px", maxWidth: "380px", borderRadius: "13px" }}>
                      <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" >Color</TableCell>
                            <TableCell align="center" >Apuesta</TableCell>
                            <TableCell align="center" >Estatus</TableCell>
                            {betStatus &&
                              <TableCell align="center" >Editar</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentBet.map((doc, index) => (
                            <TableRow key={doc.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              {doc.color === "Verde" &&
                                <TableCell align="center" sx={{ maxWidth: 40 }} style={{ fontSize: "12px", color: "#2E7D32" }} >{doc.color}</TableCell>}
                              {doc.color === "Rojo" &&
                                <TableCell align="center" sx={{ maxWidth: 40 }} style={{ fontSize: "12px", color: "#D32F2F" }} >{doc.color}</TableCell>}
                              <TableCell align="center" sx={{ maxWidth: 50 }} style={{ fontSize: "12px" }}>${doc.bet.toFixed(2)}</TableCell>
                              <TableCell align="center" sx={{ maxWidth: 110 }} style={{ fontSize: "12px" }}>{doc.status}</TableCell>
                              {betStatus &&
                                <TableCell align="center" sx={{ maxWidth: 50 }} style={{ fontSize: "12px" }}>
                                  {!editMode && !doc.isBetHunt && (
                                    <Button variant="contained" color="primary" size="small"
                                      style={{
                                        color: "#FFFFFF",
                                        borderRadius: 38,
                                        fontSize: "9px"
                                      }}
                                      onClick={(e) => handleOpenEditModal(doc.id, doc.bet, doc.color)}>
                                      Editar
                                    </Button>
                                  )}
                                </TableCell>}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>}
                {tabValue === 1 &&
                  <>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Typography variant="h6" component="h2" align="center" style={{ color: "#2E7D32" }}>
                        Total en verde: ${totalV.toFixed(2)}
                      </Typography>
                      <Typography variant="h6" component="h2" align="center" style={{ color: "#D32F2F" }}>
                        Total en Rojo: ${totalR.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 6 }}>
                      <TableContainer component={Paper} sx={{ mt: 2, maxHeight: "400px", maxWidth: "380px", borderRadius: "13px" }}>
                        <Table sx={{ minWidth: 300, maxWidth: 600 }} aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Color</TableCell>
                              <TableCell align="center">Apuesta</TableCell>
                              <TableCell align="center">Opciones</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allBets.map((doc, index) => (
                              <TableRow key={doc.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {doc.color === "Verde" && (
                                  <>
                                    <TableCell align="center" style={{ color: "#2E7D32" }}>{doc.color}</TableCell>
                                    <TableCell align="center" style={{ color: "#2E7D32" }}>${doc.bet.toFixed(2)}</TableCell>
                                    {!doc.isBetHunt && (doc.uidUser !== user.uid) && betStatus ? (
                                      <TableCell align="center" style={{ color: "#2E7D32" }}>
                                        <Button variant="contained" size="small"
                                          style={{
                                            color: "#EA9F26",
                                            backgroundColor: "#1D1D1B",
                                            borderRadius: 38,
                                            fontSize: "9px"
                                          }}
                                          onClick={() => handleOpenHuntBet(doc)}
                                        >
                                          Cazar apuesta
                                        </Button>
                                      </TableCell>
                                    ) :
                                      <TableCell align="center" style={{ color: "#1D1D1B" }}>
                                        {doc.uidUser === user.uid && "Tu apuesta"}
                                        {doc.uidBetHunt === user.uid && "Apuesta cazada"}
                                      </TableCell>
                                    }
                                  </>
                                )}

                                {doc.color === "Rojo" && (
                                  <>
                                    <TableCell align="center" style={{ color: "#D32F2F" }}>{doc.color}</TableCell>
                                    <TableCell align="center" style={{ color: "#D32F2F" }}>${doc.bet.toFixed(2)}</TableCell>
                                    {!doc.isBetHunt && (doc.uidUser !== user.uid) && betStatus ? (
                                      <TableCell align="center" style={{ color: "#D32F2F" }}>
                                        <Button variant="contained" size="small"
                                          style={{
                                            color: "#EA9F26",
                                            backgroundColor: "#1D1D1B",
                                            borderRadius: 38,
                                            fontSize: "9px"
                                          }}
                                          onClick={() => handleOpenHuntBet(doc)}
                                        >
                                          Cazar apuesta
                                        </Button>
                                      </TableCell>
                                    ) :
                                      <TableCell align="center" style={{ color: "#1D1D1B" }}>
                                        {doc.uidUser === user.uid && "Tu apuesta"}
                                        {doc.uidBetHunt === user.uid && "Apuesta cazada"}
                                      </TableCell>
                                    }
                                  </>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>}
              </Grid>
              :
              <>
                {checkBets ?
                  <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sx={{ mt: 4 }}>
                      <Typography variant="h6" component="h2" align="center" style={{ color: "#FFFFFF" }}>
                        Apuestas globales
                      </Typography>
                      <Typography variant="h6" component="h2" align="center" style={{ color: "#2E7D32" }}>
                        Total en verde: ${totalV.toFixed(2)}
                      </Typography>
                      <Typography variant="h6" component="h2" align="center" style={{ color: "#D32F2F" }}>
                        Total en Rojo: ${totalR.toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 6, minWidth: 350, maxWidth: 350 }} >
                      <TableContainer component={Paper} sx={{ mt: 2, maxHeight: "400px", maxWidth: "380px", borderRadius: "13px" }}>
                        <Table sx={{ minWidth: 300, maxWidth: 600 }} aria-label="simple table" stickyHeader >
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Color</TableCell>
                              <TableCell align="center">Apuesta</TableCell>
                              <TableCell align="center">Opciones</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {allBets.map((doc, index) => (
                              <TableRow key={doc.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {doc.color === "Verde" && (
                                  <>
                                    <TableCell align="center" style={{ color: "#2E7D32" }}>{doc.color}</TableCell>
                                    <TableCell align="center" style={{ color: "#2E7D32" }}>${doc.bet.toFixed(2)}</TableCell>
                                    {!doc.isBetHunt && betStatus && (
                                      <TableCell align="center" style={{ color: "#2E7D32" }}>
                                        <Button variant="contained" color="primary" size="small"
                                          style={{
                                            color: "#EA9F26",
                                            backgroundColor: "#1D1D1B",
                                            borderRadius: 38,
                                            fontSize: "9px"
                                          }}
                                          onClick={() => handleOpenHuntBet(doc)}
                                        >
                                          Cazar apuesta
                                        </Button>
                                      </TableCell>
                                    )}
                                  </>
                                )}

                                {doc.color === "Rojo" && (
                                  <>
                                    <TableCell align="center" style={{ color: "#D32F2F" }}>{doc.color}</TableCell>
                                    <TableCell align="center" style={{ color: "#D32F2F" }}>${doc.bet.toFixed(2)}</TableCell>
                                    {!doc.isBetHunt && betStatus && (
                                      <TableCell align="center" style={{ color: "#D32F2F" }}>
                                        <Button variant="contained" color="primary" size="small"
                                          style={{
                                            color: "#EA9F26",
                                            backgroundColor: "#1D1D1B",
                                            borderRadius: 38,
                                            fontSize: "9px"
                                          }}
                                          onClick={() => handleOpenHuntBet(doc)}
                                        >
                                          Cazar apuesta
                                        </Button>
                                      </TableCell>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12} sx={{ mt: 4 }}>
                      <Typography variant="h6" component="h2" color="#ffffff">
                        No se han realizado apuestas
                      </Typography>
                    </Grid>
                  </Grid>
                }
              </>
            }
            {/* ============================================================================================== */}

            <Grid container spacing={2} direction="column">
              <Grid item xs={12} sx={{ mb: 1, mt: 5 }} >
                <Chat />
              </Grid>
            </Grid>
          </Box>
        </Container>
        {
          roulette &&
          <Popover
            style={{
              borderRadius: "16px"
            }}
            id={id}
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Spin
              size="large"
              spinning={loadingRoulette}
            >
              <div style={{ backgroundColor: "#781113" }} className="container-ruleta">
                <Typography style={{
                  color: "#ffffff",
                  paddingBottom: "1em"
                }}>¡Hola! GalloWin te invita a participar en nuestra ruleta, costo del boleto: ${roulette.price}.00</Typography>
                {betType === 0 &&
                  <div style={{ backgroundColor: "#ffffff", borderRadius: "10px", height: "375px", paddingTop: "1em" }}>
                    <Typography style={{
                      textAlign: "center",
                      color: "#000000",
                    }}>Elige una opción.</Typography>

                    <div className="container-general">
                      <div className="container-buttons-ruleta">
                        <Button variant="outlined" size="large"
                          onClick={(e) => setColorSelectedEdit("Verde")}
                          style={{
                            backgroundColor: "rgb(232, 26, 26)",
                            color: "#ffffff",
                            border: "1px solid rgb(0 0 0 / 25%)",
                            borderRadius: 35,
                            padding: "10px 30px",
                            fontSize: "12px"
                          }}>
                          PAR
                        </Button>
                        <Button variant="outlined" size="large"
                          onClick={(e) => setColorSelectedEdit("Verde")}
                          style={{
                            backgroundColor: "rgb(35 127 0)",
                            color: "#ffffff",
                            border: "1px solid rgb(0 0 0 / 25%)",
                            borderRadius: 35,
                            padding: "10px 30px",
                            fontSize: "12px",
                            marginLeft: "1em"
                          }}>
                          IMPAR
                        </Button>
                      </div>
                    </div>
                    <Typography style={{
                      textAlign: "center",
                    }}><Box
                        component="img"
                        alt="Imagen previa"
                        src="https://www.casino.es/imagenes/juegos/ruleta/hero-ruleta.svg"
                        style={{ width: "70px", height: "70px", marginLeft: "8px", borderRadius: 14, }}
                      />
                    </Typography>
                    <div style={{ padding: "0em 1em 0em 1em" }}>
                      <Grid item xs={12} md={12} lg={12} sx={{ mt: 3, mb: 2 }}>
                        <Typography sx={{ fontSize: 16 }} align="left" style={{ textAlign: "center", marginTop: "0px" }} marginTop={"0px"}>
                          ¿Cuánto quieres apostar?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={12} sx={{ mb: 1 }}>
                        <Stack
                          sx={{ pb: 1 }}
                          direction="row"
                          spacing={1}
                          justifyContent="center"
                        >
                          <Button variant="contained" size="large"
                            onClick={(e) => handleBetStandarAmount(100)}
                            style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                            $100
                          </Button>
                          <Button variant="contained" size="large"
                            onClick={(e) => handleBetStandarAmount(200)}
                            style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                            $200
                          </Button>
                          <Button variant="contained" size="large"
                            onClick={(e) => handleBetStandarAmount(500)}
                            style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                            $500
                          </Button>
                          <Button variant="contained" size="large"
                            onClick={(e) => handleBetStandarAmount(1000)}
                            style={{ borderRadius: 35, fontSize: "12px", color: "#EA9F26", backgroundColor: "#1D1D1B" }}>
                            $1,000
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sx={{ mb: 4, mt: 2 }}>
                        <Stack component="form" onSubmit={handleMakeBet} noValidate
                          sx={{ pb: 1 }}
                          direction="row"
                          spacing={2}
                          justifyContent="space-between"
                        >
                          <TextField id="bet" label="Otra cantidad" type="number" InputProps={{ inputProps: { min: 0 } }} sx={{ maxWidth: 190 }}
                            value={betAmount}
                            onChange={validateInput}
                            onPaste={validatePaste}
                          />
                          <Button variant="contained" color="inherit" size="small" type="submit"
                            style={{
                              backgroundColor: "#E81A1A",
                              color: "#FFFFFF",
                              borderRadius: 35,
                              padding: "8px 30px",
                              fontSize: "12px"
                            }}>
                            Apostar
                          </Button>
                        </Stack>
                      </Grid>
                    </div>
                  </div>}
                {betType === 1 && !roulette.winner &&
                  <div style={{ backgroundColor: "#ffffff", borderRadius: "10px", height: "375", paddingTop: "1em" }}>
                    <Typography style={{
                      textAlign: "center",
                      marginBottom: "1em"
                    }}>Elige un número.</Typography>
                    <div className='containerFooter'>
                      <div className='container-numbers-event-details-admin'>
                        {ticketsTotal.map((item, index) => {
                          if (item.available) {
                            return (
                              <button
                                className={item.number % 2 === 0 ? 'btn-ticket-red' : 'btn-ticket-green'}
                                key={`btn-${index}`}
                                onClick={() => handleTicketFromList(item.number)}
                                style={selectedTickets[0] === item.number ? { backgroundColor: 'black', color: item.color } : null}
                              >
                                {item.number}
                              </button>
                            );
                          } else {
                            return (
                              <button
                                className={'btn-ticket-disable-ed'}
                                key={`btn-${index}`}
                                disabled={true}
                              >
                                {item.number}
                              </button>
                            );
                          }
                        })}
                      </div>
                    </div>
                    <div style={{ padding: "0em 1em 3px 1em" }}>
                      <Typography style={{
                        textAlign: "center",
                      }}><Box
                          component="img"
                          alt="Imagen previa"
                          src="https://www.casino.es/imagenes/juegos/ruleta/hero-ruleta.svg"
                          style={{ width: "70px", height: "70px", marginLeft: "8px", marginBottom: "1em", borderRadius: 14, }}
                        />
                      </Typography>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        {selectedTickets > 0 &&
                          <div className="container-bnt-confirm-roulette">
                            <Typography style={{
                              textAlign: "center",
                              marginBottom: "1em"
                            }}>Elegiste el número {selectedTickets[0]}, presiona "APOSTAR" para finalizar.</Typography>
                            <Button variant="outlined" size="large"
                              onClick={createBetRoulette}
                              style={{
                                width: "288px",
                                backgroundColor: "rgb(232, 26, 26)",
                                color: "#ffffff",
                                border: "1px solid rgb(0 0 0 / 25%)",
                                borderRadius: 35,
                                padding: "10px 30px",
                                fontSize: "12px",
                                marginBottom: "1em"
                              }}>
                              Apostar
                            </Button>
                          </div>}
                      </div>
                    </div>
                  </div>}´
                {roulette.winner &&
                  <div style={{ backgroundColor: "#ffffff", borderRadius: "10px", height: "375", paddingTop: "1em" }}>
                    <Typography style={{
                      textAlign: "center",
                      marginBottom: "1em",
                      fontWeight: "bolder",
                      fontSize: "18px"
                    }}>EL NÚMERO GANADOR ES:</Typography>
                    <div style={{ justifyContent: "center", display: "flex" }}>
                      <div style={{ width: "25%", marginBottom: "1em", boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 8px 0px" }}
                        className={roulette.winner % 2 === 0 ? 'btn-ticket-red' : 'btn-ticket-green'}>
                        <Typography style={{
                          textAlign: "center",
                          fontWeight: "bolder",
                          color: "#ffffff",
                          fontSize: "25px"
                        }}>{roulette.winner}</Typography>
                      </div>
                    </div>
                    <div style={{ padding: "0em 1em 3px 1em" }}>
                      <Typography style={{
                        textAlign: "center",
                      }}><Box
                          component="img"
                          alt="Imagen previa"
                          src="https://www.casino.es/imagenes/juegos/ruleta/hero-ruleta.svg"
                          style={{ width: "70px", height: "70px", marginLeft: "8px", marginBottom: "1em", borderRadius: 14, }}
                        />
                      </Typography>
                    </div>
                  </div>}
              </div>
              <div style={{ padding: "2em 1em 2em 1em" }}>
                <div style={{ padding: "0" }} className='container-image'>
                  <img src={LogoCompleto} alt={"logoGALLOMX"} style={{ height: "55px", width: "160px" }}></img>
                </div>
              </div>
            </Spin>
          </Popover>
        }
        {
          roulette &&
          <IconButton
            style={{
              backgroundColor: '#ff0000',
              color: '#ffffff',
              position: 'fixed',
              bottom: '95px',
              right: '27.5px',
              width: '60px',
              height: '60px',
              borderRadius: '33px',
              marginRight: '0px',
              marginLeft: '0px'
            }}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}>
            <NotificationsActiveIcon />
          </IconButton>
        }
      </div >
    </Spin >
  );
};


export default FightsV2;