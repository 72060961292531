import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { useLocalStorage } from '../useLocalStorage';
import { useUserAuth } from '../context/UserAuthContext';
import { dbFights } from "../firebase";
import { getDocs, collection, query, where, getCountFromServer, startAfter, orderBy, limit } from "firebase/firestore";
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
  gridContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
    paddingTop: "30px"
  },
  card: {
    minWidth: 320,
    position: 'relative',
    boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
    overflow: 'visible',
    borderRadius: '1.5rem',
    transition: '0.4s',
    '&:hover': {
      transform: 'translateY(-2px)',
      '& $shadow': {
        bottom: '-1.5rem',
      },
      '& $shadow2': {
        bottom: '-2.5rem',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 0,
      display: 'block',
      width: '100%',
      bottom: -1,
      height: '100%',
      borderRadius: '1.5rem',
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
  main: {
    overflow: 'hidden',
    borderTopLeftRadius: '1.5rem',
    borderTopRightRadius: '1.5rem',
    zIndex: 1,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      display: 'block',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to top, #014a7d, rgba(0,0,0,0))',
    },
  },
  content: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
    padding: '1.5rem 1.5rem 1rem',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  tag: {
    display: 'inline-block',
    fontFamily: "'Sen', sans-serif",
    backgroundColor: '#DC1515',
    borderRadius: '1rem',
    padding: '2px 1rem',
    color: '#fff',
    marginBottom: '0.5rem',
  },
  tagWinGreen: {
    marginLeft: "auto",
    marginRight: "5px",
    marginTop: "-35px",
    position: "relative",
    display: 'block',
    width: "fit-content",
    textAlign: "center",
    backgroundColor: '#31AF2B',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1.2rem',
  },
  tagWinRed: {
    marginLeft: "auto",
    marginRight: "5px",
    marginTop: "-35px",
    position: "relative",
    display: 'block',
    width: "fit-content",
    textAlign: "center",
    backgroundColor: '#E81A1A',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1.2rem',
  },
  tagEmpate: {
    marginLeft: "auto",
    marginRight: "5px",
    marginTop: "-35px",
    position: "relative",
    display: 'block',
    width: "fit-content",
    textAlign: "center",
    backgroundColor: '#b35426',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1.2rem',
  },
  tagNormal: {
    marginLeft: "0px",
    display: 'block',
    width: "fit-content",
    textAlign: "initial",
    backgroundColor: '#b35426',
    borderRadius: '1rem',
    padding: '2px 1rem',
    marginBottom: '1rem',
    color: "#fff"
  },
  title: {
    display: 'inline-block',
    fontFamily: "'Sen', sans-serif",
    fontSize: '2rem',
    fontWeight: 800,
    color: '#fff',
  },
  author: {
    zIndex: 1,
    position: 'relative',
    borderBottomLeftRadius: '1.5rem',
    borderBottomRightRadius: '1.5rem',
  },
  shadow: {
    transition: '0.2s',
    position: 'absolute',
    zIndex: 0,
    width: '88%',
    height: '100%',
    bottom: 0,
    borderRadius: '1.5rem',
    backgroundColor: 'rgba(0,0,0,0.06)',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  shadow2: {
    bottom: 0,
    width: '72%',
    backgroundColor: 'rgba(0,0,0,0.04)',
  },
  action: {
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
});

const PeleasPasadas = () => {
  const classes = useStyles();
  const { user } = useUserAuth();
  const [, setUidUser] = useLocalStorage("uidUser", "");

  //images
  let image1 = "https://storage.googleapis.com/afs-prod/media/7c9abe7992e5417cba49e400d6c3aa7c/2000.jpeg";

  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    pageStarts: [null],
  });
  const [listSize, setListSize] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const dataSave = (idUser) => {
    //This save function helps reload data in fightView
    setUidUser(idUser);
  }

  // request a weekday along with a long date
  const dateOptions = {
    month: "long",
    day: "numeric",
  };

  const getFightsCount = async () => {
    let queryPastFights = query(
      collection(dbFights, "Fights"),
      where("ended", "==", true),
    );
    const snapshotCount = await getCountFromServer(queryPastFights);
    setListSize(snapshotCount.data().count);
  };

  const getFights = async (page) => {
    const { pageSize, pageStarts } = pagination;
    const startAfterPage = pageStarts[page - 1];

    let queryPastFights = query(
      collection(dbFights, "Fights"),
      where("ended", "==", true),
      orderBy("date", "desc"),
      limit(pageSize)
    );

    if (startAfterPage) {
      queryPastFights = query(queryPastFights, startAfter(startAfterPage));
    }

    const snapshot = await getDocs(queryPastFights);
    const docs = snapshot.docs;
    const newItems = docs.map(doc => ({ ...doc.data(), id: doc.id }));

    setItems(newItems);

    if (page === pageStarts.length && docs.length === pageSize) {
      pageStarts.push(docs[docs.length - 1]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setPagination(prev => ({ ...prev, page: newPage }));
    getFights(newPage);
  };

  useEffect(() => {
    getFights(pagination.page);
    getFightsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container direction="row" alignItems="center" justifyContent="center" className={classes.gridContainer} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={6} md={4} >
          {items.map((doc, index) => {
            if (doc.status) {
              return null;
            };
            return (
              <li key={doc.id} style={{ listStyle: "none" }} >
                {!doc.privateEvent &&
                  <Card sx={{ minWidth: 275 }} style={{ marginBottom: "30px", backgroundColor: "#1D1D1B", borderRadius: 16 }} >
                    <CardActionArea component={Link} to={`/fightview/${doc.id}`} onClick={(e) => dataSave(user.uid)}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} className={classes.tagNormal} color="primary" gutterBottom>
                          {
                            new Date(doc.date.seconds * 1000).toLocaleDateString("locale", dateOptions)
                          }
                        </Typography>
                        <CardMedia
                          component="img"
                          height="120"
                          image={image1}
                        />
                        {doc.winner === "Verde" &&
                          <Typography sx={{ mb: 1.5 }} className={classes.tagWinGreen} style={{ color: "#fff" }} >
                            Ganador: {doc.winner}
                          </Typography>}
                        {doc.winner === "Rojo" &&
                          <Typography sx={{ mb: 1.5 }} className={classes.tagWinRed} style={{ color: "#fff" }} >
                            Ganador: {doc.winner}
                          </Typography>}
                        {doc.winner === "Empate" &&
                          <Typography sx={{ mb: 1.5 }} className={classes.tagEmpate} style={{ color: "#fff" }} >
                            Empate
                          </Typography>}
                        <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", marginBottom: "4px", fontSize: "22px" }}>
                          {doc.fightName}
                        </Typography>
                        <Typography component="h1" variant="h5" style={{ color: "#F4EFF4", fontSize: "12px", marginBottom: "12px" }}>
                          {doc.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                } </li>
            );
          })}
        </Grid>
      </Grid>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "baseline", marginTop: "5px" }}>
        <Button
          disabled={currentPage === 1}
          style={{ background: 'none', border: 'none' }}
          onClick={() => handlePageChange(currentPage - 1)}>
          <LeftOutlined style={{ color: "black" }} />
        </Button>
        <p>{currentPage} de {Math.ceil(listSize / pagination.pageSize)}</p>
        <Button
          disabled={currentPage === Math.ceil(listSize / pagination.pageSize)}
          style={{ background: 'none', border: 'none' }}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <RightOutlined style={{ color: "black" }} />
        </Button>
      </div>
    </>
  );
};

export default PeleasPasadas;
