import DashboardIcon from '@mui/icons-material/Dashboard';
import PaidIcon from '@mui/icons-material/Paid';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import WallpaperOutlined from '@mui/icons-material/WallpaperOutlined';
// import YouTubeIcon from '@mui/icons-material/YouTube';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FrontonIcon from '../Images/fronton.png';
import FutbolIcon from '../Images/futbol.png';
import GallosIcon from '../Images/logoGALLOMX-10.png';
import HorseIcon from '../Images/horse.png';
import horsemenu from '../Images/horsemenu.png';

const viewsAdmin = [
  {
    listIcon: <DashboardIcon />,
    listText: "Dashboard",
    text: "adminpanel"
  },
  {
    listIcon: <RadioButtonCheckedOutlinedIcon />,
    listText: "Ruleta",
    text: "ruleta-admin"
  },
  {
    listIcon: <SportsMmaIcon />,
    listText: "Peleas",
    text: "lista-peleas"
  },
  {
    listIcon: <SportsSoccerIcon />,
    listText: "Deportes",
    text: "lista-sports",
    category: 'futbol',
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Usuarios",
    text: "usersPanel"
  },
  {
    listIcon: <PaidIcon />,
    listText: "Movimientos",
    text: "depositos-admin"
  },
  {
    listIcon: <WallpaperOutlined />,
    listText: "Anuncios",
    text: "ads"
  },
  // {
  //   listIcon: <YouTubeIcon />,
  //   listText: "Tutoriales",
  //   text: "tutorials"
  // },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsStreaming = [
  {
    listIcon: <SportsMmaIcon />,
    listText: "Peleas",
    text: "lista-peleas"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsFinance = [
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Usuarios",
    text: "usersPanel"
  },
  {
    listIcon: <PaidIcon />,
    listText: "Movimientos",
    text: "depositos-admin"
  },
  {
    listIcon: <WallpaperOutlined />,
    listText: "Anuncios",
    text: "ads"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsMarketing = [
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Usuarios",
    text: "usersPanel"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsClient = [
  {
    id: 1,
    listIcon: <SportsMmaIcon />,
    listText: "Ver peleas",
    text: "homeuser",
    category: 'Gallos',
  },
  {
    listIcon: <AddCardIcon />,
    listText: "Agregar fondos",
    text: "add-money"
  },
  {
    listIcon: <CreditScoreIcon />,
    listText: "Retirar fondos",
    text: "retiros"
  },
  // {
  //   listIcon: <YouTubeIcon />,
  //   listText: "Tutoriales",
  //   text: "tutorials"
  // },
  {
    id: 2,
    listIcon: <SportsSoccerIcon />,
    listText: "Deportes",
    text: "sports-user",
    category: 'Fútbol',
  },
  {
    id:4,
    listIcon: <img style={{width:"1em", height:"1em", fontSize:"1.5rem"}} src={horsemenu} alt='icon-menu'></img>,
    listText: "Caballos",
    text: "sports-user",
    category: "Caballos"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsClientBanned = [
  {
    listIcon: <SportsMmaIcon />,
    listText: "Ver peleas",
    text: "homeuser"
  },
  {
    listIcon: <PersonOutlineIcon />,
    listText: "Mi perfil",
    text: "perfil"
  },
];

const viewsUserType = {
  admin: viewsAdmin,
  streaming: viewsStreaming,
  finance: viewsFinance,
  marketing: viewsMarketing,
  active: viewsClient,
  banned: viewsClientBanned
};

const validRoles = {
  usersPanel: ["admin", "finance", "marketing"],
  client: ["active"],
  streaming: ["admin", "streaming"],
  finance: ["admin", "finance"],
  admin: ["admin"]
};

const rouletteDescStatus = ["Inactiva", "Activa", "Terminada", "Oculta", "Cancelada"];
const sportDescStatus = ["Inactiva", "Activa", "Terminada", "Oculta", "Cancelada"];

const rouletteStatus = {
  inactive: 0,
  active: 1,
  ended: 2,
  hidden: 3,
  cancelled: 4
};

const channels = {
  1: {
    id: 'd308a64c-5161-0567-cce0-2f563d5868c2',
    url: 'https://iframe.dacast.com/live/e7e061db-7350-6fd1-4fc0-b0f4b7e4b7fb/d308a64c-5161-0567-cce0-2f563d5868c2'
  },
  2: {
    id: '87409b87-d018-8c82-b9cf-8510751222fc',
    url: 'https://iframe.dacast.com/live/e7e061db-7350-6fd1-4fc0-b0f4b7e4b7fb/87409b87-d018-8c82-b9cf-8510751222fc'
  },
  3: {
   id: '87409b87-d018-8c82-b9cf-8510751222fc',
   url: 'https://www.facebook.com/plugins/video.php?height=236&href=https%3A%2F%2Fwww.facebook.com%2F100087474559601%2Fvideos%2F731802121778895%2F&show_text=false&width=560&t=0' 
  }
}

const eventos = {
  1: {
    id: 1,
    nombre: 'Fútbol'
  },
  2: {
    id: 2,
    nombre: 'Frontón'
  },
  3: {
    id: 3,
    nombre: 'Básquetbol'
  },
  4: {
    id: 4,
    nombre: 'Caballos'
  },
}

const betsCategories = [
  {
    id: 1,
    name: 'Gallos',
    to: '/homeuser',
    img: GallosIcon
  },
  {
    id: 2,
    name: 'Fútbol',
    category: 'futbol',
    to: '/sports-user',
    img: FutbolIcon
  },
  {
    id: 3,
    name: 'Frontón',
    to: '/sports-user',
    img: FrontonIcon
  },
  {
    id: 4,
    name: 'Caballos',
    to: '/sports-user',
    img: HorseIcon
  }
];


export {
  viewsUserType,
  validRoles,
  rouletteDescStatus,
  sportDescStatus,
  rouletteStatus,
  channels,
  eventos,
  betsCategories
}
