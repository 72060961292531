import React, { useState, useEffect } from "react";
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import EventosActuales from "./EventosActuales";
import EventosPasados from "./EventosPasados";
import { useUserAuth } from "../context/UserAuthContext";
import { styled } from '@mui/material/styles';
import { collection, getDocs, doc, updateDoc, query, where } from "firebase/firestore";
import { firestore } from "../firebase";
import { Carousel } from 'antd';
import Modal from '@mui/material/Modal';
import { CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import Categories from "./Categories";
import './HomeUser.css';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#ffffff',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
};

const SportsUser = () => {
  const { userInfo, user, getUserData } = useUserAuth();
  const [tabValue, setTabValue] = useState(0);
  const [ads, setAds] = useState([]);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [urlMedia, setUrlMedia] = useState("");

  let { state } = useLocation();

  const handleOpenModalAdd = (url) => {
    setUrlMedia(url)
    setOpenModalAdd(true);
  }

  const handleCloseModalAdd = () => {
    setUrlMedia("");
    setOpenModalAdd(false);
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function updateDate(date, activeLive) {
    const userDoc = doc(firestore, "Users", user.uid);
    try {
      await updateDoc(userDoc, {
        dateBalance: date,
        activeLive: activeLive
      });
    } catch (err) {
      console.log(err);
    }
  }

  const getUser = async () => {
    await getUserData(user).then((snapshot) => {

      if (userInfo.type === "active") {
        if (snapshot.data().balance < 100) {
          if (!snapshot.data().dateBalance) {
            let currentDate = new Date()
            updateDate(currentDate, true)
          } else {
            const currentDate = new Date()
            const date = snapshot.data().dateBalance.toDate()
            const dif = currentDate.getTime() - date.getTime()
            const days = dif / (1000 * 60 * 60 * 24)
            if (days >= 1) {
              updateDate(snapshot.data().dateBalance, false)
            }
          }
        } else {
          updateDate(null, true)
        }
      }
    });
  };

  const getADS = async () => {
    const queryAdss = query(
      collection(firestore, "Ads"), where("isActive", "==", true)
    );
    const querySnapshot = await getDocs(queryAdss);
    setAds(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  useEffect(() => {
    if (user?.uid) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    getADS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Modal Ads */}
      <Modal
        open={openModalAdd}
        onClose={handleCloseModalAdd}
      >
        <Box sx={style}>
          <div>
            <CloseOutlined onClick={handleCloseModalAdd} style={{ position: "absolute", right: "30px", top: "20px", fontSize: "20px", cursor: "pointer" }} />
          </div>
          <img src={urlMedia} alt="ad" style={{ width: "100%", height: "100%", objectFit: "contain"}}/>
        </Box>
      </Modal>
      <div style={{ backgroundColor: "#2b2b2b" }}>
        {/* ADS */}
        <div className="carousel-container">
          <Carousel autoplay>
            {ads.map((ad) => (
              <div key={ad.id} onClick={() => handleOpenModalAdd(ad.urlMediaComplete)}>
                <img className="carousel-img" src={ad.urlMedia} alt={ad.name} style={{objectFit: "contain"}}/>
              </div>
            ))}
          </Carousel>
        </div>
        <Categories />
        <Container style={{ backgroundColor: "#2b2b2b" }} >
          {userInfo?.type !== "banned" &&
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6} lg={6} align="center" sx={{ mt: 4 }}>
                  <StyledTabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor=""
                    textColor="inherit"
                    sx={{ width: "100%" }}
                  >
                    <Tab label="Eventos actuales" sx={{ width: "50%" }} style={{ back: "green", color: "#ffffff" }} />
                    <Tab label="Eventos pasados" sx={{ width: "50%" }} style={{ color: "#ffffff" }} />
                  </StyledTabs>
                </Grid>
              </Grid>
              {tabValue === 0 && <EventosActuales category={state.category}/>}
              {tabValue === 1 && <EventosPasados category={state.category}/>}
            </Box>}
          {userInfo?.type === "banned" &&
            <Box
              sx={{
                marginTop: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 10
              }}
            >
              <Typography component="h1" variant="h5" color={'error'} style={{ fontSize: "22px", fontWeight: "bold", textAlign: "center" }}>
                Tu cuenta ha sido desactivada. Para más información ponte en contacto con algun miembro de la administración de GalloWin
              </Typography>
            </Box>
          }
        </Container>

        
      </div>
    </>
  );
};

export default SportsUser;
